import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import "./hoistchecksPermitView.css";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import {
  PDFDownloadLink,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";
import TabularData from "../common/pdfRenderer/tabularData";
import PDFFiles from "../common/pdfRenderer/files";
import Swal from "sweetalert2";

export default function ViewHoistchecksPermit({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([])
  const [activities, setActivities] = useState([])
  const [actions, setActions] = useState([])
  const [popup, setPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    resubmitComment: "",
    approvedComment: "",
    deniedComment: "",
    actionComment: "",
    signature: null,
  })

  const fetchHoistchecksDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/hoistchecks/permit/details`,
        {
          hoistchecksId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
        getUserEmail(res.data[0].addedByInCompany)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };


  const fetchActivities = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/hoistchecksactivity/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const fetchActions = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/hoistchecksaction/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActions(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchHoistchecksDetails();
      fetchActivities();
      fetchActions();
    }
  }, [id]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      let signature
      if (!fieldData.denied && !fieldData.actionTaken) {
        signature = await handleSaveVerifySign()
      }
      handleSubmitAfterSave(signature)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (signature) => {
    const form = new FormData();
    const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
      email: v.email,
      userid: v.userid,
      fullName: v.fullName,
      emailPref: v.emailPreference ? v.emailPreference.includes("hoistchecksPermits") : v.permits.includes("hoistchecksreceiveEmails"),
      isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
    })).filter(v => v.emailPref || v.isAdminWithNoPref)
    form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
    form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
    form.append("deniedComment", fieldData.deniedComment);
    form.append("actionComment", fieldData.actionComment);
    form.append("approvedComment", fieldData.approvedComment);
    form.append("resubmitComment", fieldData.resubmitComment);
    if (fieldData.approved) {
      form.append("approved", fieldData.approved);
    }
    if (fieldData.completed) {
      form.append("completed", fieldData.completed);
    }
    if (fieldData.actionTaken) {
      form.append("actionTaken", fieldData.actionTaken);
    }
    form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post((fieldData.resubmitted || fieldData.completed || fieldData.actionTaken) ? `${process.env.REACT_APP_API_BASEURL}/resubmit/hoistchecks-permit` : `${process.env.REACT_APP_API_BASEURL}/update/hoistchecks-permit`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/hoistchecks-permits')
        setFieldData({
          deniedComment: "",
          actionComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          actionTaken: false,
          signature: null,
        });
        handleClearSignature();
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const questions = {
    inspectDamage: "Inspect structure for damage e.g. bent mast bracings or ties, indentations on mast guides, cracked welds, loose bolts and fasteners",
    inspectRack: "Inspect rack and pinion for wear, damage and lubrication",
    inspectGear: "Inspect gearboxes for leaks",
    tieBolt: "Check that tie bolts are secure with undue movement",
    foundBolt: "Check foundation bolts and racks",
    guideRoller: "Check guide rollers are operational",
    brake: "Check brake function",
    hoist: "Check hoist way protection and machinery guards and fastenings are in place",
    cables: "Electrical cables - check for damage and bare wires",
    plugs: "Plugs and sockets - check for condition, cracks, bent pins and debris or dirt in sockets",
    nsJoints: "Check cables for taped or non-standard joints",
    eqGrip: "Check that cable covering has not been pulled out of plugs of equipment grips",
    missingPart: "Check castings of electrical equipment for damage and loose or missing parts and screws",
    overHeating: "Check cables, plugs and equipment for overheating or burn marks",
    hoistFloor: "Check floor of hoist cage is in good condition",
    landFlap: "Check landing flap for correct operation and free from damage",
    interlock: "Check all interlocks are operational",
    landingGate: "Check landing gates for wear",
  }

  const MyDoc = () => {

    const tabularSections = [
      {
        name: 'Inspected Items',
        rows: []
      },
      {
        name: 'General Details',
        rows: [
          ['Completed', (data.isCompleted && data.isCompleted == 1) ? "Yes" : "No"],
          ['Site', data.siteName ? data.siteName : "-"],
          ['Department', data.departmentName ? data.departmentName : "-"],
          ['Submitted By', data.submittedBy? data.submittedBy : "-"],          
          ['Start Date', data.startDate ? commonService.formatDate(data.startDate, "Do MMM YYYY" ): "-"],
          ['Finish Time', data.finishTime ? data.finishTime : "-"],
          ['Hoistchecks Owner', data.supervisor ? data.supervisor : "-"],          
          ['Location/Description', data.descriptionOfWorks ? data.descriptionOfWorks : ''],
          ['Inspector Name', data.operativeName ? data.operativeName : ''],
        ]
      }
    ];

    Object.entries(questions).map(([key, question]) => {
      tabularSections[0].rows.push([question, (data[key] == 1) ? "Pass" : (data[key] == 2 ? "N/A" : data[key] == 0 ? "Fail" : "-")]);
    })

  
    const filesBlocks = [
      {
        name: "Uploaded Files",
        files: data.files,
      }
    ];

    activities.map((activity) => {
      if ( activity.signature ) {
        filesBlocks.push({
          name: activity.action,
          subtitle: activity.comment,
          type: 'signatures',
          files: activity.signature,
        });
      }
    });
  
    return (
      <PDFRenderer name={"Hoist Checks #" + id} user={userDetails}>
        
        <TabularData
          tabularSections={tabularSections}
        />
  
        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}
  
      </PDFRenderer>
    )
  }

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/hoistchecks/" + deleteId)
        .then(function (response) {
          toast.success("Hoistchecks Permit archived successfully");
          history.push(`/hoistchecks-permits`)
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Hoistchecks Permit"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Hoistchecks Permit List", path: "/hoistchecks-permits" },
          { name: "Hoistchecks Permit", path: `/hoistchecks-permit/${id}` },
        ]}
      />
      <CustomPopup
        trigger={!popup}
        setTrigger={() => {
          setPopup(true)
          setFieldData({
            approved: false,
            resubmitted: false,
            completed: false,
            denied: false,
            actionTaken: false,
            approvedComment: "",
            deniedComment: "",
            actionComment: "",
            signature: null,
          })
        }}
      >
        {(fieldData.approved || fieldData.resubmitted || fieldData.completed) && <>
          <div className="sign_image">
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 263, height: 270 }}
            />
            <span
              className="cancel-custom cursor-pointer"
              onClick={handleClearSignature}
            >
              <i
                className="clear_btn  text-danger fa fa-times-circle"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        </>
        }
        {!fieldData.completed && <div className="col-12 form_inner p-2">
          <label for="">
            {fieldData.resubmitted ? "Resubmit Comment" : fieldData.approved ? "Approval Comment" : fieldData.actionTaken ? "Action Taken" : "Reason for Denial"} <span className="text-danger">*</span>
          </label>
          <div className="input_icons">
            <div className="input_field">
              <textarea
                name="approvedComment"
                rows="5"
                cols="100"
                onChange={(e) => {
                  if (fieldData.resubmitted) {
                    setFieldData({
                      ...fieldData,
                      resubmitComment: e.target.value,
                    })
                  } else if (fieldData.approved) {
                    setFieldData({
                      ...fieldData,
                      approvedComment: e.target.value,
                    })
                  } else if (fieldData.actionTaken) {
                    setFieldData({
                      ...fieldData,
                      actionComment: e.target.value,
                    })
                  } else {
                    setFieldData({
                      ...fieldData,
                      deniedComment: e.target.value,
                    })
                  }
                }}
              ></textarea>
            </div>
          </div>
        </div>}
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            {...(fieldData.approved ? {
              disabled:
                fieldData.approved ?
                  false :
                  !(fieldData.deniedComment)
            } : null)}
            {...(fieldData.actionTaken ? {
              disabled: !fieldData.actionComment
            } : null)}
            onClick={() => {
              handleSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>
      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-hoistchecks-view">Inspected Items</div>
                      <div className="p-2">
                        <div className="mb-1 d-flex dl-hoistchecks">
                          <div className="col-6 dd-hoistchecks"><strong>Details</strong> </div>
                          <div className="col-3 dd-hoistchecks"><strong>Answer</strong> </div>
                          <div className="col-3 dd-hoistchecks"><strong>Actions</strong> </div>
                        </div>
                        {Object.entries(questions).map(([key, question]) => <div className="mb-1 d-flex dl-hoistchecks">
                          <div className="col-6 dd-hoistchecks">{question} : </div>
                          <div className="col-3 dd-hoistchecks">
                            {" "}
                            {(data[key] == 1) ? "Pass" : (data[key] == 2 ? "N/A" : data[key] == 0 ? "Fail" : "-")}
                          </div>
                          <div className="col-3 dd-hoistchecks">
                            {" "}
                            {(data[key] == 0 ? ((actions.filter(act => act.assignTo == userDetails.id && act.component == key).length > 0) || userDetails.role == "admin") && (
                              <button className="btn btn-danger" style={{ height: "35px" }} onClick={() => {
                                setFieldData({
                                  ...fieldData,
                                  actionTaken: key
                                })
                                setPopup(false)
                              }}>Take Action</button>
                            ) : "-")}
                          </div>
                        </div>)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-5 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-hoistchecks-view">General Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          <div className="mb-1 d-flex dl-hoistchecks">
                            <div className="col-6 dd-hoistchecks">Completed : </div>
                            <div className="col-6 dd-hoistchecks">
                              {" "}
                              {(data.isCompleted && data.isCompleted == 1) ? "Yes" : "No"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-hoistchecks">
                            <div className="col-6 dd-hoistchecks">Site : </div>
                            <div className="col-6 dd-hoistchecks">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-hoistchecks">
                            <div className="col-6 dd-hoistchecks">Department : </div>
                            <div className="col-6 dd-hoistchecks">
                              {" "}
                              {data.departmentName ? data.departmentName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-hoistchecks">
                            <div className="col-6 dd-hoistchecks">
                              Submitted By :{" "}
                            </div>
                            <div className="col-6 dd-hoistchecks">
                              {" "}
                              {data.submittedBy ? data.submittedBy : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-hoistchecks">
                            <div className="col-6 dd-hoistchecks">
                              Start Date :{" "}
                            </div>
                            <div className="col-6 dd-hoistchecks">
                              {data.startDate
                                ? commonService.formatDate(
                                  data.startDate,
                                  "Do MMM YYYY"
                                )
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-hoistchecks">
                            <div className="col-6 dd-hoistchecks">
                              Finish Time :{" "}
                            </div>
                            <div className="col-6 dd-hoistchecks">
                              {data.finishTime}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-hoistchecks">
                            <div className="col-6 dd-hoistchecks">
                              Hoistchecks Owner :{" "}
                            </div>
                            <div className="col-6 dd-hoistchecks">
                              {data.supervisor}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-hoistchecks">
                            <div className="col-6 dd-hoistchecks">
                              Location/Description :{" "}
                            </div>
                            <div className="col-6 dd-hoistchecks">
                              {data.descriptionOfWorks}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-hoistchecks">
                            <div className="col-6 dd-hoistchecks">
                              Inspector Name :{" "}
                            </div>
                            <div className="col-6 dd-hoistchecks">
                              {data.operativeName}
                            </div>
                          </div>
                          {/* {!data.isApproved && <div className="mb-1 d-flex dl-hoistchecks alert alert-danger">
                            <div className="col-6 dd-hoistchecks">Reason Denied : </div>
                            <div className="col-6 dd-hoistchecks">
                              {data.denialComment ? data.denialComment : "-"}{" "}
                            </div>
                          </div>} */}
                        </div>
                      </div>
                      {(data.isApproved == 1) && (data.isCompleted != 1) && Object.keys(questions).filter((key)=>data[key] == 0).length == 0? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            completed: true
                          })
                          setPopup(false)
                        }}>Complete</button>
                      </>
                        : null}

                      {id && ( userDetails.role.includes("admin") || userPermissions.includes( 'deleteHoistChecklists' ) ) && data.isDeleted != 1 ? <>
                        <button className="btn btn-outline-danger ms-1" onClick={() => handleArchive(id)}>
                          Archive
                        </button>
                        </> : ''}

                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-7 mb-2">
                  <div className="d-flex flex-column">

                    <div className="mb-3" style={{ border: '1px solid #e2e6ea', borderRadius: '8px', overflow: "auto", maxHeight: "670px" }}>
                      <div className="header-hoistchecks-view position-sticky" style={{ top: 0 }}>Activity</div>
                      {activities.map(activity => (<div className="m-2">
                        <div className="p-2 signature-div-inner">
                          <div className="d-flex justify-content-between mb-2">
                            <span className="rst-title me-2">
                              {activity.action} :{" "}
                            </span>
                            <span className="rst-title me-2 text-secondary">
                              {activity.datetime}
                            </span>
                          </div>
                          {activity.comment ?
                            <p>{activity.comment}</p>
                            : null}
                          {activity.signature ? <img
                            onError={(e) => {
                              e.target.src = DefaultImage;
                            }}
                            className="obj-fit-cover"
                            height="250"
                            width="250"
                            src={
                              activity.signature
                                ? process.env.REACT_APP_API_BASEURL +
                                activity.signature
                                : DefaultImage
                            }
                          ></img> : null}
                        </div>
                      </div>))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="header-hoistchecks-view"> Uploaded Files</div>
                  <div className="d-flex">
                    {data.files && data.files.split(",").map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                      {commonService.outputImageTile(imgPreviewUrl)}
                    </div>))}
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4 mt-3">
                  <PDFDownloadLink
                    document={<MyDoc />}
                    className="mt-2 btn btn-primary pdf-download-btn"
                    fileName={"hoist-checkstlist-" + id + ".pdf"}
                  >
                    {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download PDF"
                    }
                  </PDFDownloadLink>
                </div>
              </div>

            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
