import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import { commonService } from "../common/common.service";
import { useAppState } from "../../state";
import { useHistory } from "react-router-dom";
import accidentService from "../../services/accident.service";
import { onError } from "../../libs/errorLib";
import {
  PDFDownloadLink,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";
import PDFFiles from "../common/pdfRenderer/files";

import axiosInstance from "../../helpers/request";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


export default function ViewAccidentReport({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const fetchAccidentReportDetaills = async () => {
    setLoading(true);
    try {
      if (id) {
        const response = await accidentService.getAccidentReport(id);
        if (response) {
          response.accidentDate = response?.accidentDate?.split("T")[0];
          response.ipDob = response?.ipDob?.split("T")[0];
          let [hours, minutes] = response?.accidentTime?.split(":");
          response.accidentTime = `${hours}:${minutes}`;

          setData({
            ...response,
          });
        }
      }
    } catch (e) {
      onError(e || "Unable to process request");
      if ( e?.response?.data?.message && e?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
      console.log("errr", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAccidentReportDetaills();
    }
  }, [id]);

  const pdfStyles = StyleSheet.create({
    body: {
      padding: "20px 16px 30px",
      fontSize: "13px",
      fontFamily: "Helvetica",
      color: "#000",
    },
    logos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },
    halfWidthRightAlign: {
      width: "50%",
      textAlign: "right",
    },
    halfWidthLeftAlign: {
      width: "50%",
      textAlign: "left",
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: "10px",
      border: "1px solid #ddd",
      borderBottom: "none",
    },
    detailsCell3n1: {
      width: "33.3333333%",
      flexBasis: "33.3333333%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCell5in1: {
      width: "20%",
      flexBasis: "20%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCell: {
      width: "50%",
      flexBasis: "50%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCellFullWidth: {
      width: "100%",
      flexBasis: "100%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    }
  });

  const MyDoc = () => {
    const filesBlocks = [
      {
        name: "Uploaded Files",
        files: data.files,
      }
    ];
    return (
      <PDFRenderer name={"Accident Report #" + id} user={userDetails}>
        <View>
          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              Section 1 - General Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Name of injured Person :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ipName ? data.ipName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Site : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.siteName ? data.siteName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Contact Details :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ipContact ? data.ipContact : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Gender : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ipGender ? data.ipGender : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Date of Birth :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ipDob ? data.ipDob : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Date & Time of Accident :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.accidentDate
                      ? data.accidentDate + " @ " + data.accidentTime
                      : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Lighting condition :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.lightingCondition ? data.lightingCondition : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Weather Conditions :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.weatherCondition ? data.weatherCondition : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Type of User :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.userType ? data.userType : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Company : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.company ? data.company : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Occupation : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.occupation ? data.occupation : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Accident Location :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.accidentLocation ? data.accidentLocation : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Task being Completed :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ipTask ? data.ipTask : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Supervisor / Instructor :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ipSupervisor ? data.ipSupervisor : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Familiar with Task :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ipFamiliar ? data.ipFamiliar : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    SSOW / RAMS Followed :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ssowFollowed ? data.ssowFollowed : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Solo Effort / Witnesses :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.witness ? data.witness : "-"}
                  </Text>
                </Text>
              </View>
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Section 2 - Equipment Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Was there a machine / tool or plant involved :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.equipmentInvolved ? data.equipmentInvolved : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Was it in a good Condition:
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.equipmentCondition ? data.equipmentCondition : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Was it designed for the task being completed :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.equipmentRelatedToTask
                      ? data.equipmentRelatedToTask
                      : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Was it being used correctly for the task :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.equipmentUsage ? data.equipmentUsage : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    What PPE was required and worn at the time of accident :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ppe ? data.ppe : "-"}
                  </Text>
                </Text>
              </View>
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Section 3 - Accident Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCellFullWidth}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.description ? data.description : "-"}
                  </Text>
                </Text>
              </View>
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Section 4 - First Aid / Hospital Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Who was the accident first reported to :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.reportedTo ? data.reportedTo : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Was first aid administered :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.firstAid && data.firstAid == 1 ? "Yes" : "No"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Who administered first aid? :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.firstAidBy ? data.firstAidBy : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Was the IP removed to hospital? :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.removedToHospital && data.removedToHospital == 1
                      ? "Yes"
                      : "No"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Nature of Injuery and where on the IPs body :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.injuryNature ? data.injuryNature : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Was the IP detained and for how long (days)? :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.detainedFor ? data.detainedFor : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Treatment given (if known) :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.treatment ? data.treatment : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    {" "}
                    Name of hospital attended:
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.hospitalName ? data.hospitalName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Address of Hospital attended :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.hospitalAddress ? data.hospitalAddress : "-"}
                  </Text>
                </Text>
              </View>
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Section 5 - Other Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}> : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.description ? data.description : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Submitted By :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.submittedBy ? data.submittedBy : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Date Submitted :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.submittedAt
                      ? commonService.formatDateTime(data.submittedAt)
                      : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Observations :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.observation ? data.observation : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Status : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.status ? data.status : "-"}
                  </Text>
                </Text>
              </View>
            </View>
          </View>
        </View>

        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}

      </PDFRenderer>
    );
  };

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/accident-reports/" + deleteId)
        .then(function (response) {
          toast.success("Accident Report archived successfully");
          window.location = '/view-accident-reports';
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Accident Report"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id + " - " + data.status}
        steps={[
          { name: "Home", path: "/" },
          { name: "Accident Reports List", path: "/accident-reports" },
          { name: "Accident Report", path: `/accident-reports/${id}` },
        ]}
      />

      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <a
          className="original-notification-link"
          href={`/accident-notifications/${data.accidentNotificationId}`}
          onClick={(e) => {
            e.preventDefault();
            history.push(`/accident-notifications/${id}`);
          }}
        >
          Original accident Notification
        </a>{" "}
              <div className="row">
                <div className="col-12 col-lg-7 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">
                        Section 1 - Details
                      </div>
                      <div className="p-2">
                        <div className="main-details">
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Name of injured Person :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.ipName ? data.ipName : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Site : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Contact Details :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.ipContact ? data.ipContact : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Gender : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.ipGender ? data.ipGender : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Date of Birth :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.ipDob ? data.ipDob : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Date & Time of Accident :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.accidentDate
                                ? commonService.formatDate(
                                    data.accidentDate,
                                    "Do MMM YYYY"
                                  ) +
                                  (data.accidentTime ? " at " + data.accidentTime : "")
                                : "-"}{" "}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Lighting condition :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.lightingCondition
                                ? data.lightingCondition
                                : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Weather Conditions :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.weatherCondition
                                ? data.weatherCondition
                                : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Type of User :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.userType ? data.userType : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Company : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.company ? data.company : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Occupation : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.occupation ? data.occupation : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Accident Location :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.accidentLocation
                                ? data.accidentLocation
                                : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Task being Completed :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.ipTask ? data.ipTask : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Supervisor / Instructor :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.ipSupervisor ? data.ipSupervisor : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Familiar with Task :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.ipFamiliar ? data.ipFamiliar : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              SSOW / RAMS Followed :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.ssowFollowed ? data.ssowFollowed : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Solo Effort / Witnesses :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.witness ? data.witness : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Lost Time :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.timeLost ? data.timeLost : "-"}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  {userDetails.role == "admin" ? (
                    <>
                      <div className="d-flex flex-column">
                        <div className="mb-3">
                          <div className="header-access-view">
                            Section 5 - Other Details
                          </div>
                          <div className="p-2">
                            <div className="main-details">
                              <div className="mb-1 d-flex dl-access">
                                <div className="col-6 dd-access">
                                  Submitted By :{" "}
                                </div>
                                <div className="col-6 dd-access">
                                  {" "}
                                  {data.submittedBy ? data.submittedBy : "-"}
                                </div>
                              </div>

                              <div className="mb-1 d-flex dl-access">
                                <div className="col-6 dd-access">
                                  Date Submitted
                                </div>
                                <div className="col-6 dd-access input_icons">
                                  <div className="w-100">
                                    {data.submittedAt ? commonService.formatDateTime(data.submittedAt) : "-"}
                                  </div>
                                </div>
                              </div>

                              <div className="mb-1 d-flex dl-access">
                                <div className="col-6 dd-access">
                                  Observations
                                </div>
                                <div className="col-6 dd-access">
                                  <div className="checkboxToggle reverseColors">
                                    <label>
                                      {data.observation
                                        ? data.observation
                                        : "-"}
                                    </label>
                                  </div>
                                </div>
                              </div>

                              <div className="mb-1 d-flex dl-access">
                                <div className="col-6 dd-access">Status</div>
                                <div className="col-6 dd-access input_icons">
                                  <div className="w-100">
                                    {data.status? data.status : "-"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                {data.status != 'Closed' ? (
                  <button 
                    className="btn btn-outline-success ms-1 me-2"
                    onClick={() => {
                      history.push(
                        `/edit-accident-report/${data.accidentNotificationId}/${data.id}`
                      );
                    }}
                  >
                    Edit
                  </button>
                ) : null}

                {id && ( userDetails.role.includes("admin") || userPermissions.includes( 'deleteAccidentReports' ) ) && data.isDeleted != 1 ? <>
                  <button className="btn btn-outline-danger ms-1" onClick={() => handleArchive(id)}>
                    Archive
                  </button>
                </> : ''}

                </div>
                <div className="col-12 col-lg-5 mb-2">
                  <div className="d-flex flex-column">
                    <div class="mb-3">
                      <div className="header-access-view">
                        Section 2 - Equipment Details
                      </div>

                      <div class="p-2">
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Was there a machine / tool or plant involved :{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.equipmentInvolved
                              ? data.equipmentInvolved
                              : "-"}
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Was it in a good Condition:{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.equipmentCondition
                              ? data.equipmentCondition
                              : "-"}
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Was it designed for the task being completed:{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.equipmentRelatedToTask
                              ? data.equipmentRelatedToTask
                              : "-"}
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Was it being used correctly for the task:{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.equipmentUsage ? data.equipmentUsage : "-"}
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            What PPE was required and worn at the time of
                            accident:{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.ppe ? data.ppe : "-"}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <div className="header-access-view">
                        Section 3 - Accident Details
                      </div>
                      <div class="p-2">
                        {data.description ? (
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-12 dd-access">
                              {" "}
                              {data.description ? data.description : "-"}
                            </div>
                          </div>
                        ) : (
                          "No Details found"
                        )}
                      </div>
                    </div>

                    <div class="mb-3">
                      <div className="header-access-view">
                        Section 4 - First Aid / Hospital Details
                      </div>
                      <div class="p-2">
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Who was the accident first reported to :{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {data.reportedTo ? data.reportedTo : "-"}
                          </div>
                        </div>
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Was first aid administered :{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {data.firstAid
                              ? data.firstAid == 1
                                ? "Yes"
                                : "No"
                              : "-"}
                          </div>
                        </div>
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Who administered first aid?{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {data.firstAidBy ? data.firstAidBy : "-"}
                          </div>
                        </div>
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            {" "}
                            Was the IP removed to hospital?{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {data.removedToHospital
                              ? data.removedToHospital == 1
                                ? "Yes"
                                : "No"
                              : "-"}
                          </div>
                        </div>
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Nature of Injuery and where on the IPs body :{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {data.injuryNature ? data.injuryNature : "-"}
                          </div>
                        </div>
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Was the IP detained and for how long (days)?{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.detainedFor ? data.detainedFor : "-"}
                          </div>
                        </div>
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Treatment given (if known) :{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {data.treatment ? data.treatment : "-"}
                          </div>
                        </div>
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            {" "}
                            Name of hospital attended :{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {data.hospitalName ? data.hospitalName : "-"}
                          </div>
                        </div>
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">
                            Address of Hospital attended :{" "}
                          </div>
                          <div className="col-6 dd-access">
                            {data.hospitalAddress ? data.hospitalAddress : "-"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="header-access-view"> Uploaded Files</div>
                  <div className="d-flex flex-wrap">
                    {data.files &&
                      data.files.split(",").map((imgPreviewUrl) => (
                        <div
                          id="profile_pic_1_preview"
                          className="image-fixed m-2"
                        >
                          {commonService.outputImageTile(imgPreviewUrl)}
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <PDFDownloadLink
                    document={<MyDoc />}
                    className="mt-2 btn btn-primary pdf-download-btn"
                    fileName={"accident-report-" + id + ".pdf"}
                  >
                    {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download PDF"
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
