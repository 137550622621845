import React from "react";
import "./SafetyChecklist.css";
import Header from "../Header/Header";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useRef } from "react";
import { useAppState } from "../../state";
import UpDownArrow from "../../images/up-down-arrow.svg";
import Edit2 from "../../images/edit-2.svg";
import Search from "../../images/search.svg";
import Delete from "../../images/circle-cross.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import SafetyService from "../../services/safety.service";
import { onError } from "../../libs/errorLib";

import { commonService } from "../common/common.service";

import Table from "../common/Table";
import DataTable from 'react-data-table-component';
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import CustomPopup from "../Popup/CustomPopup";
import safetyService from "../../services/safety.service";

function SafetyChecklistTemplatesList({ title }) {
  const { user, userPermissions, selectedSite, selectedCompany } = useAppState()
  const history = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);
  const [pdfLoader, setPdfLoader] = useState(true);
  const [CSVPopup, setCSVPopup] = useState(true);
  const fileInputRef = useRef(null);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    getTemplates();
  }, [search, deleteCount]);

  useEffect(() => {
    getTemplates();
  }, []);

  async function getTemplates() {
    setLoading(true);
    let params;
    if (search) {
      params = { params: { search } };
    }
    try {
      const response = await SafetyService.getSafetyTemplates(params);
      if (response) {
        setData(response);
      }

      const savedSort = commonService.loadSortData('ChecklistTemplates');
      if (savedSort) {
          const { direction, columnSelector } = savedSort;
          setSortDirection(direction);
          setSortColumn(columnSelector);
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  const deleteConfirmation = async (name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete ${name} ?`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    return result;
  };

  const deleteSefetyChecklisttemplate = async (id) => {
    setLoading(true);
    try {
      const response = await SafetyService.deleteSafetyTemplate(id);
      if (response) {
        toast.success(response.message);
        setDeleteCount(deleteCount + 1);
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id, name) => {
    setDeleteLoading(true);
    try {
      let confirmDelete = false;
      let result = await deleteConfirmation(name);
      confirmDelete = result.isConfirmed;
      if (confirmDelete) {
        deleteSefetyChecklisttemplate(id);
      } else {
        setDeleteLoading(false);
      }
    } catch (err) {
      onError(err);
    } finally {
      setDeleteLoading(false);
    }
  };

  const tableHeader = [
    'ID', 'Name', 'Scoring System', 'Sections',
  ];

  function getActionsConvertableArray() {
    let array = [];

    // add all items from data but skip actions
    data.forEach((d) => {
      array.push({
        id: d.id,
        name: d.name ? d.name : '',
        scoringSystem: d.scoringSystem === 1 ? "Pass / Fail / NA" : "Yes / No / NA",
        sections: d.sections === 1 ? "Yes" : "No",
      })
    })

    return array;
  }

  const columns = [
    {
      name: 'View',
      selector: 'view',
      width: '100px',
      cell: row => <div>{row.view}</div>,
      sortFunction: (a, b) => {
        return a.id - b.id;
      }
    },
    {
      name: 'Name',
      selector: 'name',
      searchField: 'name',
      cell: row => <div>{row.name}</div>,
    },
    {
      name: 'Scoring System',
      selector: 'scoringSystem',
      searchField: 'scoringSystem',
      cell: row => <div>{row.scoringSystem}</div>,
    },
    {
      name: 'Sections',
      selector: 'sections',
      searchField: 'sections',
      cell: row => <div>{row.sections}</div>,
    },
  ];

  // add minWidth to each item in columns
  columns.forEach((column) => {
    if ( typeof column.minWidth == 'undefined' && typeof column.width == 'undefined' ) {
      column.minWidth = '200px';
    }

    // Add sorting to all columns
    if ( typeof column.sortable === 'undefined' ) {
      column.sortable = true;
    }

    if ( typeof column.sortFunction === 'undefined' && typeof column.searchField !== 'undefined' ) {
      column.sortFunction = (a, b) => a[column.searchField].localeCompare( b[column.searchField] );
    }
  });

  if ( user.role.includes("admin") ) {
    columns.push({
      name: 'Action',
      sortable: false,
      selector: 'actions',
      cell: row => <div>{row.actions}</div>,
      allowOverflow: true
    });
  }

  const downloadExcel = () => {    
    commonService.downloadArrayOfObjectsAsXLSX(
      getActionsConvertableArray(),
      tableHeader,
      title.toLowerCase().replace(' ', '-') + '.xlsx'
    );

  }
  
  const printList = () => {
    // Trigger the actual print
    commonService.triggerPrint(
      getActionsConvertableArray(),
      tableHeader,
      title
    );
  }

  const PDFReadyTable = () => {
    // Implement download PDF
    const pdfTableData = {
      column: tableHeader,
      data: getActionsConvertableArray()
    };

    return (
      <Document>
        <Page
          orientation="landscape"
          size="LETTER"
          style={{padding: "20px 16px 30px"}}
        >
          <Table
            data={pdfTableData}
            heading={title}
          />
        </Page>
      </Document>
    );
  }

  const downloadPDF = async () => {
    // If we have larger data set, show laoder.
    if ( data.length > 100 ) {
      setPdfLoader(false);
    }
    const blob = await pdf((
      <PDFReadyTable />
      )).toBlob();
      saveAs(blob, title.toLowerCase().replace(/ /g, '-').split('(')[0] + ".pdf");
      setPdfLoader(true);
  };

  const CustomSortIcon = () => (
    <span className="dt-sort-icon"></span>
  )

  const createData = () => {
    let parsedData = [];

    if ( data.length == 0 ) {
      return parsedData;
    }

    data.forEach((d) => {
      let row = {
        id: d.id,
        view: <span onClick={() => { history.push(`/safety/templates/${d.id}`); }} className="lists-id-col">{d.id}</span>,
        name: d.name ? d.name : '',
        scoringSystem: d.scoringSystem === 1 ? "Pass / Fail / NA" : "Yes / No / NA",
        sections: d.sections === 1 ? "Yes" : "No",
      };

      if ( user.role.includes( 'admin' ) ) {
        row.actions = <>
          {user.role.includes("admin") && (
                <>
                  <img
                    src={Delete}
                    alt=""
                    onClick={() => {
                      handleDelete(d.id, ' this template');
                    }}
                  />

                  <img
                    src={Edit2}
                    alt=""
                    className="ms-2"
                    onClick={() => {
                      history.push(`/safety/edit-safety-template/${d.id}`)
                    }}
                  />
                </>
              )}
        </>
      }

      parsedData.push(row);
    });

    if ( parsedData.length > 0 && search && search.length > 0 ) {
      // Search for matching string in all columns for each row
      parsedData = parsedData.filter((d) => {
        // Combine all column values in a row into a single string
        const rowValues = Object.entries(d)
          .filter(([key]) => key !== 'action') // Exclude 'action' property
          .map(([key, value]) => value)
          .join(' ')
          .toLowerCase();
        
        // Check if the search string is present in any of the columns
        return rowValues.includes(search.toLowerCase());
      });
    }

    return parsedData;
  }

  
  const handleCSVUpload = async (event) => {
    const formData = new FormData();
    formData.append("csvFile", event.target.files[0]);

    try {
      const response = await SafetyService.importCSV(formData);
      if (response) {
        toast.success(response.message);
        fileInputRef.current.value = '';
        setCSVPopup(true);
      }
    } catch (e) {
      onError(e || "CSV import failed");
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (column, direction, sortedRows) => {
    setSortColumn(column);
    setSortDirection(direction);
    commonService.saveSortData(column, direction, 'ChecklistTemplates');
};

  return (
    <div class="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Safety Checklist Templates", path: "/safety/templates" },
        ]}
      />

            <CustomPopup
                trigger={!CSVPopup}
                setTrigger={() => {
                setCSVPopup(true);
                }}
            >
                <div className="col-12 form_inner p-2">
                    <div className="input_icons">
                        <div className="input_field">
                            <div className="w-100 p-2 text-left">
                                <div className="input_icons">
                                    <div className="w-100 custom-file">
                                      <input 
                                        className="custom-file-input" 
                                        id="customFile" 
                                        type="file" 
                                        accept=".csv" 
                                        onChange={handleCSVUpload} 
                                        ref={fileInputRef} 
                                      />
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 p-2 text-left">
                                <div className="caption">
                                Please choose a CSV file to upload your Safety Checklist. You can download a sample file by clicking <a href={`${process.env.REACT_APP_API_BASEURL}/uploads/safetychecklistitems/items.csv`} download="items.csv">here</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomPopup>

      <CustomPopup
        showClose={false}
        trigger={!pdfLoader}
        setTrigger={() => {
          
        }}
      >
        <h3>Please Wait...</h3>
        <Spinner visible="true" />
      </CustomPopup>

      <div class="main_container">
        <div>
          <div class="form_inner table_search">
            <div class="input_icons">
              <img src={Search} alt="" />
              <div class="input_field">
                <input
                  type="text"
                  placeholder="Search Here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div>
              <button onClick={() => { printList() }}>Print</button>
              <button onClick={() => { downloadExcel() }}>Excel</button>
              <button onClick={() => { downloadPDF() }}>PDF</button>
              <button onClick={() => { setCSVPopup(false) }}>Import Items</button>
              
              <button
                className="GA2-btn"
                onClick={() => {
                  history.push("/safety/create-safety-template");
                }}
              >
                Add Safety Checklist Template +
              </button>
            </div>
          </div>
          <hr />
          <div id="wrapper">
            { ! loading ? <>
              <DataTable
                columns={columns}
                data={createData()}
                pagination={true}
                sortIcon={<CustomSortIcon />}
                onSort={handleSort}
                defaultSortFieldId={sortColumn}
                defaultSortAsc={sortDirection === 'asc'}
              />
            </> : <>
              <Spinner size="sm" animation="border" variant="light" />
            </>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SafetyChecklistTemplatesList;
