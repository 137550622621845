import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import "./ladderPermitView.css";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import {
  PDFDownloadLink,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";
import TabularData from "../common/pdfRenderer/tabularData";
import PDFFiles from "../common/pdfRenderer/files";

import Swal from "sweetalert2";

export default function ViewLadderPermit({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  console.log("id", id);
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [value, setValue] = useState(0);
  const [siteUsers, setSiteUsers] = useState([])
  const [popup, setPopup] = useState(true);
  const [activities, setActivities] = useState([])
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
  })
  console.log("1324144", data)

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const fetchLadderDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ladder/permit/details`,
        {
          ladderId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        console.log("re232", res.data);
        // if (res.data.length > 0) {
        setData(res.data[0]);
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
        getUserEmail(res.data[0].addedByInCompany)
        // }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };

  

  const fetchActivities = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/ladderactivity/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }

    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }

  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (id) {
      fetchLadderDetails();
      fetchActivities()
    }
  }, [id]);

  function handleBooleanValues(value) {
    return value !== "" && value !== null ? (value ? "Yes" : "No") : "N/A";
  }

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      let signature
      if(!fieldData.denied){
        signature = await handleSaveVerifySign()
      }
      handleSubmitAfterSave(signature)
    } catch (error) {
      toast.error("Signature is required.")
    }
  }

  const handleSubmitAfterSave = (signature) => {
    const form = new FormData();
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    form.append("approved", fieldData.approved);
    form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/update/ladder-permit`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/ladder-permits')
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          denied: false,
          signature: null,
        });
        handleClearSignature();
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const MyDoc = () => {

    const tabularSections = [
      {
        name: 'General Details',
        rows: [
          ['Site', data.siteName ? data.siteName : "-"],
          ['Department', data.departmentName ? data.departmentName : "-"],
          ['Contractor', data.contractorName ? data.contractorName : data.companyName],
          ['Operator', data.operatorName ? data.operatorName : data.operatorName],
          ['Submitted By', data.submittedBy ? data.submittedBy : "-"],
          ['Date Submitted', data.createdAt ? commonService.formatDate(data.createdAt, "Do MMM YYYY" ): "-"],
          ['Approved', data.isApproved == 1 ? "Yes" : data.isApproved == 0 ? "No" : "Pending"],
          ['Approved By', data.approvedBy? data.approvedBy : "-"],
          ['Approved Date & Time', data.approvedAt ? commonService.formatDate( new Date(parseInt(data.approvedAt)), "Do MMM YYYY" ) : "-"]
        ]
      },
      {
        name: 'Restriction or Obstructions',
        rows: [
          [data.obsMEQuestion ? data.obsMEQuestion : "M & E Obstruction", handleBooleanValues(data.obsME)],
          [data.obsOverheadQuestion ? data.obsOverheadQuestion : "Overhead Obstruction", handleBooleanValues(data.obsOverhead)],
          [data.rstFloorSpaceQuestion ? data.rstFloorSpaceQuestion : "Restricted FLoor Space", handleBooleanValues(data.rstFloorSpace)],
          [data.rstHeightQuestion ? data.rstHeightQuestion : "Restricted height from Floor to ceiling", handleBooleanValues(data.rstHeight)],
          [data.restrictedAccessQuestion ? data.restrictedAccessQuestion : "Restricted Access?", handleBooleanValues(data.restrictedAccess)],
          ['Restricted Access details', data.restrictedAccessText ? data.restrictedAccessText : "-"],
          
        ]
      },
      {
        name: 'Inspected Items',
        rows: [
          ['Location of Ladder', data.ladderLocation ? data.ladderLocation : "-"],
          ['Date & Time', data.date ? commonService.formatDate(data.date,"Do MMM YYYY") : "-"],
          ['Defects Found', handleBooleanValues(data.isDefectFound)],
          ['Inspected By', data.inspectorName ? data.inspectorName : "-"],
          ['Labelled / ID Mark', data.idMark ? data.idMark : "-"],
          [data.ladderTypeQuestion ? data.ladderTypeQuestion : "Type of Ladder", data.ladderType ? data.ladderType : "-"],
          [data.cutsInspectedQuestion ? 
            data.cutsInspectedQuestion 
            : "Are there any cracks or cuts", 
            handleBooleanValues(data.cutsInspected)],
          [data.ladderProperInspectedQuestion ? 
            data.ladderProperInspectedQuestion 
            : "Is the ladder appropriate for the task required", 
            handleBooleanValues(data.ladderProperInspected)],
          [data.ladderVisuallnspectedQuestion ? 
            data.ladderVisuallnspectedQuestion 
            : "Has a visual inspection of the ladder been carried out", 
            handleBooleanValues(data.ladderVisualInspected)],
          [data.identifyNumberInspectedQuestion ? 
            data.identifyNumberInspectedQuestion 
            : "Is ladder inspected on a GA 3 and identification number visual and up to date", 
            handleBooleanValues(data.identifyNumberInspected)
          ],
          [data.ladderWorkingOrderInspectedQuestion 
            ? data.ladderWorkingOrderInspectedQuestion 
            : "Are all ladder components fully intact, free form damage and in full working order",
          handleBooleanValues(data.ladderWorkingOrderInspected)]
        ]
      }
    ];

    if ( ! data.isApproved ) {
      tabularSections[0].rows.push( ['Reason Denied', data.denialComment ? data.denialComment : "-"] );
    }

    if(data.isDefectFound) {
      tabularSections[2].rows.push( ['Were corrective actions taken?', handleBooleanValues(data.actionTaken)] );
      tabularSections[2].rows.push( ['Details of defects found', data.detailsOfDefect] );
      tabularSections[2].rows.push( ['Details of Corrective actions', data.detailsActionTaken] );
    }

    const filesBlocks = [
      {
        name: "Uploaded Files",
        files: data.files,
      }
    ];

    if ( data.verifySignature ) {
      filesBlocks.push({
        name: "Submitter Signature",
        type: 'signatures',
        files: data.verifySignature
      });
    }

    if ( data.inspectorSignature ) {
      filesBlocks.push({
        name: "Inspector Signature",
        type: 'signatures',
        files: data.inspectorSignature
      });
    }

    if ( data.approveSignature ) {
      filesBlocks.push({
        name: "Approver Signature",
        type: 'signatures',
        files: data.approveSignature
      });
    }

    return (
      <PDFRenderer name={"Ladder Permit #" + id} user={userDetails}>
        
        <TabularData
          tabularSections={tabularSections}
        />

        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}

      </PDFRenderer>
    )
  }

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/ladder/" + deleteId)
        .then(function (response) {
          toast.success("Ladder Permit archived successfully");
          window.location = '/ladder-permits';
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Ladder Permit"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Ladder Permit List", path: "/ladder-permits" },
          { name: "Ladder Permit", path: `/ladder-permit/${id}` },
        ]}
      />
      <CustomPopup
        trigger={!popup}
        setTrigger={() => {
          setPopup(true)
          setFieldData({
            approved: false,
            denied: false,
            approvedComment: "",
            deniedComment: "",
            signature: null,
          })
        }}
      >
        {fieldData.approved && <>
          <div className="sign_image">
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 263, height: 270 }}
            />
            <span
              className="cancel-custom cursor-pointer"
              onClick={handleClearSignature}
            >
              <i
                className="clear_btn  text-danger fa fa-times-circle"
                aria-hidden="true"
              ></i>
            </span>
          </div>
          {/* <div className="sign_save_reset_btn">
            <button
              className="btn btn-primary"
              onClick={handleSaveVerifySign}
            >
              Save
            </button>
          </div> */}
        </>
        }
        <div className="col-12 form_inner p-2">
          <label for="">
            {fieldData.approved ? "Approval Comment" : "Reason for Denial"} {fieldData.approved ? '' : <><span className="text-danger">*</span></> }
          </label>
          <div className="input_icons">
            <div className="input_field">
              <textarea
                name="approvedComment"
                maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                rows="5"
                cols="100"
                onChange={(e) => {
                  if (fieldData.approved) {
                    setFieldData({
                      ...fieldData,
                      approvedComment: e.target.value,
                    })
                  } else {
                    setFieldData({
                      ...fieldData,
                      deniedComment: e.target.value,
                    })
                  }
                }}
              ></textarea>
            </div>
          </div>
          <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            // disabled={!fieldData.approvedComment||!fieldData.deniedComment||!(fieldData.approved?fieldData.signature:false)}
            disabled={
              fieldData.approved ?
                false && !(fieldData.approvedComment)
                :
                !(fieldData.deniedComment)
            }
            onClick={() => {
              handleSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>
      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-ladder-view">General Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          <div className="mb-1 d-flex dl-ladder">
                            <div className="col-6 dd-ladder">Site : </div>
                            <div className="col-6 dd-ladder">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-ladder">
                            <div className="col-6 dd-ladder">Department : </div>
                            <div className="col-6 dd-ladder">
                              {" "}
                              {data.departmentName ? data.departmentName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-ladder">
                            <div className="col-6 dd-ladder">
                              Operator Name:{" "}
                            </div>
                            <div className="col-6 dd-ladder">
                              {" "}
                              {data.operatorName ? data.operatorName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-ladder">
                            <div className="col-6 dd-ladder">Contractor : </div>
                            <div className="col-6 dd-ladder">
                              {data.contractorName ? data.contractorName : data.companyName}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-ladder">
                            <div className="col-6 dd-ladder">
                              Submitted By :{" "}
                            </div>
                            <div className="col-6 dd-ladder">
                              {" "}
                              {data.submittedBy ? data.submittedBy : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-ladder">
                            <div className="col-6 dd-ladder">
                              Date Submitted :{" "}
                            </div>
                            <div className="col-6 dd-ladder">
                              {data.createdAt
                                ? commonService.formatDate(
                                  data.createdAt,
                                  "Do MMM YYYY"
                                )
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-ladder">
                            <div className="col-6 dd-ladder">Approved : </div>
                            <div className="col-6 dd-ladder">
                              {/* {handleBooleanValues(data.isApproved)} */}
                              <span className={"roundPill " + (data.isApproved == 2 ? "yellow-bg" : ( data.isApproved == 1 ? "green-bg" : "red-bg" ) ) }>
                              {data.isApproved == 1
                                ? "Yes"
                                : data.isApproved == 0
                                  ? "No"
                                  : "Pending"}
                                </span>
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-ladder">
                            <div className="col-6 dd-ladder">
                              Approved By :{" "}
                            </div>
                            <div className="col-6 dd-ladder">
                              {data.approvedByName ? data.approvedByName : "-"}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-ladder">
                            <div className="col-6 dd-ladder">
                              Approved Date & Time :{" "}
                            </div>
                            <div className="col-6 dd-ladder">
                              {data.approvedAt
                                ? commonService.formatDate(
                                  new Date(parseInt(data.approvedAt)),
                                  "Do MMM YYYY"
                                )
                                : "-"}
                            </div>
                          </div>
                          {!data.isApproved && <div className="mb-1 d-flex dl-ladder alert alert-danger">
                            <div className="col-6 dd-ladder">Reason Denied : </div>
                            <div className="col-6 dd-ladder">
                              {data.denialComment ? data.denialComment : "-"}{" "}
                            </div>
                          </div>}
                        </div>
                      </div>
                      {siteUsers.filter(user => (user.type == "admin") && (user.userid == userDetails.id)).length && (data.isApproved == 2) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            approved: true
                          })
                          setPopup(false)
                        }}>Approve</button>
                        <button className="btn btn-danger ms-2" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            denied: true
                          })
                          setPopup(false)
                        }}>Deny</button>
                      </>
                        : null}

                      {id && ( userDetails.role.includes("admin") || userPermissions.includes( 'deleteLadderPermits' ) ) && data.isDeleted != 1 ? <>
                        <button className="btn btn-outline-danger ms-1 mt-2" onClick={() => handleArchive(id)}>
                          Archive
                        </button>
                      </> : ''}
                    </div>
                    <div className="mb-3">
                      <div className="header-ladder-view">Signature(s)</div>

                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="nav tabs example"
                      >
                        <Tab label="Submitter" {...a11yProps(0)} />
                        <Tab label="Inspector" {...a11yProps(1)} />
                        <Tab label="Approver" {...a11yProps(2)} />
                      </Tabs>
                      <TabPanel value={value} index={0}>
                        <div className="signature-div">
                          <div className="p-2 signature-div-inner">
                            <img
                              onError={(e) => {
                                e.target.src = DefaultImage;
                              }}
                              className="obj-fit-cover"
                              height="250"
                              width="250"
                              src={
                                data.verifySignature
                                  ? process.env.REACT_APP_API_BASEURL +
                                  data.verifySignature
                                  : DefaultImage
                              }
                            ></img>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <div className="signature-div">
                          <div className="p-2 signature-div-inner">
                            <img
                              onError={(e) => {
                                e.target.src = DefaultImage;
                              }}
                              className="obj-fit-cover"
                              height="250"
                              width="250"
                              src={
                                data.inspectorSignature
                                  ? process.env.REACT_APP_API_BASEURL +
                                  data.inspectorSignature
                                  : DefaultImage
                              }
                            ></img>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        <div className="signature-div">
                          <div className="p-2 signature-div-inner">
                            <img
                              onError={(e) => {
                                e.target.src = DefaultImage;
                              }}
                              className="obj-fit-cover"
                              height="250"
                              width="250"
                              src={
                                data.approveSignature
                                  ? process.env.REACT_APP_API_BASEURL +
                                  data.approveSignature
                                  : DefaultImage
                              }
                            ></img>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-7 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-ladder-view">
                        Restriction or Obstructions
                      </div>
                      <div className="p-2 main-restriction-div">
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.obsMEQuestion ? data.obsMEQuestion : "M & E Obstruction"} :{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(data.obsME)}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.obsOverheadQuestion ? data.obsOverheadQuestion : "Overhead Obstruction"} :{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(data.obsOverhead)}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.rstFloorSpaceQuestion ? data.rstFloorSpaceQuestion : "Restricted FLoor Space"} :{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(data.rstFloorSpace)}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.rstHeightQuestion ? data.rstHeightQuestion : "Restricted height from Floor to ceiling"} :{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(data.rstHeight)}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.restrictedAccessQuestion ? data.restrictedAccessQuestion : "Restricted Access?"} :{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(data.restrictedAccess)}
                            </span>
                          </div>
                        </div>

                        {data.restrictedAccess && data.restrictedAccessText ? <>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                              Restricted Access details: {" "}
                              </span>
                              <span className="rst-details">
                                {data.restrictedAccessText}
                              </span>
                            </div>
                          </div>
                        </> : ''}

                        {data.otherRestriction && <>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Other Restrictions or Obstructions :{" "}
                              </span>
                              <span className="rst-details">
                                {data.otherRestriction}
                              </span>
                            </div>
                          </div>
                        </>}

                      </div>
                    </div>
                    <div className="mb-3">
                      <div className="header-ladder-view">Inspected Items</div>
                      <div className="p-2 main-restriction-div">
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Location of Ladder :{" "}
                            </span>
                            <span className="rst-details">
                              {data.ladderLocation ? data.ladderLocation : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Date & Time :{" "}
                            </span>
                            <span className="rst-details">
                              {data.date
                                ? commonService.formatDate(
                                  data.date,
                                  "Do MMM YYYY"
                                )
                                : "-"}{" "}
                              @ {data.time}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Defects Found :{" "}
                            </span>
                            <span className="rst-details">
                              <span className={"roundPill " + (data.isDefectFound ? " red-bg" : " green-bg")}>{handleBooleanValues(data.isDefectFound)}</span>
                            </span>
                          </div>
                        </div>
                                
                        {data.isDefectFound ? <>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Were corrective actions taken? :{" "}
                              </span>
                              <span className="rst-details">
                                {handleBooleanValues(data.actionTaken)}
                              </span>
                            </div>
                          </div>
                        </> : ''}

                        {data.isDefectFound && data.detailsOfDefect ? <>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Details of defects found :{" "}
                              </span>
                              <span className="rst-details">
                                {data.detailsOfDefect}
                              </span>
                            </div>
                          </div>
                        </> : ''}

                        {data.isDefectFound && data.actionTaken ? <>
                          <div className="row mb-2">
                            <div className="d-flex">
                              <span className="rst-title me-2">
                                Details of Corrective actions :{" "}
                              </span>
                              <span className="rst-details">
                                {data.detailsActionTaken}
                              </span>
                            </div>
                          </div>
                        </> : ''}

                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Inspected By :{" "}
                            </span>
                            <span className="rst-details">
                              {data.inspectorName ? data.inspectorName : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Labelled / ID Mark :{" "}
                            </span>
                            <span className="rst-details">
                              {data.idMark ? data.idMark : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.ladderTypeQuestion ? data.ladderTypeQuestion : "Type of Ladder"} :{" "}
                            </span>
                            <span className="rst-details">
                              {data.ladderType ? data.ladderType : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.cutsInspectedQuestion ? data.cutsInspectedQuestion : "Are there any cracks or cuts"} :{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(data.cutsInspected)}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.ladderProperInspectedQuestion ? data.ladderProperInspectedQuestion : "Is the ladder appropriate for the task required"} :{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(data.ladderProperInspected)}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.ladderVisuallnspectedQuestion ? data.ladderVisuallnspectedQuestion : "Has a visual inspection of the ladder been carried out"} :{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(data.ladderVisualInspected)}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.identifyNumberInspectedQuestion ? data.identifyNumberInspectedQuestion : "Is ladder inspected on a GA 3 and identification number visual and up to date"}:{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(
                                data.identifyNumberInspected
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              {data.ladderWorkingOrderInspectedQuestion ? data.ladderWorkingOrderInspectedQuestion : "Are all ladder components fully intact, free form damage and in full working order"} :{" "}
                            </span>
                            <span className="rst-details">
                              {handleBooleanValues(
                                data.ladderWorkingOrderInspected
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">

                    <div className="mb-3" style={{ border: '1px solid #e2e6ea', borderRadius: '8px', overflow: "auto", maxHeight: "670px" }}>
                      <div className="header-access-view position-sticky" style={{ top: 0 }}>Activity</div>
                      {activities.map(activity => (<div className="m-2">
                        <div className="p-2 signature-div-inner">
                          <div className="d-flex justify-content-between mb-2">
                            <span className="rst-title me-2">
                              {activity.action} :{" "}
                            </span>
                            <span className="rst-title me-2 text-secondary">
                              {activity.datetime}
                            </span>
                          </div>
                          {activity.comment ?
                            <p>{activity.comment}</p>
                            : null}
                          {activity.signature ? <img
                            onError={(e) => {
                              e.target.src = DefaultImage;
                            }}
                            className="obj-fit-cover"
                            height="250"
                            width="250"
                            src={
                              activity.signature
                                ? process.env.REACT_APP_API_BASEURL +
                                activity.signature
                                : DefaultImage
                            }
                          ></img> : null}
                        </div>
                      </div>))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="header-ladder-view"> Uploaded Files</div>
                  <div className="d-flex flex-wrap">
                    {data.files && data.files.split(",").map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                      {commonService.outputImageTile(imgPreviewUrl)}
                    </div>))}
                  </div>
                </div>
              </div>

            {data.isApproved == 1 && <>
              <div className="row mt-3">
                <div className="col-md-4 mt-3">
                  <PDFDownloadLink
                    document={<MyDoc />}
                    className="mt-2 btn btn-primary pdf-download-btn"
                    fileName={"ladder-permit-" + id + ".pdf"}
                  >
                    {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download PDF"
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            </>}
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
