import React from "react";
import "./list.css";
import Header from "../../Header/Header";
import { useAppState } from "../../../state";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../helpers/request";
import { useEffect, useState } from "react";
import Edit2 from "../../../images/edit-2.svg";
import Delete from "../../../images/archive-icon.svg";
import Restore from "../../../images/undo.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { commonService } from "../../common/common.service";

import Table from "../../common/Table";
import DataTable from 'react-data-table-component';
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import CustomPopup from "../../Popup/CustomPopup";

export default function CommonGroupInspectionsList({ title, isAll, isDeleted }) {
  const { user, selectedSite, userPermissions, selectedCompany } = useAppState();
  const cookies = new Cookies();
  const history = useHistory();
  const userToken = localStorage.getItem("cs_token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyId, setUserID] = useState("");
  const [search, setSearch] = useState("");
  const [pdfLoader, setPdfLoader] = useState(true);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    if (companyId) {
      getInspections();
    }
  }, [companyId, selectedSite]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  useEffect(() => {
    userDetail();
  }, []);

  const getInspections = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/groupinspections/groupinspections`,
        {
          companyId: companyId,
          userId: user.id,
          isAll: isAll,
          isDeleted: isDeleted,
          siteId: localStorage.getItem(`${user.id}-currentSite`),
          contractorId: user.role.includes("admin") ? null : user.contractorId
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // if (res.data.length > 0) {
        setData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );

        const savedSort = commonService.loadSortData('GroupInspections');
        if (savedSort) {
          const { direction, columnSelector } = savedSort;
          setSortDirection(direction);
          setSortColumn(columnSelector);
        }

        setLoading(false);
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log("errr", err);
      });
  };

  function userDetail() {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          if ( res.data[0].role == 'superadmin' ) {
            setUserID( selectedCompany );
          } else {
            setUserID(
              res.data[0].added_by ? res.data[0].added_by : res.data[0].id
            );
          }
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive ${name}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/groupinspection/" + id)
        .then(function (response) {
          getInspections();
          toast.success("Group Safety Mananger Visit archived successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Unable to archive Group Safety Manager Visit"
          );
        });
    }
  };
  
  const handleUndelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to restore ${name}`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, restore it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/undelete-groupinspection/" + id)
        .then(function (response) {
          getInspections();
          toast.success("Group Safety Mananger Visit restored successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Unable to restore Group Safety Manager Visit"
          );
        });
    }
  };

  const tableHeader = [
    'ID', 'Date', 'Completed', 'Site', 'Department', 'Submitted By'
  ];

  function getActionsConvertableArray() {
    let array = [];

    // add all items from data but skip actions
    data.forEach((d) => {
      array.push({
        id: d.id,
        visitDate: d.visitDate ? commonService.formatDate(d.visitDate, "Do MMM YYYY") : '',
        isCompleted: d.isCompleted == 1 ? 'Yes' : 'No',
        siteName: d.siteName ? d.siteName : '',
        departmentName: d.departmentName ? d.departmentName : '',
        submittedBy: d.submittedBy ? d.submittedBy : '',
      })
    })

    return array;
  }

  const columns = [
    {
      name: 'View',
      selector: 'view',
      width: '100px',
      cell: row => <div>{row.view}</div>,
      sortFunction: (a, b) => {
        return a.id - b.id;
      }
    },
    {
      name: 'Date',
      selector: 'visitDate',
      searchField: 'visitDate',
      cell: row => row.visitDate,
      sortFunction: (a, b) => {
        return a.visitDatePlain - b.visitDatePlain;
      }
    },
    {
      name: 'Completed',
      selector: 'isCompleted',
      searchField: 'isCompleted',
      cell: row => <div><span className={`roundPill ${row.isCompletedValue == 0 ? "red-bg" : "green-bg"}`}>{row.isCompleted}</span></div>,
    },
    {
      name: 'Site',
      selector: 'siteName',
      searchField: 'siteName',
      cell: row => <div>{row.siteName}</div>,
    },
    {
      name: 'Department',
      selector: 'departmentName',
      searchField: 'departmentName',
      cell: row => <div>{row.departmentName}</div>,
    },
    {
      name: 'Submitted By',
      selector: 'submittedBy',
      searchField: 'submittedBy',
      cell: row => <div>{row.submittedBy}</div>,
    },
  ];

  // add minWidth to each item in columns
  columns.forEach((column) => {
    if ( typeof column.minWidth == 'undefined' && typeof column.width == 'undefined' ) {
      column.minWidth = '200px';
    }

    // Add sorting to all columns
    if ( typeof column.sortable === 'undefined' ) {
      column.sortable = true;
    }

    if ( typeof column.sortFunction === 'undefined' && typeof column.searchField !== 'undefined' ) {
      column.sortFunction = (a, b) => a[column.searchField].localeCompare( b[column.searchField] );
    }
  });

  if ( user.role.includes("admin") || userPermissions.includes( 'deleteGroupSafetyVisits' ) ) {
    columns.push({
      name: 'Action',
      sortable: false,
      selector: 'actions',
      cell: row => <div>{row.actions}</div>,
      allowOverflow: true
    });
  }

  const downloadExcel = () => {    
    commonService.downloadArrayOfObjectsAsXLSX(
      getActionsConvertableArray(),
      tableHeader,
      title.toLowerCase().replace(' ', '-') + '.xlsx'
    );

  }
  
  const printList = () => {
    // Trigger the actual print
    commonService.triggerPrint(
      getActionsConvertableArray(),
      tableHeader,
      title
    );
  }

  const PDFReadyTable = () => {
    // Implement download PDF
    const pdfTableData = {
      column: tableHeader,
      data: getActionsConvertableArray()
    };

    return (
      <Document>
        <Page
          orientation="landscape"
          size="LETTER"
          style={{padding: "20px 16px 30px"}}
        >
          <Table
            data={pdfTableData}
            heading={title}
          />
        </Page>
      </Document>
    );
  }

  const downloadPDF = async () => {
    // If we have larger data set, show laoder.
    if ( data.length > 100 ) {
      setPdfLoader(false);
    }
    const blob = await pdf((
      <PDFReadyTable />
      )).toBlob();
      saveAs(blob, title.toLowerCase().replace(/ /g, '-').split('(')[0] + ".pdf");
      setPdfLoader(true);
  };

  const CustomSortIcon = () => (
    <span className="dt-sort-icon"></span>
  )

  const createData = () => {
    let parsedData = [];

    if ( data.length == 0 ) {
      return parsedData;
    }

    data.forEach((d) => {
      let row = {
        id: d.id,
        view: <span onClick={() => { history.push(`/groupinspection/${d.id}`); }} className="lists-id-col">{d.id}</span>,
        lastAuditDate: d.lastAuditDate ? commonService.formatDate(d.lastAuditDate, "Do MMM YYYY") : '',
        visitDate: d.visitDate ? commonService.formatDate(d.visitDate, "Do MMM YYYY") : '',
        
        isCompleted: d.isCompleted == 1 ? 'Yes' : 'No',
        submittedBy: d.submittedBy ? d.submittedBy : '',
        
        siteName: d.siteName ? d.siteName : '',
        departmentName: d.departmentName ? d.departmentName : '',
        visitDatePlain: d.visitDate ? commonService.formatDate(d.visitDate, "YYYYMMDD") : 0,
        isCompletedValue: d.isCompleted,
        lastAuditDatePlain: d.lastAuditDate ? commonService.formatDate(d.lastAuditDate, "YYYYMMDD") : 0
      };

      if ( user.role.includes( 'admin' ) || userPermissions.includes( 'deleteGroupSafetyVisits' ) ) {
        row.actions = <>
          {!isDeleted && ( user.role.includes("admin") || userPermissions.includes( 'deleteGroupSafetyVisits' ) ) && (
                <img
                  src={Delete}
                  alt=""
                  onClick={() => {
                    handleDelete(d.id, ' this inspection');
                  }}
                />
              )}
          {isDeleted && ( user.role.includes("admin") || userPermissions.includes( 'deleteGroupSafetyVisits' ) ) && (
              <img
                src={Restore}
                alt=""
                title="Restore"
                className="undelete-btn"
                onClick={() => {
                  handleUndelete(d.id, ' this inspection');
                }}
              />
            )}
              {!isDeleted && userPermissions.includes('submitgroupSafetyVisits') && (d.isCompleted == 0) && <img
                src={Edit2}
                alt=""
                className="ms-2"
                onClick={() => {
                  history.push(`/edit-groupinspection/${d.id}`)
                }}
              />}
        </>
      }

      parsedData.push(row);
    });

    if ( parsedData.length > 0 && search && search.length > 0 ) {
      // Search for matching string in all columns for each row
      parsedData = parsedData.filter((d) => {
        // Combine all column values in a row into a single string
        const rowValues = Object.entries(d)
          .filter(([key]) => key !== 'action') // Exclude 'action' property
          .map(([key, value]) => value)
          .join(' ')
          .toLowerCase();
        
        // Check if the search string is present in any of the columns
        return rowValues.includes(search.toLowerCase());
      });
    }

    return parsedData;
  }

  const handleSort = (column, direction, sortedRows) => {
    setSortColumn(column);
    setSortDirection(direction);
    commonService.saveSortData(column, direction, 'GroupInspections');
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleRowSelected = React.useCallback(state => {
      setSelectedRows(state.selectedRows);
  }, []);

  const handleBulkDelete = async () => {
      const result = await Swal.fire({
          title: `Are you sure you want to archive all chosen Group Inspection(s)`,
          text: "You have chosen " + selectedRows.length + " Group Inspection(s)",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, archive all!",
      });

      if (result.isConfirmed) {
          axiosInstance
              .delete(process.env.REACT_APP_API_BASEURL + "/groupinspections-all/" + selectedRows.map(row => row.id).join(","))
              .then(function (response) {
                  getInspections();
                  toast.success("Group Inspection(s) archived successfully");
                  setSelectedRows([]);
                  setToggleCleared(true);
                  //location.reload(); // Reloading ensures sites list is updated.
              })
              .catch(function (error) {
                  console.log(error);
                  toast.error(error?.response?.data?.message || "Unable to archive Group Inspection(s)");
              });
      }
  };

  const handleBulkRestore = async () => {
      const result = await Swal.fire({
          title: `Are you sure you want to restore all chosen Group Inspection(s)`,
          text: "You have chosen " + selectedRows.length + " Group Inspection(s)",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, restore all!",
      });

      if (result.isConfirmed) {
          axiosInstance
              .post(process.env.REACT_APP_API_BASEURL + "/undelete-groupinspections-all/" + selectedRows.map(row => row.id).join(","))
              .then(function (response) {
                  getInspections();
                  toast.success("Group Inspection(s) restored successfully");
                  setSelectedRows([]);
                  setToggleCleared(true);
                  //location.reload(); // Reloading ensures sites list is updated.
              })
              .catch(function (error) {
                  console.log(error);
                  toast.error(error?.response?.data?.message || "Unable to restore Group Inspection(s)");
              });
      }
  };

  const contextActions = React.useMemo(() => {
      return <></>;
  }, [selectedRows, toggleCleared]);

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Group Safety Manager Visits List", path: "/groupinspections" },
        ]}
      />

      <CustomPopup
        showClose={false}
        trigger={!pdfLoader}
        setTrigger={() => {
          
        }}
      >
        <h3>Please Wait...</h3>
        <Spinner visible="true" />
      </CustomPopup>

      <div className="main_container">
        <div>
          <div className="form_inner table_search">
            <div className="input_icons">
              <img src={require("../../../images/search.png")} alt="" />
              <div className="input_field">
                <input
                  type="text"
                  placeholder="Search Here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="listing-buttons">
              <button onClick={() => { printList() }}>Print</button>
              <button onClick={() => { downloadExcel() }}>Excel</button>
              <button onClick={() => { downloadPDF() }}>PDF</button>

              { userPermissions.includes('submitgroupSafetyVisits') && <>
                <button
                  className="GA2-btn"
                  onClick={() => {
                    history.push("/groupinspections/add-groupinspection");
                  }}
                >
                  Add Group Safety Manager Visit +
                </button>
              </> }
            </div>
          </div>
          <hr />
          {selectedRows && selectedRows.length > 0 && <>
              <div className="d-flex justify-content-between align-items-center selected-rows-actions">
                  <span>{selectedRows.length} Group Inspection(s) selected.</span>
                  <span>
                      {isDeleted ? <>
                          <button className='btn btn-danger' onClick={handleBulkRestore}>Restore Selected</button>
                      </> : <>
                          <button className='btn btn-danger' onClick={handleBulkDelete}>Archive Selected</button>
                      </>}
                  </span>
              </div>
              <hr />
          </>}
          <div id="wrapper">
            { ! loading ? <>
              <DataTable
                columns={columns}
                data={createData()}
                pagination={true}
                sortIcon={<CustomSortIcon />}
                onSort={handleSort}
                {...( user.role.includes("admin") || userPermissions.includes( 'deleteGroupSafetyVisits' ) ? {
                    selectableRows: true,
                    contextActions: contextActions,
                    onSelectedRowsChange: handleRowSelected,
                    clearSelectedRows: toggleCleared
                  } : {})}
                defaultSortFieldId={sortColumn}
                defaultSortAsc={sortDirection === 'asc'}
              />
            </> : <>
              <Spinner size="sm" animation="border" variant="light" />
            </>}
          </div>
        </div>
      </div>
    </div>
  );
}
