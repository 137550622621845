import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import fileIcon from "../../images/openfolderwhite.svg";
import { getBase64 } from "../common/imageHelper";
import Swal from "sweetalert2";

export default function ViewPPEIssueReports({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([])
  const [activities, setActivities] = useState([])
  const [popup, setPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
    actionTaken: false,
    actionStatus: false,
    actionFiles: false,
  });

  const [files, setFiles] = useState([]);

  const statusOptions = [
    { value: 'Open', label: 'Open', name: 'actionStatus', },
    { value: 'In Progress', label: 'In Progress', name: 'actionStatus', },
    { value: 'Closed', label: 'Closed', name: 'actionStatus', },
  ];

  const fetchSafetyDisciplinaryLogs = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ppe-issue-reports/details`,
        {
          ppeIssueReportId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
        getUserEmail(res.data[0].addedByInCompany)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };


  const fetchActivities = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/safety-observations-activities/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchSafetyDisciplinaryLogs();
      fetchActivities();
    }
  }, [id]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      // let signature
      // if(!fieldData.denied){
      //   signature = await handleSaveVerifySign()
      // }
      handleSubmitAfterSave()
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleFileChange = (e) => {
    const { name, value, files } = e.target || e;
    if (name == "newfiles") {
      Promise.all(Array.from(e.target.files).map(file => getBase64(file)))
        .then((result) => {
          setFiles(result);
        })
        .catch((err) => {
          console.log(err);
        });
      setFieldData({
        ...fieldData,
        actionFiles: files,
      });
    }
  };

  const handleSubmitAfterSave = () => {
    const form = new FormData();
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    if (fieldData.approved) {
      form.append("approved", fieldData.approved);
    }
    if (fieldData.completed) {
      form.append("completed", fieldData.completed);
    }

    if (fieldData.actionTaken) {
      form.append("actionTaken", fieldData.actionTaken);
      form.append("actionStatus", fieldData.actionStatus);
      form.append("actionComment", fieldData.actionComment);
      form.append("actionRootCause", fieldData.actionRootCause);

      Object.entries(fieldData?.actionFiles)?.map( ([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      } );
    }

    // form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post((fieldData.resubmitted || fieldData.completed) ? `${process.env.REACT_APP_API_BASEURL}/resubmit/safety-observation` : ( fieldData.actionTaken ? `${process.env.REACT_APP_API_BASEURL}/safety-observation/action-taken` : `${process.env.REACT_APP_API_BASEURL}/update/safety-observation` ), form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          signature: null,
          actionComment: "",
          actionTaken: false,
          actionStatus: "",
          actionComment: "",
          actionRootCause: "",
        });
        
        setPopup(true);

        fetchSafetyDisciplinaryLogs();
        fetchActivities();
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/ppe-issue-report/" + deleteId)
        .then(function (response) {
          toast.success("PPE Issue Report archived successfully");
          window.location = '/ppe-issue-reports';
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive PPE Issue Report"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "PPE Issue Reports List", path: "/ppe-issue-reports/" },
          { name: "PPE Issue Report", path: `/ppe-issue-reports/${id}` },
        ]}
      />
   
      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <div className="row">
                <div className="col-12 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Site : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Department : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.departmentName ? data.departmentName : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Item : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.item ? data.item : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Company : </div>
                            <div className="col-6 dd-access">
                              { data.contractorId == -1 ? data.otherCompany: ( data.contractorName
                              ? data.contractorName
                              : "-" ) }
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Received By : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.receivedBy ? data.receivedBy : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Comment : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.comments ? data.comments : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Date & Time :{" "}
                            </div>
                            <div className="col-6 dd-access">
                            {data.createdAt
                                ? ( commonService.formatDate(
                                  data.createdAt,
                                  "Do MMM YYYY @ HH:MM"
                                ) == 'Invalid date' ? data.createdAt : commonService.formatDate(
                                  data.createdAt,
                                  "Do MMM YYYY @ HH:MM"
                                ))
                                : "-"}{" "}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Submitted By :</div>
                            <div className="col-6 dd-access">{data.submittedBy ? data.submittedBy : "-"}</div>
                          </div>
                          
                          <div className="p-2"></div>

                          { userPermissions.includes('submitppeIssueReports') && data.isDeleted != '1' && <>
                            <button className="btn btn-outline-success ms-1" onClick={() => {
                              history.push(`/edit-ppe-issue-report/${data.id}`)
                            }}>Edit</button>
                          </> }

                          {id && ( userDetails.role.includes("admin") || userPermissions.includes( 'deletePPEIssueReports' ) ) && data.isDeleted != 1 ? <>
                            <button className="btn btn-outline-danger ms-1" onClick={() => handleArchive(id)}>
                              Archive
                            </button>
                          </> : ''}

                          <div className="p-2"></div>
                 
                        </div>
                      </div>

                      <div className="header-access-view mb-2">Signature</div>

                      <div className="p-2 signature-div-inner">
                        <img
                          onError={(e) => {
                            e.target.src = DefaultImage;
                          }}
                          className="obj-fit-cover"
                          height="250"
                          width="250"
                          src={
                            data.verifySignature
                              ? process.env.REACT_APP_API_BASEURL +
                              data.verifySignature
                              : DefaultImage
                          }
                          
                        />
                      </div>
                      

                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
