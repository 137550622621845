import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import PDFFiles from "../common/pdfRenderer/files";
import {
  PDFDownloadLink,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";
import { onError } from "../../libs/errorLib";
import accidentService from "../../services/accident.service";
import Swal from "sweetalert2";

export default function ViewAccidentNotifications({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([])
  const [activities, setActivities] = useState([])
  const [comments, setComments] = useState([])
  const [popup, setPopup] = useState(true);
  const [insurancePopup, setInsurancePopup] = useState(true);
  const [commentPopup, setcommentPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
    insuranceReported: null,
    accidentStatus: null,
    accidentLocation: null,
    accidentType: null,
    accidentTypeAdmin: null,
  })

  const [insuranceData, setInsuranceData] = useState({
    insuranceReported: null,
  })
  
  const [commentData, setcommentData] = useState({
    comment: null,
  })

  const accidentTypes = [
    { value: 'Reportable', label: 'Reportable', name: 'accidentTypeAdmin', },
    { value: 'First Aid', label: 'First Aid', name: 'accidentTypeAdmin', },
    { value: 'Info Only', label: 'Info Only', name: 'accidentTypeAdmin', },
    { value: 'Member of the Public', label: 'Member of the Public', name: 'accidentTypeAdmin', },
  ];
  
  
  const accidentLocations = [
    { value: 'UK', label: 'UK', name: 'accidentType', },
    { value: 'Ireland', label: 'Ireland', name: 'accidentType', },
    { value: 'Other', label: 'Other', name: 'accidentType', },
  ];
  
  const accidentStatuses = [
    { value: 'Received', label: 'Received', name: 'accidentType', },
    { value: 'Settled', label: 'Settled', name: 'accidentType', },
    { value: 'Closed', label: 'Closed', name: 'accidentType', },
    { value: 'Payment Offered', label: 'Payment Offered', name: 'accidentType', },
    { value: 'Statutory Barred', label: 'Statutory Barred', name: 'accidentType', },
  ];

  const fetchAccidentNotificationsDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/AccidentNotifications/notification/details`,
        {
          notificationId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setFieldData({
          ...fieldData,
          accidentLocation: res.data[0].accidentLocation,
          accidentStatus: res.data[0].accidentStatus,
          accidentType: res.data[0].accidentType,
          accidentTypeAdmin: res.data[0].accidentTypeAdmin,
        });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
        getUserEmail(res.data[0].addedByInCompany)

        setInsuranceData( {
          insuranceReported: res.data[0].insuranceReported == 'yes' || res.data[0].insuranceReported == 1 ? true : false,
          insuranceNumber: res.data[0].insuranceNumber
        } );
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };

  const markNotificationAsRead = async(id) => {
    try {
      if (id) {
        const response = await accidentService.markNotificationAsRead(id);
        if (response) {
          console.log(response);
        }
      }
    } catch (e) {
      onError(e || "Unable to process request");
      console.log("errr", e);
    } finally {
      setLoading(false);
    }
  }


  const fetchActivities = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/accident-notification-activities/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }
  
  const fetchComments = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/accident-notification-comments/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setComments(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchAccidentNotificationsDetails();
      fetchActivities();
      fetchComments();
    }
  }, [id]);

  useEffect(() => {
    if(data.id && !data.readAt) {
      markNotificationAsRead(id);
    }
  }, [data]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      let signature
      if(!fieldData.denied){
        signature = await handleSaveVerifySign()
      }
      handleSubmitAfterSave(signature)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }
  
  const handleInsuranceSubmit = async () => {
    try {
      const form = new FormData();
      form.append("insuranceNumber", insuranceData.insuranceNumber);
      form.append("insuranceReported", insuranceData.insuranceReported);
      form.append("approvedBy", userDetails.id);
      form.append("addedByInCompany", data.addedByInCompany);
      form.append("addedByInCompanyEmail", email);
      form.append("addedByInCompanyName", fullName);
      form.append("approvedByName", userDetails.fullName);
      form.append("id", id);
      
      setLoading(true);
      axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/update-accident-notification/insuranceNumber`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        window.location = '/accident-notifications/' + id;
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
    } catch (error) {
      
    }
  }
  
  const handleCommentSubmit = async () => {
    try {
      const form = new FormData();
      form.append("commentBody", commentData.comment);
      form.append("approvedBy", userDetails.id);
      form.append("addedByInCompany", data.addedByInCompany);
      form.append("addedByInCompanyEmail", email);
      form.append("addedByInCompanyName", fullName);
      form.append("approvedByName", userDetails.fullName);
      
      form.append("createdAt", (new Date()).toLocaleString('en-GB') );
      
      form.append("id", id);
      
      setLoading(true);
      axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/accident-notifications/add-comment`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        // window.location = '/accident-notifications/' + id + '?nc=1';
        setcommentPopup(true)
        setcommentData({
          ...commentData,
          comment: "",
        });

        fetchComments();
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
    } catch (error) {
      
    }
  }
  
  const changeInsuranceStatusToFalse = async () => {
    try {
      const form = new FormData();
      /*form.append("insuranceNumber", insuranceData.insuranceNumber);*/
      // form.append("insuranceReported", false );
      form.append("approvedBy", userDetails.id);
      form.append("addedByInCompany", data.addedByInCompany);
      form.append("addedByInCompanyEmail", email);
      form.append("addedByInCompanyName", fullName);
      form.append("approvedByName", userDetails.fullName);
      form.append("id", id);
      
      setLoading(true);
      axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/update-accident-notification/insuranceNumber`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        window.location = '/accident-notifications/' + id;
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
    } catch (error) {
      
    }
  }

  const updateAccidentType = ( updated_value ) => {

    setFieldData({
      ...fieldData,
      accidentTypeAdmin: updated_value,
    })

    const form = new FormData();
    setLoading(false);
    form.append("id", id);
    form.append("accidentType", updated_value);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);

    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/accident-notification/update-accident-type`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const updateLocation = ( updated_value ) => {

    setFieldData({
      ...fieldData,
      accidentLocation: updated_value,
    })

    const form = new FormData();
    setLoading(false);
    form.append("id", id);
    form.append("accidentLocation", updated_value);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);

    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/accident-notification/update-accident-location`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const updateStatus = ( updated_value ) => {

    setFieldData({
      ...fieldData,
      accidentStatus: updated_value,
    })

    const form = new FormData();
    setLoading(false);
    form.append("id", id);
    form.append("accidentStatus", updated_value);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);

    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/accident-notification/update-accident-status`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const handleSubmitAfterSave = (signature) => {
    const form = new FormData();
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    if (fieldData.approved) {
      form.append("approved", fieldData.approved);
    }
    if (fieldData.completed) {
      form.append("completed", fieldData.completed);
    }
    form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post((fieldData.resubmitted || fieldData.completed) ? `${process.env.REACT_APP_API_BASEURL}/resubmit/accident-notifications` : `${process.env.REACT_APP_API_BASEURL}/update/accidentnotification`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/accident-notifications')
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          signature: null,
        });
        handleClearSignature();
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const pdfStyles = StyleSheet.create({
    body: {
      padding: "20px 16px 30px",
      fontSize: "13px",
      fontFamily: "Helvetica",
      color: "#000",
    },
    logos: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },
    halfWidthRightAlign: {
      width: "50%",
      textAlign: "right",
    },
    halfWidthLeftAlign: {
      width: "50%",
      textAlign: "left",
    },
    detailsWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      fontSize: "10px",
      border: "1px solid #ddd",
      borderBottom: "none",
    },
    detailsCell3n1: {
      width: "33.3333333%",
      flexBasis: "33.3333333%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCell5in1: {
      width: "20%",
      flexBasis: "20%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    },
    detailsCell: {
      width: "14.285%",
      flexBasis: "14.285%",
      padding: "5px",
      borderRight: "1px solid #ddd",
      borderBottom: "1px solid #ddd",
    }
  });

  const MyDoc = () => {

    const filesBlocks = [
      {
        name: "Uploaded Files",
        files: data.files,
      }
    ];

    activities.map((activity) => {
      if ( activity.signature ) {
        filesBlocks.push({
          name: activity.action,
          subtitle: activity.comment,
          type: 'signatures',
          files: activity.signature,
        });
      }
    });

    return (
      <PDFRenderer
        name={"Accident Notification #" + id}
        user={userDetails}
        data={data}
      >
        <View>
          <View>
            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              User Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCell3n1}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Injured Party :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.fullName ? data.fullName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell3n1}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Employer Party :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.employer ? data.employer : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell3n1}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Trade : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.trade ? data.trade : "-"}
                  </Text>
                </Text>
              </View>
            </View>

            <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              Accident Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Date & Time :</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.accidentDate
                      ? commonService.formatDate(
                          data.accidentDate,
                          "Do MMM YYYY"
                        ) +
                        (data.accidentTime ? " at " + data.accidentTime : "")
                      : "-"}{" "}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Type of Accident :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.accidentType ? data.accidentType : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Trigger : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.triggeredBy ? data.triggeredBy : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Part of Body :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.bodyPart ? data.bodyPart : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Ambulance Called :{" "}
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.ambulanceCalled ? data.ambulanceCalled : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Details : </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.accidentDetails ? data.accidentDetails : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Tetanus Injection :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.tetanus ? data.tetanus : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Attend Hospital :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.hospitalAdvise ? data.hospitalAdvise : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>
                    Medical Treatment :
                  </Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.medicallyAdvised ? data.medicallyAdvised : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Department :</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.departmentName ? data.departmentName : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Site :</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.siteName ? data.siteName : "-"}
                  </Text>
                </Text>
              </View>
            </View>
          </View>

          <View>
          <Text
              style={{
                fontSize: "18px",
                fontFamily: "Helvetica-Bold",
                textAlign: "left",
                marginBottom: "10px",
                marginTop: "10px"
              }}
            >
              Other Details
            </Text>

            <View style={pdfStyles.detailsWrapper}>
              
              <View style={pdfStyles.detailsCell5in1}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Submitted By :</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {data.submittedBy ? data.submittedBy : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell5in1}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Type of Accident :</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {fieldData.accidentTypeAdmin ? fieldData.accidentTypeAdmin : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell5in1}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Reported to Insurance :</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {insuranceData.insuranceReported && insuranceData.insuranceReported == 1? 'Yes' : "No"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell5in1}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Location :</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {fieldData.accidentLocation ? fieldData.accidentLocation : "-"}
                  </Text>
                </Text>
              </View>

              <View style={pdfStyles.detailsCell5in1}>
                <Text>
                  <Text style={{ fontFamily: "Helvetica" }}>Status :</Text>
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {fieldData.accidentStatus? fieldData.accidentStatus : "-"}
                  </Text>
                </Text>
              </View>

            </View>
          </View>
        </View>

        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}
      </PDFRenderer>
    );
  };

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/accident-notifications/" + deleteId)
        .then(function (response) {
          toast.success("Accident Notification archived successfully");
          window.location = '/accident-notifications';
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Accident Notification"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Accident Notifications List", path: "/accident-notifications" },
          { name: "Accident Notification", path: `/accident-notifications/${id}` },
        ]}
      />
      <CustomPopup
        trigger={!popup}
        setTrigger={() => {
          setPopup(true)
          setFieldData({
            approved: false,
            resubmitted: false,
            completed: false,
            denied: false,
            approvedComment: "",
            deniedComment: "",
            signature: null,
          })
        }}
      >
        {(fieldData.approved || fieldData.resubmitted || fieldData.completed) && <>
          <div className="sign_image">
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 263, height: 270 }}
            />
            <span
              className="cancel-custom cursor-pointer"
              onClick={handleClearSignature}
            >
              <i
                className="clear_btn  text-danger fa fa-times-circle"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        </>
        }
        {!fieldData.resubmitted && !fieldData.completed && <div className="col-12 form_inner p-2">
          <label for="">
            {fieldData.approved ? "Approval Comment" : "Reason for Denial"} <span className="text-danger">*</span>
          </label>
          <div className="input_icons">
            <div className="input_field">
              <textarea
                name="approvedComment"
                rows="5"
                cols="100"
                onChange={(e) => {
                  if (fieldData.approved) {
                    setFieldData({
                      ...fieldData,
                      approvedComment: e.target.value,
                    })
                  } else {
                    setFieldData({
                      ...fieldData,
                      deniedComment: e.target.value,
                    })
                  }
                }}
              ></textarea>
            </div>
          </div>
        </div>}
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            {...(fieldData.approved ? {
              disabled:
                fieldData.approved ?
                  false :
                  !(fieldData.deniedComment)
            } : null)}
            onClick={() => {
              handleSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={!insurancePopup}
        setTrigger={() => {
          setInsurancePopup(true)
          setInsuranceData({
            insuranceReported: false,
          })
        }}
      >
        <div className="col-12 form_inner p-2">
          <label for="">Insurance Number</label>
          <div className="input_icons">
            <div className="input_field">
              <textarea
                name="insuranceNumer"
                rows="5"
                cols="100"
                onChange={(e) => {
                    setInsuranceData({
                      ...insuranceData,
                      insuranceNumber: e.target.value,
                    })
                  }
                }
              ></textarea>
            </div>
          </div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              handleInsuranceSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>

      <CustomPopup
        trigger={!commentPopup}
        setTrigger={() => {
          setcommentPopup(true)
          setcommentData({
            comment: '',
          })
        }}
      >
        <div className="col-12 form_inner p-2">
          <label for="">Comment*</label>
          <div className="input_icons">
            <div className="input_field">
              <textarea
                name="comment"
                rows="5"
                maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                cols="100"
                onChange={(e) => {
                    setcommentData({
                      ...commentData,
                      comment: e.target.value,
                    })
                  }
                }
              ></textarea>
            </div>
          </div>
          <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
        </div>
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              handleCommentSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>

      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">User Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Injured Party : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.fullName ? data.fullName : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Employer : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.employer ? data.employer : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Trade : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.trade ? data.trade : "-"}
                            </div>
                          </div>

                          {/* <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Approved : </div>
                            <div className="col-6 dd-access">
                              {data.isApproved == 1
                                ? "Yes"
                                : data.isApproved == 0
                                  ? "No"
                                  : "Pending"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Completed : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {(data.isCompleted && data.isCompleted == 1) ? "Yes" : "No"}
                            </div>
                          </div> */}
                          
                          {!data.isApproved && <div className="mb-1 d-flex dl-access alert alert-danger">
                            <div className="col-6 dd-access">Reason Denied : </div>
                            <div className="col-6 dd-access">
                              {data.denialComment ? data.denialComment : "-"}{" "}
                            </div>
                          </div>}
                        </div>
                      </div>
                      
                      {false && siteUsers.filter(user => (user.type == "admin") && (user.userid == userDetails.id)).length && (data.isApproved == 2) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            approved: true
                          })
                          setPopup(false)
                        }}>Approve</button>
                        <button className="btn btn-danger ms-2" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            denied: true
                          })
                          setPopup(false)
                        }}>Deny</button>
                      </>
                        : null}
                      
                      { false && (data.isApproved == 0) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            resubmitted: true
                          })
                          setPopup(false)
                        }}>Resubmit</button>
                      </>
                        : null}
                        {(data.isApproved == 0) && (data.isCompleted != 1) ? <>
                          <button className="btn btn-outline-success ms-1" onClick={() => {
                            history.push(`/edit-accident-notification/${data.id}`)
                          }}>Edit</button>
                        </>
                          : null}

                      { false && (data.isApproved == 1) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            completed: true
                          })
                          setPopup(false)
                        }}>Complete</button>
                      </>
                        : null}
                    </div>
                  </div>
                  
                  {userDetails.role == 'admin' ? <>
                    <div className="d-flex flex-column">
                      <div className="mb-3">
                        <div className="header-access-view">Other Details</div>
                        <div className="p-2">
                          <div className="main-details">
                            
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Submitted By : </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.submittedBy ? data.submittedBy : "-"}
                              </div>
                            </div>
                            
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Type of Accident</div>
                              <div className="col-6 dd-access input_icons">
                                <div className="w-100">
                                  <Select
                                    placeholder="Select an option"
                                    options={accidentTypes}
                                    onChange={(e) => {
                                      updateAccidentType( e.value );
                                    }}
                                    {...(id ? {
                                      value: accidentTypes.filter(v => v.value == fieldData.accidentTypeAdmin),
                                    } : {})}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Reported to Insurance</div>
                              <div className="col-6 dd-access">
                                <div className="checkboxToggle reverseColors">
                                  <label>
                                    <input type="checkbox" name="insuranceReported" value="1" checked={insuranceData.insuranceReported == 1} />
                                    <span onClick={() => {
                                        setInsuranceData({
                                          ...insuranceData,
                                          insuranceReported: ( ! insuranceData.insuranceReported )
                                        })

                                        /* If insuranceReported is set to false then don't show popup */
                                        if ( ! insuranceData.insuranceReported ) {
                                          setInsurancePopup(false);
                                        } else {
                                          changeInsuranceStatusToFalse();
                                        }
                                      }}>
                                      <span></span>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            { insuranceData.insuranceReported && <div className="mb-1 d-flex dl-access">
                                <div className="col-6 dd-access">Insurance Number : </div>
                                <div className="col-6 dd-access">
                                  {" "}
                                  {insuranceData.insuranceNumber ? insuranceData.insuranceNumber : "-"}
                                </div>
                              </div>
                            }

                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Location</div>
                              <div className="col-6 dd-access input_icons">
                                <div className="w-100">
                                  <Select
                                    placeholder="Select an option"
                                    options={accidentLocations}
                                    onChange={(e) => {
                                      updateLocation( e.value );
                                    }}
                                    {...(id ? {
                                      value: accidentLocations.filter(v => v.value == fieldData.accidentLocation),
                                    } : {})}
                                  />
                                </div>
                              </div>
                            </div>
                            
                            
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Status</div>
                              <div className="col-6 dd-access input_icons">
                                <div className="w-100">
                                  <Select
                                    placeholder="Select an option"
                                    options={accidentStatuses}
                                    onChange={(e) => {
                                      updateStatus( e.value );
                                    }}
                                    {...(id ? {
                                      value: accidentStatuses.filter(v => v.value == fieldData.accidentStatus),
                                    } : {})}
                                  />
                                </div>
                              </div>
                            </div>

                            {id && <>
                              <button className="btn btn-outline-success ms-1" onClick={() => {
                                history.push(`/edit-accident-notification/${id}`)
                              }}>Edit</button>
                            </>}

                            {id && ( userDetails.role.includes("admin") || userPermissions.includes( 'deleteAccidentNotifications' )) && data.isDeleted != 1 ? <>
                              <button className="btn btn-outline-danger ms-1" onClick={() => handleArchive(id)}>
                                Archive
                              </button>
                            </> : ''}

                          </div>
                        </div>
                      </div>
                    </div>
                  </> : null }

                </div>
                <div className="col-12 col-lg-7 mb-2">
                  <div className="d-flex flex-column">
                    <div class="mb-3">
                    <div className="header-access-view">Accident Details</div>

                      <div class="p-2">
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Date & Time : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.accidentDate
                                  ? commonService.formatDate(
                                    data.accidentDate,
                                    "Do MMM YYYY"
                                  ) + ( data.accidentTime ? ' at ' + data.accidentTime : '' )
                                  : "-"}{" "}
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Type of Accident : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.accidentType ? data.accidentType : "-"}
                          </div>
                        </div>
                        
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Trigger : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.triggeredBy ? data.triggeredBy : "-"}
                          </div>
                        </div>
                        
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Part of Body : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.bodyPart ? data.bodyPart : "-"}
                          </div>
                        </div>
                        
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Ambulance Called : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.ambulanceCalled ? ( data.ambulanceCalled.toLowerCase() == 'yes' ? 'Yes' : 'No' ) : "-"}
                          </div>
                        </div>
                        
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Details : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.accidentDetails ? data.accidentDetails : "-"}
                          </div>
                        </div>
                        
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Tetanus Injection : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.tetanus ? data.tetanus : "-"}
                          </div>
                        </div>
                        
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Attend Hospital : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.hospitalAdvise ? data.hospitalAdvise : "-"}
                          </div>
                        </div>
                        
                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Medical Treatment : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.medicallyAdvised ? data.medicallyAdvised : "-"}
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Department : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.departmentName ? data.departmentName : "-"}
                          </div>
                        </div>

                        <div className="mb-1 d-flex dl-access">
                          <div className="col-6 dd-access">Site : </div>
                          <div className="col-6 dd-access">
                            {" "}
                            {data.siteName ? data.siteName : "-"}
                          </div>
                        </div>
                      </div>
                      

                    </div>

                    {userDetails.role == 'admin' ? <>
                      <div className="mb-3" style={{ border: '1px solid #e2e6ea', borderRadius: '8px', overflow: "auto", maxHeight: "670px" }}>
                        <div className="header-access-view position-sticky" style={{ top: 0 }}>Comments</div>
                        <div className="p-2">
                          
                        {comments.map(comment => (<div className="m-2">
                          <div className="p-2 signature-div-inner">
                            <div className="d-flex justify-content-between mb-2">
                              <span className="rst-title me-2">
                                {comment.submittedByName} :{" "}
                              </span>
                              <span className="rst-title me-2 text-secondary">
                                {comment.createdAt}
                              </span>
                            </div>
                            {comment.commentBody ?
                              <p>{comment.commentBody}</p>
                              : null}
                            </div>
                          </div>))}
                        </div>
                      </div>

                      <div style={{ marginBottom: '20px' }}>
                        <div className="add-comment-button">
                          <button
                            className="btn btn-danger w-100"
                            onClick={() => {
                              setcommentPopup(false);
                            }}
                          >Add New Comment</button>
                        </div>
                      </div>
                    </> : null }

                    <div className="mb-3" style={{ border: '1px solid #e2e6ea', borderRadius: '8px', overflow: "auto", maxHeight: "670px" }}>
                      <div className="header-access-view position-sticky" style={{ top: 0 }}>Activity</div>
                      {activities.map(activity => (<div className="m-2">
                        <div className="p-2 signature-div-inner">
                          <div className="d-flex justify-content-between mb-2">
                            <span className="rst-title me-2">
                              {activity.action} :{" "}
                            </span>
                            <span className="rst-title me-2 text-secondary">
                              {activity.datetime}
                            </span>
                          </div>
                          {activity.comment ?
                            <p>{activity.comment}</p>
                            : null}
                          {activity.signature ? <img
                            onError={(e) => {
                              e.target.src = DefaultImage;
                            }}
                            className="obj-fit-cover"
                            height="250"
                            width="250"
                            src={
                              activity.signature
                                ? process.env.REACT_APP_API_BASEURL +
                                activity.signature
                                : DefaultImage
                            }
                          ></img> : null}
                        </div>
                      </div>))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="header-access-view"> Uploaded Files</div>
                  <div className="d-flex">
                    {data.files && data.files.split(",").map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                      {commonService.outputImageTile(imgPreviewUrl)}
                    </div>))}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <PDFDownloadLink
                    document={<MyDoc />}
                    className="mt-2 btn btn-primary pdf-download-btn"
                    fileName={"accident-notitification-" + id + ".pdf"}
                  >
                    {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download PDF"
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
