import React from "react";
import "./contractor.css";
import Header from "../Header/Header";
import { useState } from "react";
import Select from "react-select";
import Cookies from "universal-cookie";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import axiosInstance from "../../helpers/request";
import Delete from "../../images/circle-cross.svg";
import { useAppState } from "../../state";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Autocomplete, TextField } from "@mui/material";
import { monthlyPaymentArray } from "../../helpers/paymentArray";
import statisticsService from "../../services/statistics.service";
import { onError } from "../../libs/errorLib";

function ViewContractor({ title }) {
  const { user, userPermissions } = useAppState();
  const cookies = new Cookies();
  const userToken = localStorage.getItem("cs_token");
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const isEdit = location.pathname.includes("view-contractor");
  const [data, setData] = useState({
    name: "",
    description: "",
    sites: "",
  });
  const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([]);
  const [selectedNormalUser, setSelectedNormalUser] = useState("");
  const [selectedSite, setSelectedSite] = useState("");

  const [users, setUsers] = useState([]);
  const [contractorUsers, setContractorUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [uniqueRoles, setUniqueRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companyId, setUserID] = useState("");
  const [pageNumber, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageNumber1, setPage1] = useState(1);
  const [pageSize1, setPageSize1] = useState(10);
  const [total1, setTotal1] = useState(0);
  const [sites, setSites] = useState([]);
  const [stats, setStats] = useState(null);
  const [newUser, setNewUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: [],
    added_by: "",
    addedByInCompany: "",
    companyName: "",
    contractor: id,
    subscription_id: "",
    trial: "",
  });
  useEffect(() => {
    userDetail();
    if (isEdit && id) {
      getContractor();
      getContractorUsers();
      getContractorStats();
    }
  }, []);

  useEffect(() => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-roles`,
        {
          companyId: companyId,
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length > 0) {
          setRoles(res.data);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
    getUsers();
    getSites();
  }, [companyId]);

  useEffect(() => {
    if (users.length && contractorUsers.length) {
      let filteredCompanyUsers = users.filter(
        (cu) =>
          !contractorUsers
            .filter((v) => v.role !== "admin")
            .filter((du) => cu.id == du.id).length
      );
      console.log("filteredCompanyUsers", filteredCompanyUsers);
      setSelectedCompanyUsers(filteredCompanyUsers);
    } else if (users.length) {
      console.log("users-all", users);
      setSelectedCompanyUsers(users);
    }
  }, [users, contractorUsers]);

  useEffect(() => {
    if(data && data.sites) {
      setTotal1(data.sites?.split(",").length);
    }
  }, [data]);

  const getContractorStats = async() => {
    try {
      const response = await statisticsService.getStatistics({contractorId: id});
      if (response) {
        setStats(response);
      }
    } catch (e) {
      onError(e);
    }
  }

  const getUsers = () => {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-users`,
        {
          companyId: companyId,
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length > 0) {
          const formattedUsers = res.data.map((user) => {
            return {
              label: `${user.fullName} - ${
                user.contractorName ? user.contractorName : user.companyName
              } - ${
                user.roleName
                  ? user.roleName
                  : user.role == "admin" || user.role == "superadmin"
                  ? user.role
                  : user.role
              }`,
              id: user.id,
            };
          });
          setUsers(formattedUsers);
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  function getSites() {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/all-sites`,
        {
          companyId: companyId,
          status: "",
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data.length > 0) {
          setTotal1(res.data.length);
          setSites(
            res.data.filter(
              (v, i) => res.data.findIndex((v1) => v1.id == v.id) == i
            )
          );
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  function userDetail() {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setUserID(
            res.data[0].added_by ? res.data[0].added_by : res.data[0].id
          );
          if (res.data[0].role == "admin") {
            setNewUser({
              ...newUser,
              // chosenServices: res.data[0].chosenServices,
              companyName: res.data[0].companyName,
              subscription_id: res.data[0].subscription_id,
              trial: res.data[0].trial,
              added_by:
                res.data[0].role !== "admin" && res.data[0].added_by
                  ? res.data[0].added_by
                  : res.data[0].id,
              addedByInCompany: res.data[0].id,
            });
          } else {
            const item = monthlyPaymentArray[0];
            let expireDate;
            const currentDate = new Date();
            if (item.validity === "yearly") {
              expireDate = new Date(
                currentDate.setFullYear(currentDate.getFullYear() + 1)
              );
            } else {
              expireDate = new Date(
                currentDate.setMonth(currentDate.getMonth() + 1)
              );
            }
            const expiryTimestamp = Math.floor(expireDate.getTime() / 1000);
            setNewUser({
              ...newUser,
              added_by: res.data[0].id,
              trial: `${expiryTimestamp}-${item.priceId}`,
              addedByInCompany: res.data[0].id,
            });
          }
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  function getContractor() {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/contractor/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data && res.data.length) {
          setData({ ...res.data[0] });
          // if (res.data.map(v => v.userid).filter(v => v).length) {
          //     getContractorUsers(res.data.map(v => v.userid).filter(v => v))
          // }
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  function getContractorUsers() {
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/get-contract-users`,
        { contractor: id },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        if (res.data && res.data.length) {
          setContractorUsers(res.data);
          setTotal(res.data.length);
          setUniqueRoles(
            res.data
              .map((v) => v.role.split(","))
              .flat()
              .filter((u, i, arr) => i == arr.findIndex((x) => x === u))
          );
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const submitDepartmentUser = (newUser) => {
    setLoading(true);
    axiosInstance
      .post(`/add-contractor-user`, { userId: newUser.id, contractorId: id }, {
        headers: {
            Authorization: `Bearer ${userToken}`,
        },
      })
      .then(async (response) => {
        setLoading(false);
        if (response.status === 200) {
          getContractorUsers();
          setSelectedNormalUser("");
          toast.success("Successfully Updated contractor");
        } else {
          throw new Error(response?.error);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Failed to Update contractor");
      });
  };

  const submitSite = (site) => {
    setLoading(true);
    const form = new FormData();
    form.append("name", data.name);
    form.append("description", data.description);
    form.append(
      "sites",
      data.sites ? data.sites.split(",").concat([site.id]).join(",") : site.id
    );
    axiosInstance
      .put(`/contractor/${id}`, form)
      .then(async (response) => {
        if (response.status === 200) {
          // setData(response.data)
          getContractor();
          setSelectedSite("");
          toast.success("Successfully Updated contractor");
        } else {
          throw new Error(response?.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast.error("Failed to Update contractor");
      });
  };

  const handleDelete = async (userId, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to remove ${name} from this contractor`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/remove-contractor/", {
          userId,
        }, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(function (response) {
          getContractorUsers();
          toast.success("User deleted successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message || "Unable to delete user"
          );
        });
    }
  };

  const handleDeleteSite = async (siteId, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to remove ${name} from this contractor`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      setLoading(true);
      const form = new FormData();
      form.append("name", data.name);
      form.append("description", data.description);
      form.append(
        "sites",
        data.sites
          .split(",")
          .filter((s) => s != siteId)
          .join(",")
      );
      axiosInstance
        .put(`/contractor/${id}`, form)
        .then(async (response) => {
          if (response.status === 200) {
            // setData(response.data)
            getContractor();
            toast.success("Deleted site from contractor Successfully");
          } else {
            throw new Error(response?.error);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          toast.error("Failed to Delete site from contractor");
        });
    }
  };

  const handleArchive = async () => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this contractor`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      setLoading(true);
      axiosInstance
        .post("/archive-contractor", { id }, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(function (response) {
          setLoading(false);
          toast.success("Contractor archived successfully");
        })
        .catch(function (error) {
          setLoading(false);
          toast.error(
            error?.response?.data?.message || "Unable to archive Contractor"
          );
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewUser({
      ...newUser,
      [name]: value,
    });
  };

  const isValidData = () => {
    const { firstName, lastName, email, role } = newUser;
    const EMAIL_REGEX = new RegExp(/\S+@\S+\.\S+/);
    let isValid = true;
    let message = "";
    console.log("email", email, EMAIL_REGEX.test(email));
    if (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      role.length == 0
    ) {
      isValid = false;
      message = "All fields are mandatory";
    } else if (!EMAIL_REGEX.test(email)) {
      isValid = false;
      message = "Please enter valid email address";
    }
    // else if (password !== cpassword) {
    //   isValid = false;
    //   message = "Password and Confirm Password not matched";
    // }

    return { isValid, message };
  };

  const handleSubmit = () => {
    const { isValid, message } = isValidData();
    if (isValid) {
      setLoading(true);
      axiosInstance
        .post("/user", {
          data: { ...newUser, role: newUser.role.join(","), companyId: ( user.role == 'admin' ? user.id : companyId) },
          actionBy: user.firstName + " " + user.lastName,
          companyId: ( user.role == 'admin' ? user.id : companyId) 
        })
        .then(function (response) {
          setLoading(false);
          getContractorUsers();
          setNewUser({
            ...newUser,
            firstName: "",
            lastName: "",
            email: "",
            role: [],
          });
          toast.success("User added successfully");
        })
        .catch(function (error) {
          setLoading(false);
          // already exists in our records
          console.log(
            error,
            error?.response?.data?.message.email,
            error?.response?.data?.message.companyName
          );
          if (
            error?.response?.data?.message.email !== "" &&
            error?.response?.data?.message.companyName !== ""
          ) {
            if (
              error?.response?.data?.message.email === user.email &&
              error?.response?.data?.message.companyName === user.companyName
            ) {
              toast.error(
                ` Email and CompanyName already exists in our records` ||
                  "Unable to process this request"
              );
            } else if (error?.response?.data?.message.email === user.email) {
              toast.error(
                ` Email already exists in our records` ||
                  "Unable to process this request"
              );
            } else {
              console.log(
                error,
                error?.response?.data?.message.email,
                error?.response?.data?.message.companyName
              );
              toast.error(
                (typeof error?.response?.data?.message == "string" ??
                  error?.response?.data?.message) ||
                  "Unable to process this request"
              );
            }
          } else {
            toast.error("Unable to process this request");
          }
        });
    } else {
      toast.error(message);
    }
  };

  return (
    <div class="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Contractors List", path: "/contractors" },
          { name: "View Contractor" },
        ]}
      />
      <div class="main_container">
        <div
          className="d-flex w-100 view_user_content justify-content-between flex-wrap"
          id="grid-view"
        >
          <div>
            <div class="accident mt-0">
              <div class="accident_text">
                <h5>Details</h5>
              </div>
            </div>
            <table class="table table-striped">
              <tbody>
                <tr>
                  <th scope="row" style={{ width: "30%" }}>
                    Name:
                  </th>
                  <td>{data?.name}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "30%" }}>
                    Description:
                  </th>
                  <td>{data?.description}</td>
                </tr>
                <tr>
                  <th scope="row" style={{ width: "30%" }}>
                    Email Address:
                  </th>
                  <td>{data?.email}</td>
                </tr>
              </tbody>
            </table>
            <div className="w-100 d-flex justify-content-between px-2">
              {data.isDeleted == 0 && <>
                <button
                  className="view_site_button text-white submit"
                  onClick={() => {
                    history.push("/edit-contractor/" + id);
                  }}
                >
                  Edit
                </button>
              </>}
              {false && (user.role == "admin" || user.role == "superadmin" || userPermissions.includes( 'archivecontractor' ) ) &&
                data.archived != "1" && (
                  <button
                    className="view_site_button text-dark submit"
                    style={{ background: "#e1eefa" }}
                    onClick={handleArchive}
                  >
                    Archive
                  </button>
                )}
            </div>
            <div class="signature">
              <div class="sign_btns">
                <div></div>
              </div>
            </div>
          </div>
          <div>
            <div class="accident mt-0">
              <div class="accident_text">
                <h5>Statistics</h5>
              </div>
            </div>
            <table class="table table-striped d-flex">
              <tbody className="statistic-table">
                <tr>
                  <th scope="row" width="75%">
                    Users:
                  </th>
                  <td width="5%">{contractorUsers.length}</td>
                </tr>
                <tr>
                  <th scope="row" width="75%">
                    Accident Notifications:
                  </th>
                  <td width="5%">{stats?.accidentNotifications? stats.accidentNotifications : 0}</td>
                </tr>
                <tr>
                  <th scope="row" width="75%">
                    NCR CAR Reports:
                  </th>
                  <td width="5%">0</td>
                </tr>
                <tr>
                  <th scope="row" width="75%">
                    Safety Desciplinary Logs:
                  </th>
                  <td width="5%">{stats?.safetyDisciplinaryLogs ? stats.safetyDisciplinaryLogs : 0}</td>
                </tr>
              </tbody>
              <tbody className="statistic-table">
                <tr>
                  <th scope="row" width="75%">
                    Accident Reports:
                  </th>
                  <td width="5%">{stats?.accidentreports ? stats.accidentreports : 0}</td>
                </tr>
                <tr>
                  <th scope="row" width="75%">
                    PPE Issue:
                  </th>
                  <td width="5%">{stats?.ppeIssueReports ? stats.ppeIssueReports : 0}</td>
                </tr>
                <tr>
                  <th scope="row" width="75%">
                    Safety Observations:
                  </th>
                  <td width="5%">{stats?.safetyobservations ? stats.safetyobservations : 0}</td>
                </tr>
                <tr>
                  <th scope="row" width="75%">
                    Safety Checklists:
                  </th>
                  <td width="5%">{stats?.safetychecklists ? stats.safetychecklists : 0}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {data.isDeleted == 0 && <>
            <div>
              <div class="accident mt-0">
                <div class="accident_text">
                  <h5>Users</h5>
                </div>
              </div>
              <div class="form-1">
                <div class="form justify-content-start">
                  {
                    <div className="form_inner p-2">
                      <>
                        <div id="wrapper" style={{ maxHeight: "600px" }}>
                          <table class="table table-sm">
                            <thead>
                              <tr>
                                <th scope="col" width="5%">
                                  View{" "}
                                  <img
                                    src={require("../../images/up-down-arrow.png")}
                                    alt=""
                                  />
                                </th>
                                <th scope="col" width="8%">
                                  Full Name{" "}
                                  <img
                                    src={require("../../images/up-down-arrow.png")}
                                    alt=""
                                  />
                                </th>
                                <th scope="col" width="8%">
                                  Email{" "}
                                  <img
                                    src={require("../../images/up-down-arrow.png")}
                                    alt=""
                                  />
                                </th>
                                <th scope="col" width="8%">
                                  Role{" "}
                                  <img
                                    src={require("../../images/up-down-arrow.png")}
                                    alt=""
                                  />
                                </th>
                                <th scope="col" width="2%">
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {contractorUsers
                                .filter(
                                  (v, i) =>
                                    (pageNumber - 1) * pageSize <= i &&
                                    pageNumber * pageSize > i
                                )
                                .map((d) => (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="cursor-pointer"
                                      onClick={() => {
                                        history.push("/view-user/" + d.id);
                                      }}
                                    >
                                      {d.id}
                                    </th>
                                    <td>{d.fullName}</td>
                                    <td>{d.email}</td>
                                    <td>
                                      {d.role
                                        ?.split(",")
                                        .map((v) =>
                                          roles.filter((r) => r.id == v).length
                                            ? roles.filter((r) => r.id == v)[0]
                                                .name
                                            : v
                                        )}
                                    </td>
                                    <td>
                                      <img
                                        src={Delete}
                                        alt=""
                                        onClick={() => {
                                          handleDelete(d.id, d.fullName);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="pagination-content mt-3">
                          <div className="page-entries">
                            <nav aria-label="...">
                              <ul className="pagination">
                                <li className="page-item">
                                  <a className="page-link">Show </a>
                                </li>
                                <li className="page-item">
                                  <select
                                    className="page-link"
                                    style={{
                                      borderRadius: 0,
                                      padding: "6px 12px",
                                    }}
                                    value={pageSize}
                                    onChange={(e) => {
                                      setPageSize(e.target.value);
                                    }}
                                  >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                  </select>
                                </li>
                                <li className="page-item">
                                  <a className="page-link" href="#">
                                    Entries{" "}
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            <p>
                              Showing {(pageNumber - 1) * pageSize + 1} to{" "}
                              {pageNumber * pageSize} from {total} Entries
                            </p>
                          </div>
                          <nav aria-label="...">
                            <ul className="pagination">
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (pageNumber > 1) {
                                      setPage((p) => p - 1);
                                    }
                                  }}
                                >
                                  Previous
                                </a>
                              </li>
                              {Array.from({
                                length: Math.ceil(total / pageSize),
                              }).map((v, i) => (
                                <li
                                  onClick={() => setPage(i + 1)}
                                  className={`page-item ${
                                    pageNumber == i + 1 ? "active" : ""
                                  }`}
                                  aria-current="page"
                                >
                                  <a className="page-link" href="#">
                                    {i + 1}
                                  </a>
                                </li>
                              ))}
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (total / pageSize > pageNumber) {
                                      setPage((p) => p + 1);
                                    }
                                  }}
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </>
                    </div>
                  }
                </div>
              </div>
              <div className="viewBoxWrapper">
                <label>Add User</label>
                <Autocomplete
                  value={selectedNormalUser}
                  onChange={(event, newValue) => {
                    console.log("NEW-Val", newValue);
                    setSelectedNormalUser(newValue);
                  }}
                  name="siteUser"
                  disablePortal
                  id="combo-box-demo"
                  options={selectedCompanyUsers}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select User" />
                  )}
                />
                <button
                  disabled={!selectedNormalUser}
                  className="view_site_button text-white submit"
                  onClick={
                    !selectedNormalUser
                      ? () => {}
                      : () => {
                          submitDepartmentUser(selectedNormalUser);
                        }
                  }
                >
                  Add
                </button>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#addnewuser"
                  class="ms-2 view_site_button text-white submit"
                >
                  Add New User
                </button>
              </div>
            </div>
            <div>
              <div class="accident mt-0">
                <div class="accident_text">
                  <h5>Sites</h5>
                </div>
              </div>
              <div class="form-1">
                <div class="form justify-content-start">
                  {
                    <div className="form_inner p-2">
                      <>
                        {/* <label for="">Users</label> */}
                        <div id="wrapper" style={{ maxHeight: "600px" }}>
                          <table class="table table-sm">
                            <thead>
                              <tr>
                                <th scope="col" width="5%">
                                  View{" "}
                                  <img
                                    src={require("../../images/up-down-arrow.png")}
                                    alt=""
                                  />
                                </th>
                                <th scope="col" width="8%">
                                  Name{" "}
                                  <img
                                    src={require("../../images/up-down-arrow.png")}
                                    alt=""
                                  />
                                </th>
                                <th scope="col" width="8%">
                                  Description{" "}
                                  <img
                                    src={require("../../images/up-down-arrow.png")}
                                    alt=""
                                  />
                                </th>
                                {(user.role.includes("admin") ||
                                  userPermissions.includes("editsites")) && (
                                  <th scope="col" width="2%">
                                    Action
                                  </th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {sites
                                .filter((s) => data.sites?.includes(s.id))
                                .filter(
                                  (v, i) =>
                                    (pageNumber1 - 1) * pageSize1 <= i &&
                                    pageNumber1 * pageSize1 > i
                                )
                                .map((d) => (
                                  <tr>
                                    <th
                                      scope="row"
                                      className="cursor-pointer"
                                      style={{ color: "#005CB9" }}
                                      onClick={() => {
                                        history.push("/view-site/" + d.id);
                                      }}
                                    >
                                      {d.id}
                                    </th>
                                    <td>{d.name}</td>
                                    <td>{d.description}</td>
                                    {(user.role.includes("admin") ||
                                      userPermissions.includes("editsites")) && (
                                      <td>
                                        {user.role.includes("admin") && (
                                          <img
                                            src={Delete}
                                            alt=""
                                            onClick={() => {
                                              handleDeleteSite(d.id, d.name);
                                            }}
                                          />
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <div className="pagination-content mt-3">
                          <div className="page-entries">
                            <nav aria-label="...">
                              <ul className="pagination">
                                <li className="page-item">
                                  <a className="page-link">Show </a>
                                </li>
                                <li className="page-item">
                                  {/* <a className="page-link" href="#">1 &nbsp; <img src="../../images/down-arrow.png" alt=""/></a> */}
                                  <select
                                    className="page-link"
                                    style={{
                                      borderRadius: 0,
                                      padding: "6px 12px",
                                    }}
                                    value={pageSize1}
                                    onChange={(e) => {
                                      setPageSize1(e.target.value);
                                    }}
                                  >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                  </select>
                                </li>
                                <li className="page-item">
                                  <a className="page-link" href="#">
                                    Entries{" "}
                                  </a>
                                </li>
                              </ul>
                            </nav>
                            <p>
                              Showing {(pageNumber1 - 1) * pageSize1 + 1} to{" "}
                              {pageNumber1 * pageSize1} from {total1} Entries
                            </p>
                          </div>
                          <nav aria-label="...">
                            <ul className="pagination">
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (pageNumber1 > 1) {
                                      setPage1((p) => p - 1);
                                    }
                                  }}
                                >
                                  Previous
                                </a>
                              </li>
                              {Array.from({
                                length: Math.ceil(total1 / pageSize1),
                              }).map((v, i) => (
                                <li
                                  onClick={() => setPage1(i + 1)}
                                  className={`page-item ${
                                    pageNumber1 == i + 1 ? "active" : ""
                                  }`}
                                  aria-current="page"
                                >
                                  <a className="page-link" href="#">
                                    {i + 1}
                                  </a>
                                </li>
                              ))}
                              <li className="page-item">
                                <a
                                  className="page-link"
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (total1 / pageSize1 > pageNumber1) {
                                      setPage1((p) => p + 1);
                                    }
                                  }}
                                >
                                  Next
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </>
                    </div>
                  }
                </div>
              </div>
              <div className="viewBoxWrapper">
                <label>Add Site</label>
                <Autocomplete
                  value={selectedSite}
                  onChange={(event, newValue) => {
                    console.log("NEW-Val", newValue);
                    setSelectedSite(newValue);
                  }}
                  name="siteUser"
                  disablePortal
                  id="combo-box-demo"
                  options={sites
                    .filter((s) => !data.sites?.includes(s.id))
                    .map((s) => ({ id: s.id, label: s.name }))}
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Site" />
                  )}
                />
                <button
                  disabled={!selectedSite}
                  className="view_site_button text-white submit"
                  onClick={
                    !selectedSite
                      ? () => {}
                      : () => {
                          submitSite(selectedSite);
                        }
                  }
                >
                  Add
                </button>
              </div>
            </div>
          </>}
        </div>
        <div className="d-flex w-100 view_user_content justify-content-between"></div>
      </div>
      <div
        class="modal fade"
        id="addnewuser"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add New User
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div className="main_container p-0">
                <form>
                  <div class="form-1">
                    <div class="form justify-content-start">
                      <div class="col-6 form_inner p-2">
                        <label for="">
                          First Name <span className="text-danger">*</span>{" "}
                        </label>
                        <div class="input_icons">
                          <div class="input_field">
                            <input
                              type="text"
                              value={newUser.firstName}
                              name="firstName"
                              onChange={handleChange}
                              placeholder="First Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-6 form_inner p-2">
                        <label for="">
                          Last Name <span className="text-danger">*</span>{" "}
                        </label>
                        <div class="input_icons">
                          <div class="input_field">
                            <input
                              type="text"
                              value={newUser.lastName}
                              name="lastName"
                              onChange={handleChange}
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-6 form_inner p-2">
                        <label for="">
                          Email Address <span className="text-danger">*</span>{" "}
                        </label>
                        <div class="input_icons">
                          <div class="input_field">
                            <input
                              type="text"
                              value={newUser.email}
                              name="email"
                              onChange={handleChange}
                              placeholder="Email Address"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-6 form_inner p-2">
                        <label for="">
                          Role <span className="text-danger">*</span>{" "}
                        </label>
                        <div class="input_icons">
                          <div class="w-100">
                            <Select
                              isMulti
                              options={roles.map((d) => ({
                                label: d.name,
                                value: d.id,
                              }))}
                              value={
                                data.role == "admin"
                                  ? [{ label: "Admin", value: "admin" }]
                                  : roles
                                      .filter((v) =>
                                        newUser.role.includes(v.id)
                                      )
                                      .map((v) => ({
                                        label: v.name,
                                        value: v.id,
                                      }))
                              }
                              placeholder="Select Roles"
                              onChange={(v) => {
                                setNewUser({
                                  ...newUser,
                                  role:
                                    v && v.length ? v.map((v) => v.value) : [],
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                disabled={loading}
                onClick={handleSubmit}
              >
                Add User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewContractor;
