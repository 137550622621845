import React from 'react'
import "./contractor.css"
import Header from '../Header/Header'
import { useAppState } from '../../state'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import axiosInstance from '../../helpers/request'
import { useEffect } from 'react'
import Edit2 from '../../images/edit-2.svg';
import Restore from "../../images/undo.svg";
import Delete from '../../images/archive-icon.svg';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import Select from "react-select"
import { MODULE_STATUS } from '../common/constant'
import { useLocation } from 'react-router-dom'
import { commonService } from "../common/common.service";

import Table from "../common/Table";
import DataTable from 'react-data-table-component';
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import CustomPopup from "../Popup/CustomPopup";


function CommonContractorList({ title, isDeleted }) {
    const { user, userPermissions, selectedSite, selectedCompany } = useAppState()
    const cookies = new Cookies();
    const history = useHistory()
    const userToken = localStorage.getItem("cs_token");
    const location = useLocation()
    const isOwn = location.pathname.includes("my-")
    const isSites = location.pathname.includes("sites-") || (user.role.includes('admin') ? false : localStorage.getItem(`${user.id}-currentSite`))
    const isArchived = location.pathname.includes("archived")
    const [data, setData] = useState([])
    const [companyId, setUserID] = useState("")
    const [myId, setMyId] = useState("")
    const [search, setSearch] = useState("");
	const [pdfLoader, setPdfLoader] = useState(true);

	const [sortColumn, setSortColumn] = useState(null);
	const [sortDirection, setSortDirection] = useState('asc');

    const [status, setStatus] = useState({
        label: "Select Status",
        value: ""
    })

    console.log('data', data)
    useEffect(() => {
        getContractors()
    }, [companyId, status, selectedSite]);

	useEffect(() => {
		setUserID(selectedCompany);
	}, [selectedCompany]);

    useEffect(() => {
        userDetail()
    }, [])

    const getContractors = () => {
        if (!isSites) {
            axiosInstance
                .post(`${process.env.REACT_APP_API_BASEURL}/${((user.role == "superadmin") && !isOwn) ? "every-contractors" : "all-contractors"}`, {
                    companyId: companyId,
                    status: status.value,
                    isOwn,
                    isSites,
                    id: myId,
                    isDeleted: isDeleted,
                }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
                .then((res) => {
                    // if (res.data.length > 0) {
                    setData(res.data.filter((contractor, index, arr) => res.data.findIndex(item => item.id == contractor.id) == index).filter(d => isArchived ? d.archived == "1" : true));
                    // }

					const savedSort = commonService.loadSortData('Contractors');
					if (savedSort) {
						const { direction, columnSelector } = savedSort;
						setSortDirection(direction);
						setSortColumn(columnSelector);
					}

                })
                .catch((err) => {
                    console.log("errr", err);
                });
        } else {
            if (isSites && localStorage.getItem(`${user.id}-currentSite`)) {
                axiosInstance
                    .post(`${process.env.REACT_APP_API_BASEURL}/site-contractors/${localStorage.getItem(`${user.id}-currentSite`)}`, {
                        status: status.value,
                        isOwn,
                        id: myId
                    }, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    })
                    .then((res) => {
                        if (res.data && res.data.length) {
                            setData(res.data.filter((contractor, index, arr) => res.data.findIndex(item => item.id == contractor.id) == index));
                        }
                    })
                    .catch((err) => {
                        console.log("errr", err);
                    });
            } else {
                setData([]);
            }
        }
    }

    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
					if ( res.data[0].role == 'superadmin' ) {
						setUserID( selectedCompany );
						setMyId(selectedCompany);
					} else {
						setUserID(((res.data[0].role !== "admin") && res.data[0].added_by) ? res.data[0].added_by : res.data[0].id);
						setMyId(res.data[0].id);
					}
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }


    const handleDelete = async (id, name) => {
        const result = await Swal.fire({
            title: `Are you sure you want to archive ${name}`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, archive it!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .delete(process.env.REACT_APP_API_BASEURL + "/contractor/" + id)
                .then(function (response) {
                    getContractors()
                    toast.success("Contractor archived successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to archive Contractor");
                });
        }
    };

    const handleUndelete = async (id, name) => {
        const result = await Swal.fire({
          title: `Are you sure you want to restore ${name}`,
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, restore it!",
        });

        if (result.isConfirmed) {
          axiosInstance
            .post(process.env.REACT_APP_API_BASEURL + "/undelete-contractor/" + id)
            .then(function (response) {
              getContractors();
              toast.success("Contractor restored successfully");
            })
            .catch(function (error) {
              toast.error(
                error?.response?.data?.message || "Unable to restore contractor"
              );
            });
        }
      };



    const handleChangeStatus = (module_id, value) => {
        axiosInstance
            .post(process.env.REACT_APP_API_BASEURL + "/update-contractor-status", {
                id: module_id,
                status: value
			}, {
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			})
            .then(function (response) {
                toast.success(response?.data?.message || "Contractor status has been updated successfully");
            })
            .catch(function (error) {
                console.log('error', error)
                toast.error(error?.response?.data?.message || "Unable to update Contractor status");
            });
        let newData = []
        data.forEach((itm) => {
            if (itm.id == module_id) {
                newData = [
                    ...newData,
                    {
                        ...itm,
                        is_active: value
                    }
                ];
            } else {
                newData = [...newData, itm]
            }
        })
        setData(newData)


    }

    const tableHeader = [
		'ID', 'Site', 'Description'
	  ];

	  function getActionsConvertableArray() {
		let array = [];

		// add all items from data but skip actions
		data.forEach((d) => {
		  array.push({
			id: d.id,
			name: d.name ? d.name : '',
			description: d.description ? d.description : '',
			//status: d.is_active ? 'Active' : 'Inactive',
		  })
		})

		return array;
	  }

	  const columns = [
		{
		  name: 'View',
		  selector: 'view',
		  width: '100px',
		  cell: row => <div>{row.view}</div>,
		  sortFunction: (a, b) => {
			return a.id - b.id;
		  }
		},
		{
		  name: 'Site',
		  selector: 'name',
		  searchField: 'name',
		  cell: row => <div>{row.name}</div>,
		},
		{
		  name: 'Description',
		  selector: 'description',
		  searchField: 'description',
		  cell: row => <div>{row.description}</div>,
		},
	  ];

	  // add minWidth to each item in columns
	  columns.forEach((column) => {
		if ( typeof column.minWidth == 'undefined' && typeof column.width == 'undefined' ) {
		  column.minWidth = '200px';
		}

		// Add sorting to all columns
		if ( typeof column.sortable === 'undefined' ) {
		  column.sortable = true;
		}

		if ( typeof column.sortFunction === 'undefined' && typeof column.searchField !== 'undefined' ) {
		  column.sortFunction = (a, b) => a[column.searchField].localeCompare( b[column.searchField] );
		}
	  });

	  if ( false && user.role.includes("admin") ) {

		columns.push( {
			name: 'Status',
			selector: 'status',
			searchField: 'status',
			cell: row => <div>
				<div className="form-check form-switch">
					<input
						className="sub-permission-check form-check-input form-check-input-color d-block"
						style={{ width: "45px", height: "22px" }}
						type="checkbox"
						role={`switch-${row.id}`}
						id={`flexSwitchCheckChecked-${row.id}`}
						checked={row.status ? true : false}
						onChange={(e) => {
							handleChangeStatus(row.id, e.target.checked ? 1 : 0)
						}} />
				</div>
			</div>,
		} );
    }

    if ( user.role.includes("admin") || userPermissions.includes('editcontractor') || userPermissions.includes( 'archivecontractor' ) ) {
		columns.push({
		  name: 'Action',
		  sortable: false,
		  selector: 'actions',
		  cell: row => <div>{row.actions}</div>,
		  allowOverflow: true
		});
	  }

	  const downloadExcel = () => {
		commonService.downloadArrayOfObjectsAsXLSX(
		  getActionsConvertableArray(),
		  tableHeader,
		  title.toLowerCase().replace(' ', '-').split('(')[0] + '.xlsx'
		);

	  }

	  const printList = () => {
		// Trigger the actual print
		commonService.triggerPrint(
		  getActionsConvertableArray(),
		  tableHeader,
		  title
		);
	  }

	  const PDFReadyTable = () => {
		// Implement download PDF
		const pdfTableData = {
		  column: tableHeader,
		  data: getActionsConvertableArray()
		};

		return (
		  <Document>
			<Page
			  orientation="landscape"
			  size="LETTER"
			  style={{padding: "20px 16px 30px"}}
			>
			  <Table
				data={pdfTableData}
				heading={title}
			  />
			</Page>
		  </Document>
		);
	  }

	  const downloadPDF = async () => {
		// If we have larger data set, show laoder.
		if ( data.length > 100 ) {
		  setPdfLoader(false);
		}
		const blob = await pdf((
		  <PDFReadyTable />
		  )).toBlob();
		  saveAs(blob, title.toLowerCase().replace(/ /g, '-').split('(')[0] + ".pdf");
		  setPdfLoader(true);
	  };

	  const CustomSortIcon = () => (
		<span className="dt-sort-icon"></span>
	  )

	  const createData = () => {
		let parsedData = [];

		if ( data.length == 0 ) {
		  return parsedData;
		}

		data.forEach((d) => {
		  let row = {
			id: d.id,
			view: <span onClick={() => { history.push(`/view-contractor/${d.id}`); }} className="lists-id-col">{d.id}</span>,
			name: d.name ? d.name : '',
			description: d.description ? d.description : '',
			status: d.is_active ? true : false,
		  };

		  if ( user.role.includes("admin") || userPermissions.includes('editcontractor') || userPermissions.includes( 'archivecontractor' ) ) {
			row.actions = <>
        { ! isDeleted ? <>
          {(user.role.includes("admin") || userPermissions.includes( 'archivecontractor' )) &&
  					<img src={Delete} alt="" onClick={() => {
  						handleDelete(d.id, d.name)
  					}} />}
  				{userPermissions.includes('editcontractor') && <img className="ps-2" src={Edit2} alt="" onClick={() => {
  					history.push("/edit-contractor/" + d.id)
  				}} />}
        </> : <>
          { (user.role.includes("admin") || userPermissions.includes( 'archivecontractor' )) &&
              <img
                  src={Restore}
                  alt=""
                  title="Restore"
                  className="undelete-btn"
                  onClick={() => {
                  handleUndelete(d.id, ' this contractor');
                  }}
              /> }
        </> }

			</>
		  }

		  parsedData.push(row);
		});

		if ( parsedData.length > 0 && search && search.length > 0 ) {
		  // Search for matching string in all columns for each row
		  parsedData = parsedData.filter((d) => {
			// Combine all column values in a row into a single string
			const rowValues = Object.entries(d)
			  .filter(([key]) => key !== 'action') // Exclude 'action' property
			  .map(([key, value]) => value)
			  .join(' ')
			  .toLowerCase();

			// Check if the search string is present in any of the columns
			return rowValues.includes(search.toLowerCase());
		  });
		}

		return parsedData;
	  }

	const handleSort = (column, direction, sortedRows) => {
		setSortColumn(column);
		setSortDirection(direction);
		commonService.saveSortData(column, direction, 'Contractors');
	};

	const [selectedRows, setSelectedRows] = React.useState([]);
	const [toggleCleared, setToggleCleared] = React.useState(false);

	const handleRowSelected = React.useCallback(state => {
		setSelectedRows(state.selectedRows);
	}, []);

	const handleBulkDelete = async () => {
		const result = await Swal.fire({
			title: `Are you sure you want to archive all chosen contractors`,
			text: "You have chosen " + selectedRows.length + " contractors",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, archive all!",
		});

		if (result.isConfirmed) {
			axiosInstance
				.delete(process.env.REACT_APP_API_BASEURL + "/contractor-all/" + selectedRows.map(row => row.id).join(","))
				.then(function (response) {
					getContractors();
					toast.success("Contractors(s) archived successfully");
					setSelectedRows([]);
					setToggleCleared(true);
					history.push( location.pathname );
					//location.reload(); // Reloading ensures sites list is updated.
				})
				.catch(function (error) {
					console.log(error);
					toast.error(error?.response?.data?.message || "Unable to archive contractor(s)");
				});
		}
	};
	
	const handleBulkRestore = async () => {
		const result = await Swal.fire({
			title: `Are you sure you want to restore all chosen contractors`,
			text: "You have chosen " + selectedRows.length + " contractors",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, restore all!",
		});

		if (result.isConfirmed) {
			axiosInstance
				.post(process.env.REACT_APP_API_BASEURL + "/undelete-contractors-all/" + selectedRows.map(row => row.id).join(","))
				.then(function (response) {
					getContractors();
					toast.success("Contractor(s) restored successfully");
					setSelectedRows([]);
					setToggleCleared(true);
					history.push( location.pathname );
					//location.reload(); // Reloading ensures sites list is updated.
				})
				.catch(function (error) {
					console.log(error);
					toast.error(error?.response?.data?.message || "Unable to restore Contractor(s)");
				});
		}
	};

	const contextActions = React.useMemo(() => {
		return <></>;
	}, [selectedRows, toggleCleared]);


    return (
        <div className="middle_page">
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: isArchived ? "Archived Contractors" : "Contractors List", path: "/contractors" }]} />
			<CustomPopup
				showClose={false}
				trigger={!pdfLoader}
				setTrigger={() => {

				}}
			>
				<h3>Please Wait...</h3>
				<Spinner visible="true" />
			</CustomPopup>

            <div className="main_container">
                <div>
                    <div className="form_inner table_search">
                        <div className="input_icons">
                            <img src={require("../../images/search.png")} alt="" />
                            <div className="input_field">
                                <input type="text" placeholder="Search Here" value={search} onChange={e => setSearch(e.target.value)} />
                            </div>
                        </div>
                        <div class="input_icons">
                            {/* <div class="w-100">
                                <Select options={MODULE_STATUS} value={status} placeholder="Select Status" onChange={value => {
                                    setStatus(value)

                                }} />
                            </div> */}
                        </div>
                        <div className="listing-buttons">
                            <button onClick={() => { printList() }}>Print</button>
							<button onClick={() => { downloadExcel() }}>Excel</button>
							<button onClick={() => { downloadPDF() }}>PDF</button>

                            {(user.role.includes('admin') || userPermissions.includes('createcontractor')) && <button className='GA2-btn' onClick={() => {
                                history.push("/create-contractor")
                            }}>Add Contractor +</button>}
                        </div>
                    </div>
                    <hr />
					{selectedRows && selectedRows.length > 0 && <>
                      <div className="d-flex justify-content-between align-items-center selected-rows-actions">
                        <span>{selectedRows.length} contractor(s) selected.</span>
                        <span>
                          {isDeleted ? <>
                            <button className='btn btn-danger' onClick={handleBulkRestore}>Restore Selected</button>
                          </> : <>
                            <button className='btn btn-danger' onClick={handleBulkDelete}>Archive Selected</button>
                          </>}
                        </span>
                      </div>
                      <hr />
                    </>}
                    <div id="wrapper">
                        { data.length ? <>
						<DataTable
							columns={columns}
							data={createData()}
							pagination={true}
							sortIcon={<CustomSortIcon />}
							onSort={handleSort}
							{...( user.role.includes("admin") || userPermissions.includes( 'archivecontractor' ) ? {
								selectableRows: true,
								contextActions: contextActions,
								onSelectedRowsChange: handleRowSelected,
								clearSelectedRows: toggleCleared
								} : {})}
							defaultSortFieldId={sortColumn}
							defaultSortAsc={sortDirection === 'asc'}
						/>
						</> : <>
						<Spinner size="sm" animation="border" variant="light" />
						</>}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CommonContractorList
