import React from 'react'
import "./ViewSite.css"
import Header from '../Header/Header'
import { useState } from 'react'
import Cookies from 'universal-cookie';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axiosInstance from '../../helpers/request';
import { useAppState } from '../../state';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Delete from '../../images/circle-cross.svg';
import TableLoading from "../common/TableLoading";
import Select from "react-select";
import CustomPopup from "../Popup/CustomPopup";
import siteService from '../../services/site.service';
import statisticsService from '../../services/statistics.service';
import { onError } from '../../libs/errorLib';


function ViewSite({ title }) {
    const { id } = useParams()
    const history = useHistory()
    const { user, userPermissions } = useAppState()
    const [data, setData] = useState({
        name: "",
        description: ""
    })

    const [loading, setLoading] = useState(false)
    const [companyUsers, setCompanyUsers] = useState([])
    const [siteUsers, setSiteUsers] = useState([])
    const [siteUsersForReassign, setSiteUsersForReassign] = useState([]);

    const [selectedCompanyUsers, setSelectedCompanyUsers] = useState([])
    const [siteAdminUsers, setSiteAdminUsers] = useState([])
    const [siteNormalUsers, setSiteNormalUsers] = useState([])

    const [selectedAdminUser, setSelectedAdminUser] = useState('');
    const [selectedNormalUser, setSelectedNormalUser] = useState('');
    const [pageNumber, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0)
    const [roles, setRoles] = useState([])

    const [reassignPopup, setReassignPopup] = useState(true);
    const [reassignData, setReassignData] = useState({});

    const [pageNumber2, setPage2] = useState(1)
    const [pageSize2, setPageSize2] = useState(10)
    const [total2, setTotal2] = useState(0);
    const [stats, setStats] = useState(null);
    useEffect(() => {
        if (id) {
            userDetail(id);
            getSiteStats(id);
        }
    }, [])

    const getSiteStats = async () => {
        try {
            const response = await statisticsService.getStatistics({siteId: id});
            if (response) {
              setStats(response);
            }
          } catch (e) {
            onError(e);
          }
    }

    const userDetail = async (siteId) => {
        try {
            const userDetailsResponse = await axiosInstance.post(`/userdetails`, {});
            const userDetailsResponseData = userDetailsResponse.data
            if (userDetailsResponseData) {
                const companyId = userDetailsResponseData[0].added_by ? userDetailsResponseData[0].added_by : userDetailsResponseData[0].id;
                if (companyId) {
                    axiosInstance
                        .post(`${process.env.REACT_APP_API_BASEURL}/all-roles`, {
                            companyId: companyId,
                            status: ""
                        })
                        .then((res) => {
                            if (res.data.length > 0) {
                                setRoles(res.data);
                                getCompanyUsers(companyId, siteId,res.data);
                            }
                        })
                        .catch((err) => {
                            console.log("errr", err);
                        });
                }
            }

        } catch (error) {
            console.log("error", error);
        }
    }

    const getCompanyUsers = async (companyId, siteId, roles) => {
        try {
            const allUserResponse = await axiosInstance.post(`/all-users`, {
                companyId: companyId,
                status: ""
            });

            const allUserResponseData = allUserResponse.data
            if (allUserResponseData) {
                const formattedUsers = allUserResponseData.map(user => {
                    return {
                        label: `${user.fullName} - ${user.contractorName? user.contractorName : user.companyName} - ${user.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v)}`,
                        id: user.id
                    }
                })
                console.log("Company-Users", formattedUsers);
                setCompanyUsers(formattedUsers);

                getSite(siteId)
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const getSite = async (siteId) => {
        try {
            const siteResponse = await axiosInstance.get(`/site/${siteId}`);

            const siteResponseData = siteResponse.data;
            if (siteResponseData) {
                setData(siteResponseData[0]);
                getSiteUsers(siteId)
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const getSiteUsers = async (siteId) => {
        setLoading(true)
        try {
            const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
            const siteUsersResponseData = siteUsersResponse.data;
            setLoading(false)
            if (siteUsersResponseData.length) {
                console.log("SiteUsers", siteUsersResponseData);
                setSiteUsers(siteUsersResponseData);
            }

        } catch (error) {
            setLoading(false)
            console.log("error", error);
        }

    }

    useEffect(() => {
        if (siteUsers.length && companyUsers.length) {
            const adminUsers = siteUsers.filter(user => user.type == "admin");
            console.log("SiteAdminUsers", adminUsers);
            setSiteAdminUsers(adminUsers)
            setTotal(adminUsers.length)

            const normalUsers = siteUsers.filter(user => user.type == "user");
            console.log("SiteNormalUsers", normalUsers);
            setSiteNormalUsers(normalUsers)
            setTotal2(normalUsers.length)

            console.log("companyUsers", companyUsers);

            let filteredCompanyUsers = companyUsers.filter(cu => !siteUsers.filter(su => cu.id == su.userid).length);
            console.log("filteredCompanyUsers", filteredCompanyUsers);
            setSelectedCompanyUsers(filteredCompanyUsers)
        } else if (companyUsers.length) {
            console.log("companyUsers-all", companyUsers);
            setSelectedCompanyUsers(companyUsers)
        }

    }, [companyUsers, siteUsers])

    const addSiteUser = (type) => {
        let newUser = {
            siteid: id,
            type
        };

        if (type == "admin" && selectedAdminUser) {
            newUser.userid = selectedAdminUser.id

            submitSiteUser(newUser)
        } else if (type == "user" && selectedNormalUser) {
            newUser.userid = selectedNormalUser.id
            submitSiteUser(newUser)
        } else {
            toast.error("Please select user")
        }
    }

    const resetUser = () => {
        setSelectedAdminUser('');
        setSelectedNormalUser('');
        setSiteAdminUsers([]);
        setSiteNormalUsers([]);
    }

    const submitSiteUser = (newUser) => {
        setLoading(true)
        axiosInstance.post(`/add-site-user`, newUser)
            .then(async (response) => {
                setLoading(false)
                resetUser();
                if (response.status === 200) {
                    getSiteUsers(id);
                    toast.success("Successfully Added User")
                } else {
                    throw new Error(response?.error);
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
                toast.error("Failed to Add User")
            });
    }

    const getReassignmentUser = async (uId, name) => {
        // show reassign popup

        if( ["string", "number"].includes(typeof id)) {
            const siteUsers = await siteService.getSiteUsers(id);
            setSiteUsersForReassign(siteUsers.filter((item) => item.userid != uId));
        }
        
        if(!reassignData.assignTo) {
            setReassignPopup(false);
        } else {
            setReassignPopup(true);
            deleteSiteUser(reassignData.uId, reassignData.name);
        }
    }

    const deleteSiteUser = async (uId, name) => {

        setReassignData({
            ...reassignData,
            uId,
            name
        })
        if(reassignData.assignTo) {
                const result = await Swal.fire({
                title: `Are you sure you want to remove ${name} from this site`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            });

            if (result.isConfirmed) {
                setLoading(true)
                axiosInstance
                    .post("/site-user/" + uId, {
                        userId: uId,
                        siteId: id,
                        assignTo: reassignData.assignTo
                    })
                    .then(function (response) {
                        resetUser();
                        setLoading(false)
                        getSiteUsers(id);
                        toast.success("User deleted successfully");
                        setReassignData({});
                    })
                    .catch(function (error) {
                        setReassignData({});
                        console.error(error);
                        setLoading(false)
                        toast.error(error?.response?.data?.message || "Unable to delete user");
                    });
            }
            setReassignData({});
        } else {
            getReassignmentUser(uId, name);
        }
    };

    const handleArchive = async () => {
        const result = await Swal.fire({
            title: `Are you sure you want to archive this site`,
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, archive it!",
        });

        if (result.isConfirmed) {
            setLoading(true)
            axiosInstance
                .post("/archive-site", { id })
                .then(function (response) {
                    resetUser();
                    setLoading(false)
                    getSiteUsers(id);
                    toast.success("Site archived successfully");
                })
                .catch(function (error) {
                    setLoading(false)
                    toast.error(error?.response?.data?.message || "Unable to archive site");
                });
        }
    }

    const makeAdmin = async (uId) => {

        const result = await Swal.fire({
            title: `Do you really want to make this user an admin?`,
            text: "You can always change this later!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Make Admin!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .post("/site-user-switch/" + uId, {
                    userId: uId,
                    siteId: id,
                    newType: "admin"
                })
                .then(function (response) {
                    setLoading(false)
                    getSiteUsers(id);
                    toast.error(response?.data?.message || "User type switched");
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    }
    
    const makeUser = async (uId) => {

        const result = await Swal.fire({
            title: `Do you really want to make this user a user?`,
            text: "You can always change this later!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Make User!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .post("/site-user-switch/" + uId, {
                    userId: uId,
                    siteId: id,
                    newType: "user"
                })
                .then(function (response) {
                    setLoading(false)
                    getSiteUsers(id);
                    toast.error(response?.data?.message || "User type switched");
                })
                .catch(function (error) {
                    console.error(error);
                });
        }
    }
       

    // console.log("ViewSite-uniqueRoles", uniqueRoles)
    // console.log("ViewSite-roles", roles)
    // console.log("companyUsers-OUT", companyUsers)

    return (
        <div class="middle_page">
            <CustomPopup
                trigger={!reassignPopup}
                setTrigger={() => {
                setReassignPopup(true);
                }}
            >
                <div className="col-12 form_inner p-2">
                    <div className="input_icons">
                        <div className="input_field">
                            <div className="w-100 p-2 text-left">
                                <label for="">
                                    <div className="require-label">
                                        Assign To <span className="text-danger">*</span>
                                    </div>
                                </label>
                                <div className="input_icons">
                                    <div className="w-100">
                                        <Select
                                        onChange={(e) => {
                                            setReassignData({
                                            ...reassignData,
                                            assignTo: e.value,
                                            });
                                        }}
                                        placeholder="Select User"
                                        options={siteUsersForReassign.map((v) => ({
                                            label:
                                            v.fullName +
                                            " - " +
                                            (v.contractorName
                                                ? v.contractorName
                                                : v.companyName) +
                                            " - " +
                                            (v.roleName ? v.roleName : v.role),
                                            value: `${v.userid}`,
                                            name: "assignTo",
                                        }))}
                                        value={siteUsersForReassign
                                            .filter((v) => `${v.userid}` == reassignData?.assignTo)
                                            .map((v) => ({
                                            label:
                                                v.fullName +
                                                " - " +
                                                (v.contractorName
                                                ? v.contractorName
                                                : v.companyName) +
                                                " - " +
                                                (v.roleName ? v.roleName : v.role),
                                            value: `${v.userid}`,
                                            name: "assignTo",
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sign_save_reset_btn">
                                <button
                                    className="btn btn-success"
                                    disabled={
                                    !reassignData?.assignTo
                                    }
                                    onClick={getReassignmentUser}
                                >
                                    Reassign
                                </button>
                            </div>

                            <div className="w-100 p-2 text-left">
                                <div className="caption">
                                    This user may have open action items, please choose another user to reassign these open items to
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomPopup>
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: "Sites List", path: "/sites" }, { name: "View Site" }]} />
            <div class="main_container">
                <div className='d-flex w-100 view_user_content justify-content-between flex-wrap' id="grid-view">
                    <div>
                        <div class="accident mt-0">
                            <div class="accident_text">
                                <h5>Details</h5>
                            </div>
                        </div>
                        <table class="table table-striped">
                            <tbody>
                                <tr>
                                    <th scope="row" style={{ width: "30%" }}>Name:</th>
                                    <td>{data?.name}</td>
                                </tr>
                                <tr>
                                    <th scope="row" style={{ width: "30%" }}>Description:</th>
                                    <td>{data?.description}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='w-100 d-flex justify-content-between px-2'>
                            <button className='view_site_button text-white submit' onClick={() => { history.push("/edit-site/" + id) }}>
                                Edit
                            </button>
                            {((user.role == "admin") || userPermissions.includes( 'archivesites' ) || (user.role == "superadmin")) && (data.archived != "1") && <button className='view_site_button text-dark submit' style={{ background: "#e1eefa" }} onClick={handleArchive}>
                                Archive
                            </button>}
                        </div>
                        <div class="signature">
                            <div class="sign_btns">
                                <div></div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="accident mt-0">
                            <div class="accident_text">
                                <h5>Statistics</h5>
                            </div>
                        </div>
                        <table class="table table-striped d-flex">
                            <tbody className='statistic-table'>
                                <tr>
                                    <th scope="row" width="75%">Users:</th>
                                    <td width='5%'>{siteUsers.length}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">Accident Notifications:</th>
                                    <td width='5%'>{stats?.accidentNotifications ? stats?.accidentNotifications : 0}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">NCR CAR Reports:</th>
                                    <td width='5%'>0</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">Safety Desciplinary Logs:</th>
                                    <td width='5%'>{stats?.safetyDisciplinaryLogs ? stats?.safetyDisciplinaryLogs : 0 }</td>
                                </tr>
                            </tbody>
                            <tbody className='statistic-table'>
                                <tr>
                                    <th scope="row" width="75%">Accident Reports:</th>
                                    <td width='5%'>{stats?.accidentreports ? stats?.accidentreports : 0}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">PPE Issue:</th>
                                    <td width='5%'>{stats?.ppeIssueReports ? stats?.ppeIssueReports : stats?.ppeIssueReports}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">Safety Observations:</th>
                                    <td width='5%'>{stats?.safetyobservations ? stats?.safetyobservations : 0}</td>
                                </tr>
                                <tr>
                                    <th scope="row" width="75%">Safety Checklists:</th>
                                    <td width='5%'>{stats?.safetychecklists ? stats?.safetychecklists : 0}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <div class="accident mt-0">
                            <div class="accident_text">
                                <h5>Site Admins</h5>
                            </div>
                        </div>

                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div className='form_inner p-2'>
                                    {/* <label for="">Users</label> */}
                                    <div id="wrapper" style={{ maxHeight: "600px" }}>
                                        <table class="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="5%">View <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Full Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Email <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Action </th>
                                                    {/* <th scope="col" width="8%">Company Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th> */}
                                                    {/* <th scope="col" width="8%">Role <img src={require("../../images/up-down-arrow.png")} alt="" /></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <TableLoading colSpan={4} />
                                                    : siteAdminUsers.filter((v, i) => (((pageNumber - 1) * pageSize) <= i) && (pageNumber * pageSize) > i).map(d => (<tr key={d.id}>
                                                        <th scope="row" className='cursor-pointer' onClick={() => {
                                                            history.push("/view-user/" + d.id)
                                                        }}>{d.id}</th>
                                                        <td>{d.fullName}</td>
                                                        <td>{d.email}</td>
                                                        <td>

                                                            {typeof d.isPrimary === 'undefined' || ! d.isPrimary ? <>
                                                                <img src={Delete} alt="" onClick={() => { deleteSiteUser(d.id, d.fullName) }} />
                                                                
                                                                <button className="btn btn-success btn-sm ml-2 user-switch-admin" onClick={() => { makeUser(d.id) }}>Make User</button>
                                                            </> : <></>}

                                                        </td>
                                                        {/* <td>{d.companyName}</td> */}
                                                        {/* <td>{d.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v)}</td> */}
                                                    </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination-content mt-3">
                                        <div className="page-entries">
                                            <nav aria-label="...">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <a className="page-link">Show </a>
                                                    </li>
                                                    <li className="page-item">
                                                        {/* <a className="page-link" href="#">1 &nbsp; <img src="../../images/down-arrow.png" alt=""/></a> */}
                                                        <select className='page-link' style={{
                                                            borderRadius: 0,
                                                            padding: "6px 12px",
                                                        }} value={pageSize} onChange={e => {
                                                            setPageSize(e.target.value)
                                                        }}>
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                        </select>

                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Entries </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <p>Showing {((pageNumber - 1) * pageSize) + 1} to {pageNumber * pageSize} from {total} Entries</p>
                                        </div>
                                        <nav aria-label="...">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a className="page-link" onClick={e => {
                                                        e.preventDefault()
                                                        if (pageNumber > 1) {
                                                            setPage(p => p - 1)
                                                        }
                                                    }}>Previous</a>
                                                </li>
                                                {Array.from({ length: Math.ceil(total / pageSize) }).map((v, i) => (<li onClick={()=>setPage(i+1)} className={`page-item ${pageNumber == (i + 1) ? "active" : ""}`} aria-current="page">
                                                    <a className="page-link" href="#">{i + 1}</a>
                                                </li>))}
                                                <li className="page-item">
                                                    <a className="page-link" href="#" onClick={e => {
                                                        e.preventDefault()
                                                        if ((total / pageSize) > pageNumber) {
                                                            setPage(p => p + 1)
                                                        }
                                                    }}>Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='viewBoxWrapper'>
                            <label >Add Site Admin</label>
                            <Autocomplete
                                value={selectedAdminUser}
                                onChange={(event, newValue) => {
                                    console.log("NEW-Val", newValue);
                                    setSelectedAdminUser(newValue);
                                }}
                                name="siteAdmin"
                                disablePortal
                                id="combo-box-demo"
                                options={selectedCompanyUsers}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Select Admin User" />}
                            />
                            <button disabled={!selectedAdminUser} className='view_site_button text-white submit' onClick={!selectedAdminUser ? () => { } : () => { addSiteUser('admin') }}>
                                Add
                            </button>
                        </div>
                    </div>

                    <div>
                        <div class="accident mt-0">
                            <div class="accident_text">
                                <h5>Site Users</h5>
                            </div>
                        </div>
                        <div class="form-1">
                            <div class="form justify-content-start">
                                <div className='form_inner p-2'>
                                    {/* <label for="">Users</label> */}
                                    <div id="wrapper" style={{ maxHeight: "600px" }}>
                                        <table class="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="5%">View <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Full Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Email <img src={require("../../images/up-down-arrow.png")} alt="" /></th>
                                                    <th scope="col" width="8%">Action </th>
                                                    {/* <th scope="col" width="8%">Company Name <img src={require("../../images/up-down-arrow.png")} alt="" /></th> */}
                                                    {/* <th scope="col" width="8%">Role <img src={require("../../images/up-down-arrow.png")} alt="" /></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loading ? <TableLoading colSpan={4} />
                                                    : siteNormalUsers.filter((v, i) => (((pageNumber2 - 1) * pageSize2) <= i) && (pageNumber2 * pageSize2) > i).map(d => (<tr key={d.id}>
                                                        <th scope="row" className='cursor-pointer' onClick={() => {
                                                            history.push("/view-user/" + d.id)
                                                        }}>{d.id}</th>
                                                        <td>{d.fullName}</td>
                                                        <td>{d.email}</td>
                                                        <td>


                                                            <img src={Delete} alt="" onClick={() => { deleteSiteUser(d.id, d.fullName) }} />

                                                            <button className="btn btn-success btn-sm ml-2 user-switch-admin" onClick={() => { makeAdmin(d.id) }}>Make Admin</button>


                                                        </td>
                                                        {/* <td>{d.companyName}</td> */}
                                                        {/* <td>{d.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v)}</td> */}
                                                    </tr>))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="pagination-content mt-3">
                                        <div className="page-entries">
                                            <nav aria-label="...">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <a className="page-link">Show </a>
                                                    </li>
                                                    <li className="page-item">
                                                        {/* <a className="page-link" href="#">1 &nbsp; <img src="../../images/down-arrow.png" alt=""/></a> */}
                                                        <select className='page-link' style={{
                                                            borderRadius: 0,
                                                            padding: "6px 12px",
                                                        }} value={pageSize2} onChange={e => {
                                                            setPageSize2(e.target.value)
                                                        }}>
                                                            <option value={10}>10</option>
                                                            <option value={20}>20</option>
                                                            <option value={50}>50</option>
                                                        </select>

                                                    </li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#">Entries </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                            <p>Showing {((pageNumber2 - 1) * pageSize2) + 1} to {pageNumber2 * pageSize2} from {total2} Entries</p>
                                        </div>
                                        <nav aria-label="...">
                                            <ul className="pagination">
                                                <li className="page-item">
                                                    <a className="page-link" onClick={e => {
                                                        e.preventDefault()
                                                        if (pageNumber2 > 1) {
                                                            setPage2(p => p - 1)
                                                        }
                                                    }}>Previous</a>
                                                </li>
                                                {Array.from({ length: Math.ceil(total2 / pageSize2) }).map((v, i) => (<li onClick={()=>setPage2(i+1)} className={`page-item ${pageNumber2 == (i + 1) ? "active" : ""}`} aria-current="page">
                                                    <a className="page-link" href="#">{i + 1}</a>
                                                </li>))}
                                                <li className="page-item">
                                                    <a className="page-link" href="#" onClick={e => {
                                                        e.preventDefault()
                                                        if ((total2 / pageSize2) > pageNumber2) {
                                                            setPage2(p => p + 1)
                                                        }
                                                    }}>Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='viewBoxWrapper'>
                            <label >Add User</label>
                            <Autocomplete
                                value={selectedNormalUser}
                                onChange={(event, newValue) => {
                                    setSelectedNormalUser(newValue);
                                }}
                                name="siteUser"
                                disablePortal
                                id="combo-box-demo"
                                options={selectedCompanyUsers}
                                sx={{ width: "100%" }}
                                renderInput={(params) => <TextField {...params} label="Select User" />}
                            />
                            <button disabled={!selectedNormalUser} className='view_site_button text-white submit' onClick={!selectedNormalUser ? () => { } : () => { addSiteUser('user') }}>
                                Add
                            </button>
                        </div>
                    </div>
                </div>
                <div className='d-flex w-100 view_user_content justify-content-between'></div>
            </div>
        </div>
    )
}

export default ViewSite