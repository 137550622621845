import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import "./temporaryPermitView.css";
import fileIcon from "../../images/openfolderwhite.svg";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import { getBase64 } from "../common/imageHelper";
import {
  PDFDownloadLink,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";
import TabularData from "../common/pdfRenderer/tabularData";
import PDFFiles from "../common/pdfRenderer/files";

import Swal from "sweetalert2";

export default function ViewTemporaryPermit({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState((new Date()));
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([])
  const [activities, setActivities] = useState([])
  const [popup, setPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [files, setFiles] = useState({ previews: [], files: "" });
  const [denialFiles, setDenialFiles] = useState( { previews: [], files: "" } );

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
  })

  const fetchTemporaryDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/temporary/permit/details`,
        {
          temporaryId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData({ ...res.data[0], existingFiles: res.data[0].files });
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
        getUserEmail(res.data[0].addedByInCompany)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };


  const fetchActivities = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/temporaryactivity/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchTemporaryDetails();
      fetchActivities();
    }
  }, [id]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      console.log("asdfasdfasdfasdf", signatureRef.current.isEmpty())
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async (status) => {
    try {
      let signature
      if (!fieldData.denied || status) {
        signature = await handleSaveVerifySign()
      }
      handleSubmitAfterSave(signature, status)
    } catch (error) {
      console.log(error)
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (signature, status) => {
    const form = new FormData();
    const mailUpdates = siteUsers.filter(v => v.type == "admin").map(v => ({
      email: v.email,
      userid: v.userid,
      fullName: v.fullName,
      emailPref: v.emailPreference ? v.emailPreference.includes("tempWorksPermits") : v.permits.includes("tempWorksPermitsreceiveEmails"),
      isAdminWithNoPref: !v.emailPreference && v.role.includes("admin")
    })).filter(v => v.emailPref || v.isAdminWithNoPref)
    form.append("mailUpdates", mailUpdates.map(v => `${v.email}--${v.fullName}`).join(","));
    form.append("notiticationUpdates", siteUsers.filter(v => v.type == "admin").filter(v => v.userid).map(v => v.userid).join(","));
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    if (fieldData.approved && !status) {
      form.append("approved", fieldData.approved);
    }

    Object.entries(denialFiles?.files)?.map(([key, value]) => {
      form.append(`denialFiles${[key]}`, value);
    });

    if (fieldData.completed && !status) {
      form.append("completed", fieldData.completed);
    }
    if (status) {
      form.append("status", status);
      form.append("date", new Date(date).getTime());
      if (data.existingFiles) {
        form.append("existingFiles", data.existingFiles);
      }
      Object.entries(files.files)?.map(([key, value]) => {
        form.append(`selectedFiles${[key]}`, value);
      });
    }
    form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    let url = ""
    if (status) {
      url = `${process.env.REACT_APP_API_BASEURL}/status/temporary-permit`
    } else {
      url = (fieldData.resubmitted || fieldData.completed) ? `${process.env.REACT_APP_API_BASEURL}/resubmit/temporary-permit` : `${process.env.REACT_APP_API_BASEURL}/update/temporary-permit`
    }
    axiosInstance
      .post(url, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/temporary-permits')
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          signature: null,
        });
        handleClearSignature();
        setFiles({
          previews: [],
          files: ""
        });
        setDenialFiles([])
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const MyDoc = () => {

    const tabularSections = [
      {
        name: 'General Details',
        rows: [
          ['Building', data.status == 1? "Yes" : "Completed"],
          ['Loading', data.status && (data.status != 1) ? (parseInt(data.status) <= 2 ? "Yes" : "Completed") : ""],
          ['Striking', data.status && (data.status == 3) ? ((parseInt(data.status) == 3) && (data.isApproved == 1) ? "Completed" : "Pending") : ""],
          ['Approved', data.isApproved == 1 ? "Yes" : data.isApproved == 0 ? "No" : "Pending"],
          ['Completed', (data.isCompleted && data.isCompleted == 1) ? "Yes" : "No"],
          ['Site', data.siteName ? data.siteName : "-"],
          ['Department', data.departmentName ? data.departmentName : "-"],
          ['Contractor', data.contractorName ? data.contractorName : data.companyName],
          ['Exact Location of works', data.locationOfWorks? data.locationOfWorks : ''],
          ['Submitted By', data.approvedBy? data.approvedBy : "-"],
          ['Date Submitted', data.createdAt ? commonService.formatDate(data.createdAt, "Do MMM YYYY" ): "-"],
          ['Description of temp works to be carried out', data.descriptionOfWorks],
          ['Drawings, specifications and calculation references', data.accessingLocationMethod? data.accessingLocationMethod : "-"],
        ]
      },
      {
        name: 'Other Details',
        rows: [
          ['Is a suitable and sufficient Risk Assessment for the task in place?', (data.suitableRiskAssessment != 2) ? 
            (data.suitableRiskAssessment == 1) ? "Yes" : "No" : "-"
          ],
          ['Have the drawing(s) been approved?', (data.drawingApproved != 2) ? (data.drawingApproved == 1) ? "Yes" : "No" : "-"]
        ]
      },
      {
        'name': 'Additional Comments',
        rows: [
          ['comments', data.otherComments ]
        ]
      }
    ];

    if ( ! data.isApproved ) {
      tabularSections[0].rows.push( ['Reason Denied', data.denialComment ? data.denialComment : "-"] );
    }

    const filesBlocks = [
      {
        name: "Uploaded Files",
        files: data.files,
      }
    ];

    activities.map((activity) => {
      if ( activity.signature ) {
        filesBlocks.push({
          name: activity.action,
          subtitle: activity.comment,
          type: 'signatures',
          files: activity.signature,
        });
      }
    });

    return (
      <PDFRenderer name={"Temporary Permit #" + id} user={userDetails}>
        
        <TabularData
          tabularSections={tabularSections}
        />

        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}

      </PDFRenderer>
    )
  }

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/temporary/" + deleteId)
        .then(function (response) {
          toast.success("Temporary Permit archived successfully");
          history.push(`/temporary-permits`)
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Temporary Permit"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Temporary Works Permit List", path: "/temporary-permits" },
          { name: "Temporary Works Permit", path: `/temporary-permit/${id}` },
        ]}
      />
      <CustomPopup
        trigger={!popup}
        setTrigger={() => {
          setPopup(true)
          setFieldData({
            approved: false,
            resubmitted: false,
            completed: false,
            denied: false,
            approvedComment: "",
            deniedComment: "",
            signature: null,
          })
        }}
      >
        {(fieldData.approved || fieldData.resubmitted || fieldData.completed) && <>
          <div className="sign_image">
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 263, height: 270 }}
            />
            <span
              className="cancel-custom cursor-pointer"
              onClick={handleClearSignature}
            >
              <i
                className="clear_btn  text-danger fa fa-times-circle"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        </>
        }
        {!fieldData.resubmitted && !fieldData.completed && <div className="col-12 form_inner p-2">
          <label for="">
            {fieldData.approved ? "Approval Comment" : "Reason for Denial"} <span className="text-danger">*</span>
          </label>
          <div className="input_icons">
            <div className="input_field">
              <textarea
                name="approvedComment"
                maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                rows="5"
                cols="100"
                onChange={(e) => {
                  if (fieldData.approved) {
                    setFieldData({
                      ...fieldData,
                      approvedComment: e.target.value,
                    })
                  } else {
                    setFieldData({
                      ...fieldData,
                      deniedComment: e.target.value,
                    })
                  }
                }}
              ></textarea>
            </div>
          </div>
          <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>

          {!fieldData.approved && <>
            <div className="d-flex preview-upload-files w-100">
              {!!denialFiles?.previews?.length &&
                denialFiles.previews.map((imgPreviewUrl) => (
                  <div id="profile_pic_1_preview" className="image-fixed m-2">
                    <img
                      className="obj_fit_img obj-fit-cover rounded"
                      src={imgPreviewUrl}
                      alt=""
                      height="150"
                      width="150"
                    />
                  </div>
                ))}
            </div>

            <div className="select_files">
              <input
                name="newfilesCloseIssue"
                type="file"
                multiple
                id="upload"
                hidden
                onChange={e => {
                  Promise.all(Array.from(e.target.files).map((file) => getBase64(file)))
                  .then((result) => {
                    setDenialFiles({ previews: result, files: e.target.files });
                  })
                  .catch((err) => {
                    
                  });
                }}
              />
              <label for="upload">
                <img src={fileIcon} alt="Icon" />
                Select files...
              </label>
            </div>
            <div className="select_all_files">
              Select all files you wish to upload in one go and NOT
              individually.
            </div>
            <div className="hold_down">
              Hold down CTRL (CMD on Mac) to select multiple files.
            </div>
          </>}

        </div>}
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            {...(fieldData.approved ? {
              disabled:
                fieldData.approved ?
                  false :
                  !(fieldData.deniedComment)
            } : null)}
            onClick={() => {
              handleSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>
      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">General Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Building : </div>
                            <div className="col-6 dd-access">
                              <span className={"roundPill " + (data.status == 1 ? 'blue-bg' : 'green-bg' )}>
                                {data.status == 1
                                  ? "Yes"
                                  : "Completed"}
                              </span>
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Loading : </div>
                            <div className="col-6 dd-access">
                              <span className={"roundPill " + (data.status && (data.status != 1) ? (parseInt(data.status) <= 2 ? 'blue-bg' : 'green-bg') : 'yellow-bg' )}>
                                {data.status && (data.status != 1) ? (parseInt(data.status) <= 2
                                  ? "Yes"
                                  : "Completed") : ""}
                              </span>
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Striking : </div>
                            <div className="col-6 dd-access">
                              {data.status && (data.status == 3) ? ((parseInt(data.status) == 3) && (data.isApproved == 1)
                                ? "Completed"
                                : "Pending") : ""}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Approved : </div>
                            <div className="col-6 dd-access">
                              <span className={"roundPill " + (data.isApproved == 2 ? "yellow-bg" : ( data.isApproved == 1 ? "blue-bg" : "red-bg" ) ) }>
                                {data.isApproved == 1
                                  ? "Approved"
                                  : data.isApproved == 0
                                    ? "No"
                                    : "Pending"}
                              </span>
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Completed : </div>
                            <div className="col-6 dd-access">
                              <span className={"roundPill " + (data.isCompleted == 1 ? 'green-bg' : 'red-bg' )}>
                                {(data.isCompleted && data.isCompleted == 1) ? "Yes" : "No"}
                              </span>
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Site : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Department : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.departmentName ? data.departmentName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Contractor : </div>
                            <div className="col-6 dd-access">
                              {data.contractorName ? data.contractorName : data.companyName}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Exact Location of works:{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.locationOfWorks ? data.locationOfWorks : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Submitted By :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.submittedBy ? data.submittedBy : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Date Submitted :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {data.createdAt
                                ? commonService.formatDate(
                                  data.createdAt,
                                  "Do MMM YYYY"
                                )
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Description of temp works to be carried out  :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {data.descriptionOfWorks}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Drawings, specifications and calculation references :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {data.accessingLocationMethod}
                            </div>
                          </div>
                          {!data.isApproved && <div className="mb-1 d-flex dl-access alert alert-danger">
                            <div className="col-6 dd-access">Reason Denied : </div>
                            <div className="col-6 dd-access">
                              {data.denialComment ? data.denialComment : "-"}{" "}
                            </div>
                          </div>}
                        </div>
                      </div>
                      {(data.status ? data.status == 2 : true) && siteUsers.filter(user => (user.type == "admin") && (user.userid == userDetails.id)).length && data.isApproved != 1 && (!data.isApproved || data.isApproved == 2) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            approved: true
                          })
                          setPopup(false)
                        }}>Approve</button>
                        <button className="btn btn-danger ms-2" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            denied: true
                          })
                          setPopup(false)
                        }}>Deny</button><br/><br />
                      </>
                        : null}
                      {(data.isApproved == 0) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            resubmitted: true
                          })
                          setPopup(false)
                        }}>Resubmit</button>
                      </>
                        : null}
                      {(data.isApproved == 0) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-outline-success ms-1" onClick={() => {
                          history.push(`/edit-temporary-permit/${data.id}`)
                        }}>Edit</button>
                      </>
                        : null}
                      {(data.isApproved == 1) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            completed: true
                          })
                          setPopup(false)
                        }}>Complete</button>
                      </>
                        : null}

                      {id && ( userDetails.role.includes("admin") || userPermissions.includes( 'deleteTempWorksPermits' ) ) && data.isDeleted != 1 ? <>
                        <button className="btn btn-outline-danger ms-1 mt-2" onClick={() => handleArchive(id)}>
                          Archive
                        </button>
                      </> : ''}
                    </div>

                    <div className="mb-3">
                      <div className="header-access-view">Additional Comments</div>
                      <div className="p-2 main-restriction-div">
                        <div className="row mb-2">
                          <div className="d-flex">

                            <span className="rst-details fw-bold">
                              {data.otherComments}
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>



                  </div>
                </div>
                <div className="col-12 col-lg-7 mb-2">
                  <div className="d-flex flex-column">
                    {data.status == 2 && data.isApproved != 1 ? (! data.isApproved || data.isApproved == 2) && <>
                      <div className="mb-3">
                        <div className="header-access-view yellow-bg" style={{fontWeight: 'bold',textAlign: 'center'}}>
                          Pending Approval
                        </div>
                      </div>
                    </> : ((data.status == 1) || (data.status == 2)) && <div className="mb-3">
                      <div className="header-access-view">{(data.status == 1) ? "Permit To Load" : "Permit To Strike"}</div>
                      <div className="p-2 main-restriction-div">
                        <span className="rst-title mt-2 mb-0 text-danger">
                          {
                            data.status == 2 ?
                              `The permanent works which have been constructed using temporary works as described above are now sufficiently advanced and have achieved adequate strength and stability. Back propping as required within the temporary works design is to remain in position until the concrete is sufficiently curred.`
                              : `The temporary works have been checked as being constructed and erected in accordance with the design drawings and are ready for loading.`}
                        </span>
                        <div className="row mb-2">
                          <span className="rst-title mt-2 mb-0 fs-6">
                            Add your Signature
                          </span>
                          <div className="sign_image ms-2">
                            <SignatureCanvas
                              ref={signatureRef}
                              canvasProps={{ width: 263, height: 270 }}
                            />
                            <span
                              className="cancel-custom cursor-pointer"
                              onClick={handleClearSignature}
                            >
                              <i
                                className="clear_btn  text-danger fa fa-times-circle"
                                aria-hidden="true"
                              ></i>
                            </span>
                          </div>
                          <div className="col-12 p-2 location_ladder">
                            <div className="input_icons">
                              <div className="input_field">
                                <input min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} type="date" value={date.toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")} onChange={e => setDate(e.target.value)} />
                              </div>
                            </div>
                          </div>
                          {(data.status == 2) && <>
                            <span className="rst-title mt-2 mb-0 fs-6">
                              Upload Cube Results
                            </span>
                            <div className="d-flex ps-0">
                              {!!files.previews.length && files.previews.map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                                <img
                                  className="obj_fit_img obj-fit-cover rounded"
                                  src={imgPreviewUrl}
                                  alt=""
                                  height="150"
                                  width="150"
                                />
                              </div>))}
                            </div>
                            <div className="select_files ps-0">
                              <input
                                name="files"
                                type="file"
                                multiple
                                id="upload"
                                hidden
                                onChange={e => {
                                  Promise.all(Array.from(e.target.files).map(file => getBase64(file)))
                                    .then((result) => {
                                      setFiles({ previews: result, files: e.target.files });
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });

                                }}
                              />
                              <label for="upload">
                                <img src={fileIcon} alt="Icon" />
                                Select files...
                              </label>
                            </div>
                            <div className="select_all_files ps-0">
                              Select all files you wish to upload in one go and NOT individually.
                            </div>
                            <div className="hold_down">
                              Hold down CTRL (CMD on Mac) to select multiple files.
                            </div>
                          </>}
                          <div className="col-12 p-2 location_ladder">
                            <div className="input_icons">
                              <div className="input_field d-flex">
                                <button
                                  className="btn btn-success me-2"
                                  // disabled={(data.status == 2)?files.files.length==0:false}
                                  onClick={() => {
                                    if ((data.status == 2) && files.files.length == 0) {
                                      toast.error("You must select at least 1 file to upload.")
                                    } else {
                                      handleSubmit(data.status == 1 ? 2 : 3)
                                    }
                                  }
                                  }
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>}

                    <div className="mb-3">
                      <div className="header-access-view">Other Details</div>
                      <div className="p-2 main-restriction-div">
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Is a suitable and sufficient Risk Assessment for the task in place? :{" "}
                            </span>
                            <span className="rst-details fw-bold">
                              {(data.suitableRiskAssessment != 2) ? (data.suitableRiskAssessment == 1) ? "Yes" : "No" : "-"}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="d-flex">
                            <span className="rst-title me-2">
                              Have the drawing(s) been approved? :{" "}
                            </span>
                            <span className="rst-details fw-bold">
                              {(data.drawingApproved != 2) ? (data.drawingApproved == 1) ? "Yes" : "No" : "-"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mb-3" style={{ border: '1px solid #e2e6ea', borderRadius: '8px', overflow: "auto", maxHeight: "670px" }}>
                      <div className="header-access-view position-sticky" style={{ top: 0 }}>Activity</div>
                      {activities.map(activity => (<div className="m-2">
                        <div className="p-2 signature-div-inner">
                          <div className="d-flex justify-content-between mb-2">
                            <span className="rst-title me-2">
                              {activity.action} :{" "}
                            </span>
                            <span className="rst-title me-2 text-secondary">
                              {activity.datetime}
                            </span>
                          </div>
                          {activity.comment ?
                            <p>{activity.comment}</p>
                            : null}
                          {activity.signature ? <img
                            onError={(e) => {
                              e.target.src = DefaultImage;
                            }}
                            className="obj-fit-cover"
                            height="250"
                            width="250"
                            src={
                              activity.signature
                                ? process.env.REACT_APP_API_BASEURL +
                                activity.signature
                                : DefaultImage
                            }
                          ></img> : null}
                        </div>
                      </div>))}
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="row">
                <div className="col-12">
                  <div className="header-access-view"> Uploaded Files</div>
                  <div className="d-flex">
                    {data.files && data.files.split(",").map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                      {commonService.outputImageTile(imgPreviewUrl)}
                    </div>))}
                  </div>
                </div>
              </div>

              {data.denialFiles && <>
                <div className="row">
                  <div className="col-12">
                    <div className="header-access-view"> Denial Files</div>
                    <div className="d-flex">
                      {data.denialFiles && data.denialFiles.split(",").map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                        {commonService.outputImageTile(imgPreviewUrl)}
                      </div>))}
                    </div>
                  </div>
                </div>
              </>}

              {data.isApproved == 1 && <>
                <div className="row mt-3">
                  <div className="col-md-4 mt-3">
                    <PDFDownloadLink
                      document={<MyDoc />}
                      className="mt-2 btn btn-primary pdf-download-btn"
                      fileName={"temporary-permit-" + id + ".pdf"}
                    >
                      {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download PDF"
                      }
                    </PDFDownloadLink>
                  </div>
                </div>
              </>}
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
