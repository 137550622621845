import React from "react";
import "./list.css";
import Header from "../../Header/Header";
import { useAppState } from "../../../state";
import Cookies from "universal-cookie";
import { useHistory } from "react-router-dom";
import axiosInstance from "../../../helpers/request";
import { useEffect, useState } from "react";
import Edit2 from "../../../images/edit-2.svg";
import Delete from "../../../images/archive-icon.svg";
import Restore from "../../../images/undo.svg";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { commonService } from "../../common/common.service";

import Table from "../../common/Table";
import DataTable from "react-data-table-component";
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import CustomPopup from "../../Popup/CustomPopup";

export default function CommonAccidentReportsList({ title, isAll, isDeleted }) {
  const { user, selectedSite, userPermissions, selectedCompany } = useAppState();
  const cookies = new Cookies();
  const history = useHistory();
  const userToken = localStorage.getItem("cs_token");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [companyId, setUserID] = useState("");
  const [search, setSearch] = useState("");
  const [pdfLoader, setPdfLoader] = useState(true);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  useEffect(() => {
    userDetail();
  }, []);

  useEffect(() => {
    if (companyId) {
      getAccidentReports();
    }
  }, [companyId, selectedSite]);

  useEffect(() => {
    setUserID(selectedCompany);
  }, [selectedCompany]);

  const userDetail = () => {
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (res.data.length > 0) {
          if ( res.data[0].role == 'superadmin' ) {
            setUserID( selectedCompany );
          } else {
            setUserID(
              res.data[0].added_by ? res.data[0].added_by : res.data[0].id
            );
          }
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  };

  const getAccidentReports = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/list-accident-reports`,
        {
          isAll: isAll,
          isDeleted: isDeleted,
          siteId: localStorage.getItem(`${user.id}-currentSite`),
          contractorId: user.role.includes("admin") ? null : user.contractorId,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        // if (res.data.length > 0) {
        setData(
          res.data.filter(
            (site, index, arr) =>
              res.data.findIndex((item) => item.id == site.id) == index
          )
        );

        const savedSort = commonService.loadSortData('accidentReports');
        if (savedSort) {
          const { direction, columnSelector } = savedSort;
          setSortDirection(direction);
          setSortColumn(columnSelector);
        }

        setLoading(false);
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log("errr", err);
      });
  };


  const handleDelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive ${name}`,
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/accident-reports/" + id)
        .then(function (response) {
          getAccidentReports();
          toast.success("Accident Report archived successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Accident Report"
          );
        });
    }
  };

  const handleUndelete = async (id, name) => {
    const result = await Swal.fire({
      title: `Are you sure you want to restore ${name}`,
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, restore it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .post(process.env.REACT_APP_API_BASEURL + "/undelete-accident-reports/" + id)
        .then(function (response) {
          getAccidentReports();
          toast.success("Accident Report restored successfully");
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to restore Accident Report"
          );
        });
    }
  };

  const tableHeader = [
    "ID",
    "Injured Person",
    "Date",
    "Time",
    "Reported To",
    "First Aid Given",
    "Removed to Hospital",
    "Site",
    "Department",
    "Status",
    "Time Lost"
  ];

  function getActionsConvertableArray() {
    let array = [];

    // add all items from data but skip actions
    data.forEach((d) => {
      array.push({
        id: d.id,
        ipName: d.ipName ? d.ipName : "",
        accidentDate: d.accidentDate ? commonService.formatDate(d.accidentDate, "Do MMM YYYY") : "",
        accidentTime: d.accidentTime ? d.accidentTime : "",
        reportedTo: d.reportedTo? d.reportedTo : "",
        firstAid: d.firstAid == 1? 'Yes' : 'No',
        removedToHospital: d.removedToHospital == 1 ? 'Yes' : 'No',
        site: d.siteName ? d.siteName : "",
        department: d.departmentName ? d.departmentName : "",
        status: d.status ? d.status : "",
        timeLost: d.timeLost ? d.timeLost : "",
      });
    });

    return array;
  }

  const columns = [
    {
      name: "View",
      selector: "view",
      width: "100px",
      cell: (row) => <div>{row.view}</div>,
      sortFunction: (a, b) => {
        return a.id - b.id;
      },
    },
    {
      name: "IP Name",
      selector: "ipName",
      searchField: "ipName",
      cell: (row) => <div>{row.ipName}</div>,
    },
    {
      name: "Accident Date",
      selector: "accidentDate",
      searchField: "accidentDate",
      cell: (row) => <div>{row.accidentDate}</div>,
      sortFunction: (a, b) => {
        return a.accidentDatePlain - b.accidentDatePlain;
      },
    },
    {
      name: "Accident Time",
      selector: "accidentTime",
      searchField: "accidentTime",
      cell: (row) => row.accidentTime,
      sortFunction: (a, b) => {
        return a.accidentTimePlain - b.accidentTimePlain;
      },
    },
    {
      name: "Reported To",
      selector: "reportedTo",
      searchField: "reportedTo",
      cell: (row) => <div>{row.reportedTo}</div>,
    },
    {
      name: "First Aid Given?",
      selector: "firstAid",
      searchField: "firstAidPlain",
      cell: (row) => <div>{row.firstAid}</div>,
    },
    {
      name: "Removed to Hospital?",
      selector: "removedToHospital",
      searchField: "removedToHospitalPlain",
      cell: (row) => <div>{row.removedToHospital}</div>,
    },
    {
      name: "Site",
      selector: "siteName",
      searchField: "siteName",
      cell: (row) => <div>{row.siteName}</div>,
    },
    {
      name: "Department",
      selector: "departmentName",
      searchField: "departmentName",
      cell: (row) => <div>{row.departmentName}</div>,
    },
    {
      name: "Status",
      selector: "status",
      searchField: "statusPlain",
      cell: (row) => <div>{row.status}</div>,
    },
    {
      name: "Time Lost",
      selector: "timeLost",
      searchField: "timeLost",
      cell: (row) => <div>{row.timeLost}</div>,
    },
  ];

  // add minWidth to each item in columns
  columns.forEach((column) => {
    if (
      typeof column.minWidth == "undefined" &&
      typeof column.width == "undefined"
    ) {
      column.minWidth = "200px";
    }

    // Add sorting to all columns
    if (typeof column.sortable === "undefined") {
      column.sortable = true;
    }

    if (
      typeof column.sortFunction === "undefined" &&
      typeof column.searchField !== "undefined"
    ) {
      column.sortFunction = (a, b) =>
        a[column.searchField].localeCompare(b[column.searchField]);
    }
  });

  if (user.role.includes("admin") || userPermissions.includes( 'deleteAccidentReports' )) {
    columns.push({
      name: "Action",
      sortable: false,
      selector: "actions",
      cell: (row) => <div>{row.actions}</div>,
      allowOverflow: true,
    });
  }

  const downloadExcel = () => {
    commonService.downloadArrayOfObjectsAsXLSX(
      getActionsConvertableArray(),
      tableHeader,
      title.toLowerCase().replace(" ", "-") + ".xlsx"
    );
  };

  const printList = () => {
    // Trigger the actual print
    commonService.triggerPrint(
      getActionsConvertableArray(),
      tableHeader,
      title
    );
  };

  const PDFReadyTable = () => {
    // Implement download PDF
    const pdfTableData = {
      column: tableHeader,
      data: getActionsConvertableArray(),
    };

    return (
      <Document>
        <Page
          orientation="landscape"
          size="LETTER"
          style={{ padding: "20px 16px 30px" }}
        >
          <Table data={pdfTableData} heading={title} />
        </Page>
      </Document>
    );
  };

  const downloadPDF = async () => {
    // If we have larger data set, show laoder.
    if (data.length > 100) {
      setPdfLoader(false);
    }
    const blob = await pdf(<PDFReadyTable />).toBlob();
    saveAs(blob, title.toLowerCase().replace(/ /g, "-").split("(")[0] + ".pdf");
    setPdfLoader(true);
  };

  const CustomSortIcon = () => <span className="dt-sort-icon"></span>;

  const createData = () => {
    let parsedData = [];

    if (data.length == 0) {
      return parsedData;
    }

    data.forEach((d) => {
      let row = {
        id: d.id,
        view: (
          <span
            onClick={() => {
              history.push(`/view-accident-reports/${d.id}`);
            }}
            className="lists-id-col"
          >
            {d.id}
          </span>
        ),
        
        ipName: d.ipName ? d.ipName : "",
        accidentDate: d.accidentDate? commonService.formatDate(d.accidentDate, "Do MMMM YYYY") : 0,
        accidentTime: d.accidentTime? d.accidentTime : '',
        reportedTo: d.reportedTo ? d.reportedTo : "",
        firstAid: d.firstAid == 1 ? <span className="roundPill green-bg">Yes</span> : <span className="roundPill red-bg">No</span>,
        removedToHospital: d.removedToHospital == 1 ? <span className="roundPill green-bg">Yes</span> : <span className="roundPill red-bg">No</span>,

        siteName: d.siteName ? d.siteName : "",
        departmentName: d.departmentName ? d.departmentName : "",
        status: d.status == 'In Progress'? <span className="roundPill yellow-bg">{d.status}</span> : <span className="roundPill green-bg">{d.status}</span>,
        timeLost: d.timeLost ? d.timeLost : "",

        firstAidPlain: d.firstAid == 1 ? '1' : '0',
        removedToHospitalPlain: d.removedToHospital == 1 ? '1' : '0',
        statusPlain: d.status,
        accidentTimePlain: d.accidentTime ? d.accidentTime.replace(/\:/g, '') : '', 
        accidentDatePlain: d.accidentDate
          ? commonService.formatDate(d.accidentDate, "YYYYMMDD")
          : 0,
      };

      if (user.role.includes("admin") || userPermissions.includes( 'deleteAccidentReports' )) {
        row.actions = (
          <>
            {!isDeleted && (user.role.includes("admin") || userPermissions.includes( 'deleteAccidentReports' )) && (
              <img
                src={Delete}
                alt=""
                onClick={() => {
                  handleDelete(d.id, " this Accident Report");
                }}
              />
            )}

            {isDeleted && ( user.role.includes("admin") || userPermissions.includes( 'deleteAccidentReports' ) ) && (
              <img
              src={Restore}
              alt=""
              title="Restore"
              className="undelete-btn"
              onClick={() => {
                handleUndelete(d.id, ' this report');
              }}
            />
            )}

            {!isDeleted && d.status != 'Closed' && (
              <img
                src={Edit2}
                alt=""
                className="ms-2"
                onClick={() => {
                  history.push(`/edit-accident-report/${d.accidentNotificationId}/${d.id}`);
                }}
              />
            )}
          </>
        );
      }

      parsedData.push(row);
    });

    if (parsedData.length > 0 && search && search.length > 0) {
      // Search for matching string in all columns for each row
      parsedData = parsedData.filter((d) => {
        // Combine all column values in a row into a single string
        const rowValues = Object.entries(d)
          .filter(([key]) => key !== "action") // Exclude 'action' property
          .map(([key, value]) => value)
          .join(" ")
          .toLowerCase();

        // Check if the search string is present in any of the columns
        return rowValues.includes(search.toLowerCase());
      });
    }

    return parsedData;
  };

  const handleSort = (column, direction, sortedRows) => {
    setSortColumn(column);
    setSortDirection(direction);
    commonService.saveSortData(column, direction, 'accidentReports');
  };

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleRowSelected = React.useCallback(state => {
      setSelectedRows(state.selectedRows);
  }, []);

  const handleBulkDelete = async () => {
      const result = await Swal.fire({
          title: `Are you sure you want to archive all chosen Accident Report(s)`,
          text: "You have chosen " + selectedRows.length + " Accident Report(s)",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, archive all!",
      });

      if (result.isConfirmed) {
          axiosInstance
              .delete(process.env.REACT_APP_API_BASEURL + "/accident-reports-all/" + selectedRows.map(row => row.id).join(","))
              .then(function (response) {
                  getAccidentReports();
                  toast.success("Accident Report(s) archived successfully");
                  setSelectedRows([]);
                  setToggleCleared(true);
                  //location.reload(); // Reloading ensures sites list is updated.
              })
              .catch(function (error) {
                  console.log(error);
                  toast.error(error?.response?.data?.message || "Unable to archive Accident Notification(s)");
              });
      }
  };

  const handleBulkRestore = async () => {
      const result = await Swal.fire({
          title: `Are you sure you want to restore all chosen Accident Report(s)`,
          text: "You have chosen " + selectedRows.length + " Accident Report(s)",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, restore all!",
      });

      if (result.isConfirmed) {
          axiosInstance
              .post(process.env.REACT_APP_API_BASEURL + "/undelete-accident-reports-all/" + selectedRows.map(row => row.id).join(","))
              .then(function (response) {
                  getAccidentReports();
                  toast.success("Accident Report(s) restored successfully");
                  setSelectedRows([]);
                  setToggleCleared(true);
                  //location.reload(); // Reloading ensures sites list is updated.
              })
              .catch(function (error) {
                  console.log(error);
                  toast.error(error?.response?.data?.message || "Unable to restore Accident Report(s)");
              });
      }
  };

  const contextActions = React.useMemo(() => {
      return <></>;
  }, [selectedRows, toggleCleared]);

  return (
    <div className="middle_page">
      <Header
        title={title}
        steps={[
          { name: "Home", path: "/" },
          { name: "Accident Reports List", path: "/view-accident-reports" },
        ]}
      />

      <CustomPopup showClose={false} trigger={!pdfLoader} setTrigger={() => {}}>
        <h3>Please Wait...</h3>
        <Spinner visible="true" />
      </CustomPopup>

      <div className="main_container">
        <div>
          <div className="form_inner table_search">
            <div className="input_icons">
              <img src={require("../../../images/search.png")} alt="" />
              <div className="input_field">
                <input
                  type="text"
                  placeholder="Search Here"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  printList();
                }}
              >
                Print
              </button>
              <button
                onClick={() => {
                  downloadExcel();
                }}
              >
                Excel
              </button>
              <button
                onClick={() => {
                  downloadPDF();
                }}
              >
                PDF
              </button>
            </div>
          </div>
          <hr />
          {selectedRows && selectedRows.length > 0 && <>
              <div className="d-flex justify-content-between align-items-center selected-rows-actions">
                  <span>{selectedRows.length} Accident Report(s) selected.</span>
                  <span>
                      {isDeleted ? <>
                          <button className='btn btn-danger' onClick={handleBulkRestore}>Restore Selected</button>
                      </> : <>
                          <button className='btn btn-danger' onClick={handleBulkDelete}>Archive Selected</button>
                      </>}
                  </span>
              </div>
              <hr />
          </>}
          <div id="wrapper">
            {!loading ? (
              <>
                <DataTable
                  columns={columns}
                  data={createData()}
                  pagination={true}
                  sortIcon={<CustomSortIcon />}
                  onSort={handleSort}
                  {...( user.role.includes("admin") || userPermissions.includes( 'deleteAccidentReports' ) ? {
                    selectableRows: true,
                    contextActions: contextActions,
                    onSelectedRowsChange: handleRowSelected,
                    clearSelectedRows: toggleCleared
                  } : {})}
                  defaultSortFieldId={sortColumn}
                  defaultSortAsc={sortDirection === 'asc'}
                />
              </>
            ) : (
              <>
                <Spinner size="sm" animation="border" variant="light" />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
