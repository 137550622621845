import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import fileIcon from "../../images/openfolderwhite.svg";
import { getBase64 } from "../common/imageHelper";
import Swal from "sweetalert2";

export default function ViewSafetyDisciplinaryLogs({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([])
  const [activities, setActivities] = useState([])
  const [popup, setPopup] = useState(true);
  const [returnPopup, setReturnPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    approvedComment: "",
    deniedComment: "",
    signature: null,
    actionTaken: false,
    actionStatus: false,
    actionFiles: false,
  });

  const [returnData, setReturnData] = useState({
    signature: false,
    date: false,
    interpreterRequired: null
  });

  const [files, setFiles] = useState([]);

  const statusOptions = [
    { value: 'Open', label: 'Open', name: 'actionStatus', },
    { value: 'In Progress', label: 'In Progress', name: 'actionStatus', },
    { value: 'Closed', label: 'Closed', name: 'actionStatus', },
  ];

  const fetchSafetyDisciplinaryLogs = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/safety-disciplinary-logs/details`,
        {
          safetyDisciplinaryLogId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
        getUserEmail(res.data[0].addedByInCompany)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );

        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };


  const fetchActivities = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/safety-observations-activities/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchSafetyDisciplinaryLogs();
      fetchActivities();
    }
  }, [id]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setReturnData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setReturnData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setReturnData({
      ...returnData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      let signature = await handleSaveVerifySign()
      handleSubmitAfterSave( signature )
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (signature) => {
    const form = new FormData();
    let error = false;
    if ( ! signature ) {
      toast.error( "Please sign the commitment" );
      error = true;
    }
    
    if ( returnData.interpreterRequired == null ) {
      toast.error( "Please select if interpreter is required" );
      error = true;
    }
    
    if ( ! returnData.returnDate ) {
      toast.error( "Please select date" );
      error = true;
    }

    if ( error ) {
      return;
    }
    
    form.append( "returnSubmittedBy", userDetails.id );
    form.append( "signature", signature );
    form.append( "interpreterRequired", returnData.interpreterRequired );
    form.append( "returnDate", commonService.formatDate(returnData.returnDate, "YYYY-MM-DD") );

    form.append("id", id);
    
    setLoading(true);
    
    axiosInstance
      .post(`${process.env.REACT_APP_API_BASEURL}/safety-disciplinary-logs/returned-on-site`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setReturnData({
          interpreterRequired: null,
          returnDate: "",
          signature: null,
        });
        
        setReturnPopup(true);

        fetchSafetyDisciplinaryLogs();
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/safety-disciplinary-log/" + deleteId)
        .then(function (response) {
          toast.success("Safety Disciplinary Log archived successfully");
          window.location = '/safety-disciplinary-logs';
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive Safety Disciplinary Log"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "Safety Disciplinary Logs List", path: "/safety-disciplinary-logs/" },
          { name: "Safety Disciplinary Log", path: `/safety-disciplinary-logs/${id}` },
        ]}
      />

      <CustomPopup
        trigger={!returnPopup}
        setTrigger={() => {
          setReturnPopup(true);
          setReturnData({});
        }}
      >
        <h2>Commitment to Return to Work</h2>
        <div className="return-to-site-message">
          <p>I confirm, following a suspension from site for breaches of Site Rules / Health and Safety Regulations that I have been re-inducted to this site and I understand the Site Rules and my duties under the Legislation.</p>
          <p>I further confirm, I undertake to carry out my duties in a safe and responsible manner, bearing in mind my own safety and that of my fellow workers.</p>
          <p>I further understand and agree, should I breach these Rules and Regulations again that I may be permanently expelled / dismissed (direct employee) this site and from all other sites under the control, supervision or management of Bennett.</p>
        </div>

        <div style={{marginBottom: '0', fontWeight: 'bold'}}>Signature</div>
        <div className="sign_image">
          <SignatureCanvas
            ref={signatureRef}
            canvasProps={{ width: 263, height: 270 }}
          />
          <span
            className="cancel-custom cursor-pointer"
            onClick={handleClearSignature}
          >
            <i
              className="clear_btn  text-danger fa fa-times-circle"
              aria-hidden="true"
            ></i>
          </span>
        </div>

        <div className="w-100 p-2">
          <FormControl>
            <FormLabel class="inspected_label">
              Interpreter <small>Required</small> <span className="text-danger">*</span>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="interpreterRequired"
              onChange={handleChange}
            >
              <FormControlLabel checked={returnData.interpreterRequired == 1} value={1} control={<Radio />} label="Yes" />
              <FormControlLabel checked={returnData.interpreterRequired == 0} value={0} control={<Radio />} label="No" />
            </RadioGroup>
          </FormControl>
        </div>

        <div className="w-100 p-2 location_access">
          <label for="">
            <div className="require-label">
              {" "}
              Date <span className="text-danger">*</span>
            </div>
          </label>
          <div className="input_icons">
            <div className="input_field">
              <input
              min={(new Date()).toLocaleString('en-GB').substring(0, 10).split("/").reverse().join("-")}
              type="date"
              onChange={handleChange}
              name="returnDate" />
            </div>
          </div>
        </div>

        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            onClick={() => {
              handleSubmit()
            }}
          >
            Submit
          </button>
        </div>

      </CustomPopup>
   
      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <div className="row">
                <div className="col-8 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Site : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Department : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.departmentName ? data.departmentName : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Company : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.contractorName ? data.contractorName : "-"}
                            </div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Date & Time :{" "}
                            </div>
                            <div className="col-6 dd-access">
                            {data.createdAt
                                ? ( commonService.formatDate(
                                  data.createdAt,
                                  "Do MMM YYYY @ HH:MM"
                                ) == 'Invalid date' ? data.createdAt : commonService.formatDate(
                                  data.createdAt,
                                  "Do MMM YYYY @ HH:MM"
                                ))
                                : "-"}{" "}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Operative : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.operative ? data.operative : "-"}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Warning Type : </div>
                            <div className="col-6 dd-access">
                              {data.warningType == 1 ? 'Verbal' : 'Written'}
                            </div>
                          </div>
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Suspended? : </div>
                            <div className="col-6 dd-access">
                              <span className={`roundPill ${data.isSuspended == '1' ? "red-bg" :  "green-bg"}`}>
                                {data.isSuspended == '1'
                                  ? 'Yes'
                                  : 'No'}
                                </span>
                            </div>
                          </div>

                          {data.isSuspended ? (
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Suspended Until : </div>
                              <div className="col-6 dd-access">
                                {data.suspendedDate
                                  ? ( commonService.formatDate(
                                    data.suspendedDate,
                                    "Do MMM YYYY"
                                  ) == 'Invalid date' ? data.suspendedDate : commonService.formatDate(
                                    data.suspendedDate,
                                    "Do MMM YYYY"
                                  ))
                                  : "-"}{" "}

                                  {data.returnedOnSite != 1 && <>
                                    <a
                                      href="#"
                                      className="btn btn-sm btn-primary ml-2"
                                      onClick={(e) => {e.preventDefault();setReturnPopup(false)}}
                                      style={{height: 'auto'}}
                                    >Returned to Site</a>
                                  </>}
                              </div>
                            </div>
                          ) : (
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Expelled from site? : </div>
                              <div className="col-6 dd-access">
                                <span className={`roundPill ${data.isExpelled == '1' ? "red-bg" :  "green-bg"}`}>
                                  {data.isExpelled == '1'
                                    ? 'Yes'
                                    : 'No'}
                                  </span>
                              </div>
                            </div>
                          )}
                          
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Issue Raised & Action Taken :</div>
                            <div className="col-6 dd-access">{data.issueRaised ? data.issueRaised : "-"}</div>
                          </div>

                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Submitted By :</div>
                            <div className="col-6 dd-access">{data.submittedBy ? data.submittedBy : "-"}</div>
                          </div>

                          <div className="p-2"></div>

                          { data.isDeleted != '1' && <>
                            <button className="btn btn-outline-success ms-1" onClick={() => {
                              history.push(`/edit-safety-disciplinary-log/${data.id}`)
                            }}>Edit</button>
                          </> }
                          
                          {id && ( userDetails.role.includes("admin") || userPermissions.includes( 'deleteSafetyDisciplinaryLogs' ) ) && data.isDeleted != 1 ? <>
                            <button className="btn btn-outline-danger ms-1" onClick={() => handleArchive(id)}>
                              Archive
                            </button>
                          </> : ''}

                          <div className="p-2"></div>
                 
                        </div>
                      </div>

                      {data.isSuspended && data.returnedOnSite == 1 ? <>
                        <div className="header-access-view">Returned to Site</div>
                        <div className="p-2">
                          <div className="main-details">
                            
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Interpreter Required? </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {data.interpreterRequired == 1 ? 'Yes' : "No"}
                              </div>
                            </div>
                            
                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Date </div>
                              <div className="col-6 dd-access">
                                {" "}
                                {commonService.formatDate(data.returnDate,"Do MMM YYYY")}
                              </div>
                            </div>

                            <div className="mb-1 d-flex dl-access">
                              <div className="col-6 dd-access">Signature </div>
                              <div className="col-6 dd-access">
                                <img
                                  onError={(e) => {
                                    e.target.src = DefaultImage;
                                  }}
                                  className="obj-fit-cover"
                                  height="250"
                                  width="250"
                                  src={
                                    data.signature
                                      ? process.env.REACT_APP_API_BASEURL +
                                      data.signature
                                      : DefaultImage
                                  }
                                ></img>
                              </div>
                            </div>

                          </div>
                        </div>
                      </> : ''}
                      

                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
