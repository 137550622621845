import React from 'react'
import "./List.css"
import Header from '../Header/Header'
import axiosInstance from '../../helpers/request';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useAppState } from '../../state';
import Edit2 from '../../images/edit-2.svg';
import Restore from "../../images/undo.svg";
import Delete from '../../images/archive-icon.svg';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";
import Select from "react-select"
import { MODULE_STATUS } from '../common/constant'
import { useLocation } from 'react-router-dom';
import CustomPopup from "../Popup/CustomPopup";
import ListingButtons from "../common/ListingButtons";

import { commonService } from "../common/common.service";

import Table from "../common/Table";
import DataTable from 'react-data-table-component';
import { Spinner } from "react-bootstrap";
import { Document, Page, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

function CommonUsersList({ title, isDeleted }) {

    const { user, userPermissions, selectedSite, selectedCompany } = useAppState()
    const history = useHistory()
    const userToken = localStorage.getItem("cs_token");
    const location = useLocation()
    const isOwn = location.pathname.includes("my-")
    const isSites = location.pathname.includes("sites-") || (user.role.includes('admin') ? false : localStorage.getItem(`${user.id}-currentSite`))
    const [data, setData] = useState([])
    const [roles, setRoles] = useState([])
    const [contractors, setContractors] = useState([])
    const [companyId, setUserID] = useState("")
    const [myId, setMyId] = useState("")
    const [search, setSearch] = useState("")
    const [pdfLoader, setPdfLoader] = useState(true);

    const [sortColumn, setSortColumn] = useState(null);
	const [sortDirection, setSortDirection] = useState('asc');

    const [status, setStatus] = useState({
        label: "Select Status",
        value: ""
    })

    const [reassignPopup, setReassignPopup] = useState(true);
    const [reassignData, setReassignData] = useState({});

    useEffect(() => {
        setUserID(selectedCompany);
    }, [selectedCompany]);

    useEffect(() => {
        if (companyId || myId) {
            axiosInstance
                .post(`${process.env.REACT_APP_API_BASEURL}/${((user.role == "superadmin") && !isOwn) ? "every-roles" : "all-roles"}`, {
                    companyId: companyId,
                    status: ""
                }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
                .then((res) => {
                    if (res.data.length > 0) {
                        setRoles(res.data);
                    }
                })
                .catch((err) => {
                    console.log("errr", err);
                });
            axiosInstance
                .post(`${process.env.REACT_APP_API_BASEURL}/${((user.role == "superadmin") && !isOwn) ? "every-contractors" : "all-contractors"}`, {
                    companyId: companyId,
                    status: ""
                }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                })
                .then((res) => {
                    if (res.data.length > 0) {
                        setContractors(res.data);
                    }
                })
                .catch((err) => {
                    console.log("errr", err);
                });
            getUsers()
        }
    }, [companyId, status, selectedSite])

    const getUsers = () => {
        if (isSites) {
            if (localStorage.getItem(`${user.id}-currentSite`)) {
                axiosInstance
                    .post(`${process.env.REACT_APP_API_BASEURL}/site-users/${localStorage.getItem(`${user.id}-currentSite`)}`, {
                        isOwn,
                        id: myId
                    }, {
                        headers: {
                            Authorization: `Bearer ${userToken}`,
                        },
                    })
                    .then((res) => {
                        setData(res.data);

                        const savedSort = commonService.loadSortData('Users');
                        if (savedSort) {
                            const { direction, columnSelector } = savedSort;
                            setSortDirection(direction);
                            setSortColumn(columnSelector);
                        }
                    })
                    .catch((err) => {
                        console.log("errr", err);
                    });
            } else {
                setData([]);
            }
        } else {
            axiosInstance
                .post(`${process.env.REACT_APP_API_BASEURL}/${((user.role == "superadmin") && !isOwn && !isSites) ? "all-users" : "all-users"}`, {
                    companyId: companyId,
                    status: status.value,
                    isOwn,
                    isSites,
                    id: myId,
                    isDeleted: isDeleted,
                })
                .then((res) => {
                    // if (res.data.length > 0) {
                    setData(res.data);

                    const savedSort = commonService.loadSortData('Users');
                    if (savedSort) {
                        const { direction, columnSelector } = savedSort;
                        setSortDirection(direction);
                        setSortColumn(columnSelector);
                    }
                    // }
                })
                .catch((err) => {
                    console.log("errr", err);
                });
        }
    }

    useEffect(() => {
        userDetail()
    }, [])

    function userDetail() {
        axiosInstance
            .post(`${process.env.REACT_APP_API_BASEURL}/userdetails`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            })
            .then((res) => {
                if (res.data.length > 0) {
                    setUserID(((res.data[0].role !== "admin") && res.data[0].added_by) ? res.data[0].added_by : res.data[0].id);
                    setMyId(res.data[0].id);
                }
            })
            .catch((err) => {
                console.log("errr", err);
            });
    }


    const handleDelete = async (id, name) => {

        setReassignData({
            ...reassignData,
            id,
            name
        })

        if(reassignData.assignTo) {
            const result = await Swal.fire({
                title: `Are you sure you want to archive ${name}`,
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, archive it!",
            });

            if (result.isConfirmed) {
                axiosInstance
                    .post(process.env.REACT_APP_API_BASEURL + "/user/" + id, {
                        assignTo: reassignData.assignTo
                    })
                    .then(function (response) {
                        getUsers();
                        toast.success("User archived successfully");
                    })
                    .catch(function (error) {
                        toast.error(error?.response?.data?.message || "Unable to archive user");
                    });
            }

        } else {
            getReassignmentUser(id, name);
        }
    };

    const handleUndelete = async (id, name) => {
        const result = await Swal.fire({
          title: `Are you sure you want to restore ${name}`,
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, restore it!",
        });
    
        if (result.isConfirmed) {
          axiosInstance
            .post(process.env.REACT_APP_API_BASEURL + "/undelete-user/" + id)
            .then(function (response) {
              getUsers();
              toast.success("User restored successfully");
            })
            .catch(function (error) {
              toast.error(
                error?.response?.data?.message || "Unable to restore user"
              );
            });
        }
      };

    const tableHeader = [
        'ID', 'Full Name', 'Email', 'Company Name', 'Role', 'Status',
      ];
    
      function getActionsConvertableArray() {
        let array = [];
    
        // add all items from data but skip actions
        data.forEach((d) => {
          array.push({
            id: d.id,
            fullName: d.fullName ? d.fullName : '',
            email: d.email ? d.email : '',
            companyName: (d.contractorId && (d.role != "admin")) ? contractors.filter(v => v.id == d.contractorId).map(v => v.name).join("") : d.companyName,
            roleName: d.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v),
            status: d.is_active ? 'Active' : 'Inactive',
          })
        })
    
        return array;
      }
    
      const columns = [
        {
          name: 'View',
          selector: 'view',
          width: '100px',
          cell: row => <div>{row.view}</div>,
          sortFunction: (a, b) => {
            return a.id - b.id;
          }
        },
        {
          name: 'Full Name',
          selector: 'fullName',
          searchField: 'fullName',
          cell: row => <div>{row.fullName}</div>,
        },
        {
          name: 'Email',
          selector: 'email',
          searchField: 'email',
          width: '300px',
          cell: row => <div>{row.email}</div>,
        },
        {
          name: 'Company Name',
          selector: 'companyName',
          searchField: 'companyName',
          cell: row => <div>{row.companyName}</div>,
        },
        {
          name: 'Role',
          selector: 'roleName',
          searchField: 'roleName',
          cell: row => <div>{row.roleName}</div>,
        },
      ];
    
      // add minWidth to each item in columns
      columns.forEach((column) => {
        if ( typeof column.minWidth == 'undefined' && typeof column.width == 'undefined' ) {
          column.minWidth = '200px';
        }
    
        // Add sorting to all columns
        if ( typeof column.sortable === 'undefined' ) {
          column.sortable = true;
        }
    
        if ( typeof column.sortFunction === 'undefined' && typeof column.searchField !== 'undefined' ) {
            column.sortFunction = (a, b) => {
                const fieldValueA = a && a[column.searchField] !== null ? String(a[column.searchField]) : '';
                const fieldValueB = b && b[column.searchField] !== null ? String(b[column.searchField]) : '';
                
                return fieldValueA.localeCompare(fieldValueB);
            };
        }
      });
    
      if ( user.role.includes("admin") ) {

        columns.push( {
            name: 'Status',
            selector: 'status',
            searchField: 'status',
            cell: row => <div>
                <div className="form-check form-switch">
                    <input
                        className="sub-permission-check form-check-input form-check-input-color d-block"
                        style={{ width: "45px", height: "22px" }}
                        type="checkbox"
                        role={`switch-${row.id}`}
                        id={`flexSwitchCheckChecked-${row.id}`}
                        checked={row.status ? true : false}
                        onChange={(e) => {
                            handleChangeStatus(row.id, e.target.checked ? 1 : 0)
                        }} />
                </div>  
            </div>,
        } );

        columns.push( {
            name: 'Status',
            selector: 'status',
            searchField: 'status',
            cell: row => <div>
                <button
                    className="send-password-reset"
                    onClick={ () => handlePasswordReset(row.id, row.fullName, row.email)}
                >Send</button>
            </div>
        } );
      }

      if (user.role.includes("admin") || userPermissions.includes('editusers') || userPermissions.includes('deleteUsers') ) {
        columns.push({
          name: 'Action',
          sortable: false,
          selector: 'actions',
          cell: row => <div>{row.actions}</div>,
          allowOverflow: true
        });
      }
    
      const downloadExcel = () => {    
        commonService.downloadArrayOfObjectsAsXLSX(
          getActionsConvertableArray(),
          tableHeader,
          title.toLowerCase().replace(' ', '-').split('(')[0] + '.xlsx'
        );
    
      }
      
      const printList = () => {
        // Trigger the actual print
        commonService.triggerPrint(
          getActionsConvertableArray(),
          tableHeader,
          title
        );
      }
    
      const PDFReadyTable = () => {
        // Implement download PDF
        const pdfTableData = {
          column: tableHeader,
          data: getActionsConvertableArray()
        };
    
        return (
          <Document>
            <Page
              orientation="landscape"
              size="LETTER"
              style={{padding: "20px 16px 30px"}}
            >
              <Table
                data={pdfTableData}
                heading={title}
              />
            </Page>
          </Document>
        );
      }

      const downloadPDF = async () => {
        // If we have larger data set, show laoder.
        if ( data.length > 100 ) {
          setPdfLoader(false);
        }
        const blob = await pdf((
          <PDFReadyTable />
          )).toBlob();
          saveAs(blob, title.toLowerCase().replace(/ /g, '-').split('(')[0] + ".pdf");
          setPdfLoader(true);
      };
    
      const CustomSortIcon = () => (
        <span className="dt-sort-icon"></span>
      )
    
      const createData = () => {
        let parsedData = [];
    
        if ( data.length == 0 ) {
          return parsedData;
        }
    
        data.forEach((d) => {
          let row = {
            id: d.id,
            view: <span onClick={() => { history.push(`/view-user/${d.id}`); }} className="lists-id-col">{d.id}</span>,
            fullName: d.fullName ? d.fullName : '',
            email: d.email ? d.email : '',
            companyName: (d.contractorId && (d.role != "admin")) ? contractors.filter(v => v.id == d.contractorId).map(v => v.name).join("") : d.companyName,
            roleName: d.role?.split(",").map(v => roles.filter(r => r.id == v).length ? roles.filter(r => r.id == v)[0].name : v),
            status: d.is_active ? true : false,
          };
          
          if ( user.role.includes("admin") || userPermissions.includes('editusers') || userPermissions.includes('deleteUsers') ) {
            row.actions = <>
              { ! isDeleted ? <>
                { (user.role.includes("admin") || userPermissions.includes('deleteUsers')) &&
                        <img src={Delete} alt="" onClick={() => {
                            handleDelete(d.id, d.fullName)
                        }} />}
                    {userPermissions.includes('editusers') && <img className="ps-2" src={Edit2} alt="" onClick={() => {
                        history.push("/edit-user/" + d.id)
                    }} />}
              </> : <>
                { (user.role.includes("admin") || userPermissions.includes('deleteUsers')) &&
                    <img
                        src={Restore}
                        alt=""
                        title="Restore"
                        className="undelete-btn"
                        onClick={() => {
                        handleUndelete(d.id, ' this user');
                        }}
                    /> }
              </> }
            </>
          }
    
          parsedData.push(row);
        });
    
        if ( parsedData.length > 0 && search && search.length > 0 ) {
          // Search for matching string in all columns for each row
          parsedData = parsedData.filter((d) => {
            // Combine all column values in a row into a single string
            const rowValues = Object.entries(d)
              .filter(([key]) => key !== 'action') // Exclude 'action' property
              .map(([key, value]) => value)
              .join(' ')
              .toLowerCase();
            
            // Check if the search string is present in any of the columns
            return rowValues.includes(search.toLowerCase());
          });
        }
    
        return parsedData;
      }
    
    const handlePasswordReset = async (id, name, email) => {
        const result = await Swal.fire({
            title: `Do you really want to send password reset for:`,
            text: `${name}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, send email!",
        });

        if (result.isConfirmed) {
            axiosInstance
                .post(process.env.REACT_APP_API_BASEURL + "/verify-email/", {
                    email: email
                })
                .then(function (response) {
                    getUsers();
                    toast.success("Email sent successfully");
                })
                .catch(function (error) {
                    toast.error(error?.response?.data?.message || "Unable to send email user");
                });
        }
    };

    const handleChangeStatus = (module_id, value) => {
        axiosInstance
            .post(process.env.REACT_APP_API_BASEURL + "/update-user-status", {
                id: module_id,
                status: value
            }, {
                headers: {
                    Authorization: `Bearer ${userToken}`
                }
            })
            .then(function (response) {
                toast.success(response?.data?.message || "User status has been updated successfully");
            })
            .catch(function (error) {
                console.log('error', error)
                toast.error(error?.response?.data?.message || "Unable to update User status");
            });
        let newData = []
        data.forEach((itm) => {
            if (itm.id == module_id) {
                newData = [
                    ...newData,
                    {
                        ...itm,
                        is_active: value
                    }
                ];
            } else {
                newData = [...newData, itm]
            }
        })
        setData(newData)


    }

    const getReassignmentUser = (id, name) => {
        // show reassign popup
        
        if(!reassignData.assignTo) {
            setReassignPopup(false);
        } else {
            setReassignPopup(true);
            handleDelete(reassignData.id, reassignData.name);
        }
    }

    const handleSort = (column, direction, sortedRows) => {
		setSortColumn(column);
		setSortDirection(direction);
		commonService.saveSortData(column, direction, 'Users');
	};

    return (
        <div class="middle_page">
            <Header title={title} steps={[{ name: "Home", path: "/" }, { name: "Users List", path: "/users" }]} />

            <CustomPopup
                showClose={false}
                trigger={!pdfLoader}
                setTrigger={() => {
                
                }}
            >
                <h3>Please Wait...</h3>
                <Spinner visible="true" />
            </CustomPopup>
            
            <CustomPopup
                trigger={!reassignPopup}
                setTrigger={() => {
                setReassignPopup(true);
                }}
            >
                <div className="col-12 form_inner p-2">
                    <div className="input_icons">
                        <div className="input_field">
                            <div className="w-100 p-2 text-left">
                                <label for="">
                                    <div className="require-label">
                                        Assign To <span className="text-danger">*</span>
                                    </div>
                                </label>
                                <div className="input_icons">
                                    <div className="w-100">
                                        <Select
                                        onChange={(e) => {
                                            setReassignData({
                                            ...reassignData,
                                            assignTo: e.value,
                                            });
                                        }}
                                        placeholder="Select User"
                                        options={data.map((v) => ({
                                            label:
                                            v.fullName +
                                            " - " +
                                            (v.contractorName
                                                ? v.contractorName
                                                : v.companyName) +
                                            " - " +
                                            (v.roleName ? v.roleName : v.role),
                                            value: `${v.id}`,
                                            name: "assignTo",
                                        }))}
                                        value={data
                                            .filter((v) => `${v.id}` == reassignData?.assignTo)
                                            .map((v) => ({
                                            label:
                                                v.fullName +
                                                " - " +
                                                (v.contractorName
                                                ? v.contractorName
                                                : v.companyName) +
                                                " - " +
                                                (v.roleName ? v.roleName : v.role),
                                            value: `${v.id}`,
                                            name: "assignTo",
                                            }))}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="sign_save_reset_btn">
                                <button
                                    className="btn btn-success"
                                    disabled={
                                    !reassignData?.assignTo
                                    }
                                    onClick={getReassignmentUser}
                                >
                                    Reassign
                                </button>
                            </div>

                            <div className="w-100 p-2 text-left">
                                <div className="caption">
                                    This user may have open action items, please choose another user to reassign these open items to
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CustomPopup>
            <div class="main_container">
                <div>
                    <div class="form_inner table_search">
                        <div class="input_icons">
                            <img src={require("../../images/search.png")} alt="" />
                            <div class="input_field">
                                <input type="text" placeholder="Search Here" value={search} onChange={e => setSearch(e.target.value)} />
                            </div>
                        </div>
                        <div class="input_icons">
                            <div class="w-100">
                                <Select options={MODULE_STATUS} value={status} placeholder="Select Status" onChange={value => {
                                    setStatus(value)

                                }} />
                            </div>
                        </div>
                        
                        <ListingButtons
                            onPrint={printList}
                            onExcelDownload={downloadExcel}
                            onPDFDownload={downloadPDF}
                            user={user}
                            userPermissions={userPermissions}
                            addURL="/create-user"
                            addLabel="Add User +"
                            permissionToCheck="createUser"
                        />
                    </div>
                    <hr />
                    <div id="wrapper">
                        { data.length ? <>
                        <DataTable
                            columns={columns}
                            data={createData()}
                            pagination={true}
                            sortIcon={<CustomSortIcon />}
                            onSort={handleSort}
							defaultSortFieldId={sortColumn}
							defaultSortAsc={sortDirection === 'asc'}
                        />
                        </> : <>
                        <Spinner size="sm" animation="border" variant="light" />
                        </>}
                    </div>
                    
                </div>
            </div>

        </div>
    )
}

export default CommonUsersList