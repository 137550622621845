import React from "react";
import { useParams } from "react-router-dom";
import Header from "../Header/Header";
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../../helpers/request";
import { toast } from "react-toastify";
import "./ga3cantideckinspectionPermitView.css";
import { commonService } from "../common/common.service";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import DefaultImage from "../../images/default/no_image.png";
import { useAppState } from "../../state";
import { useRef } from "react";
import CustomPopup from "../Popup/CustomPopup";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import {
  PDFDownloadLink,
} from "@react-pdf/renderer";
import PDFRenderer from "../common/pdfRenderer/pdfRenderer";
import TabularData from "../common/pdfRenderer/tabularData";
import PDFFiles from "../common/pdfRenderer/files";
import Swal from "sweetalert2";

export default function ViewGA3CantiDeckInspectionPermit({ title }) {
  let { id } = useParams();
  const history = useHistory();
  const { user: userDetails, userPermissions } = useAppState()
  const userToken = localStorage.getItem("cs_token");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [siteUsers, setSiteUsers] = useState([])
  const [activities, setActivities] = useState([])
  const [popup, setPopup] = useState(true);
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");

  const [fieldData, setFieldData] = useState({
    approved: false,
    denied: false,
    resubmitted: false,
    completed: false,
    resubmitComment: "",
    approvedComment: "",
    deniedComment: "",
    signature: null,
  })

  const fetchga3cantideckinspectionDetails = () => {
    setLoading(true);
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_BASEURL}/ga3cantideckinspection/permit/details`,
        {
          ga3cantideckinspectionId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => {
        setData(res.data[0]);
        setLoading(false);
        getSiteUsers(res.data[0].siteId)
        getUserEmail(res.data[0].addedByInCompany)
      })
      .catch((err) => {
        setLoading(false);
        toast.error(
          err?.response?.data?.message || "Unable to process request"
        );
        if ( err?.response?.data?.message && err?.response?.data?.message.search('have access') ) {
          history.push('/');
        }
        console.log("errr", err);
      });
  };


  const fetchActivities = () => {
    axiosInstance
      .get(`${process.env.REACT_APP_API_BASEURL}/ga3cantideckinspectionactivity/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        if (Array.isArray(res.data)) {
          setActivities(res.data)
        }
      })
      .catch((err) => {
        console.log("errr", err);
      });
  }

  const getUserEmail = async (id) => {
    try {
      const userResponse = await axiosInstance.get(`/user/${id}`);
      const responseData = userResponse.data;
      if (responseData?.email) {
        console.log("SiteUsers", responseData);
        setEmail(responseData.email);
        setFullName(responseData.fullName);
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const getSiteUsers = async (siteId) => {
    setLoading(true)
    try {
      const siteUsersResponse = await axiosInstance.post(`/site-users/${siteId}`);
      const siteUsersResponseData = siteUsersResponse.data;
      setLoading(false)
      if (siteUsersResponseData.length) {
        console.log("SiteUsers", siteUsersResponseData);
        setSiteUsers(siteUsersResponseData);
      }
    } catch (error) {
      setLoading(false)
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (id) {
      fetchga3cantideckinspectionDetails();
      fetchActivities();
    }
  }, [id]);

  const signatureRef = useRef();
  const handleSaveVerifySign = () => {
    return new Promise((res, rej) => {
      if (signatureRef.current.isEmpty()) {
        rej(null);
      } else {
        const timestamp = Date.now(); // Get the current timestamp
        const randomString = Math.random().toString(36).substring(2);

        const canvas = signatureRef.current.getCanvas();
        canvas.toBlob((blob) => {
          const file = new File(
            [blob],
            `signature$_${randomString}_&${timestamp}.png`,
            { type: "image/png" }
          );
          setFieldData((prevFieldData) => {
            return {
              ...prevFieldData,
              signature: file,
            };
          });
          res(file)
        });
      }
    })

  };

  const handleClearSignature = () => {
    signatureRef.current?.clear();
    setFieldData((prevFieldData) => {
      return {
        ...prevFieldData,
        signature: null,
      };
    });
  };

  const handleSubmit = async () => {
    try {
      let signature
      if(!fieldData.denied){
        signature = await handleSaveVerifySign()
      }
      handleSubmitAfterSave(signature)
    } catch (error) {
      toast.error("Failed to save signature")
    }
  }

  const handleSubmitAfterSave = (signature) => {
    const form = new FormData();
    form.append("deniedComment", fieldData.deniedComment);
    form.append("approvedComment", fieldData.approvedComment);
    form.append("resubmitComment", fieldData.resubmitComment);
    if (fieldData.approved) {
      form.append("approved", fieldData.approved);
    }
    if (fieldData.completed) {
      form.append("completed", fieldData.completed);
    }
    form.append("signature", signature);
    form.append("approvedBy", userDetails.id);
    form.append("addedByInCompany", data.addedByInCompany);
    form.append("addedByInCompanyEmail", email);
    form.append("addedByInCompanyName", fullName);
    form.append("approvedByName", userDetails.fullName);
    form.append("id", id);
    setLoading(true);
    axiosInstance
      .post((fieldData.resubmitted || fieldData.completed) ? `${process.env.REACT_APP_API_BASEURL}/resubmit/ga3cantideckinspection-permit` : `${process.env.REACT_APP_API_BASEURL}/update/ga3cantideckinspection-permit`, form, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        history.push('/ga3cantideckinspection-permits')
        setFieldData({
          deniedComment: "",
          approvedComment: "",
          approved: false,
          resubmitted: false,
          completed: false,
          denied: false,
          signature: null,
        });
        handleClearSignature();
      })
      .catch((err) => {
        console.log(err)
        toast.error(
          err?.response?.data?.message || "Unable to process this request"
        );
        setLoading(false);
      });
  }

  const MyDoc = () => {

    const tabularSections = [
      {
        name: 'General Details',
        rows: [
          ['Site', data.siteName ? data.siteName : "-"],
          ['Department', data.departmentName ? data.departmentName : "-"],
          ['Contractor', data.contractorName ? data.contractorName : data.companyName],      
          ['Location', data.locationOfWorks ? data.locationOfWorks : ''],
          ['Submitted By', data.submittedBy? data.submittedBy : "-"],    
          ['Date of Inspection', data.startDate ? commonService.formatDate(data.startDate, "Do MMM YYYY" ): "-"],
          ['Description', data.descriptionOfWorks? data.descriptionOfWorks : ''],
        ]
      }      
    ];
  
    if ( ! data.isApproved ) {
      tabularSections[0].rows.push( ['Reason Denied', data.denialComment ? data.denialComment : "-"] );
    }
  
    const filesBlocks = [
      {
        name: "Uploaded Files",
        files: data.files,
      }
    ];

    activities.map((activity) => {
      if ( activity.signature ) {
        filesBlocks.push({
          name: activity.action,
          subtitle: activity.comment,
          type: 'signatures',
          files: activity.signature,
        });
      }
    });
  
    return (
      <PDFRenderer name={"GA3 CantiDeck Inspection #" + id} user={userDetails}>
        
        <TabularData
          tabularSections={tabularSections}
        />
  
        {filesBlocks.map((block) => (
          <PDFFiles block={block} />
        ))}
  
      </PDFRenderer>
    )
  }

  const handleArchive = async (deleteId) => {
    const result = await Swal.fire({
      title: `Are you sure you want to archive this item?`,
      text: "Archives can be restored later on",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, archive it!",
    });

    if (result.isConfirmed) {
      axiosInstance
        .delete(process.env.REACT_APP_API_BASEURL + "/ga3cantideckinspection/" + deleteId)
        .then(function (response) {
          toast.success("GA3 CantiDeck Inspection archived successfully");
          history.push(`/ga3cantideckinspection-permits`)
        })
        .catch(function (error) {
          toast.error(
            error?.response?.data?.message ||
              "Unable to archive GA3 CantiDeck Inspection"
          );
        });
    }
  };

  return (
    <div className="middle_page">
      <Header
        title={title + " #" + id}
        steps={[
          { name: "Home", path: "/" },
          { name: "GA3 CantiDeck Inspection List", path: "/ga3cantideckinspection-permits" },
          { name: "GA3 CantiDeck Inspection", path: `/ga3cantideckinspection-permit/${id}` },
        ]}
      />
      <CustomPopup
        trigger={!popup}
        setTrigger={() => {
          setPopup(true)
          setFieldData({
            approved: false,
            resubmitted: false,
            completed: false,
            denied: false,
            approvedComment: "",
            deniedComment: "",
            signature: null,
          })
        }}
      >
        {(fieldData.approved || fieldData.resubmitted || fieldData.completed) && <>
          <div className="sign_image">
            <SignatureCanvas
              ref={signatureRef}
              canvasProps={{ width: 263, height: 270 }}
            />
            <span
              className="cancel-custom cursor-pointer"
              onClick={handleClearSignature}
            >
              <i
                className="clear_btn  text-danger fa fa-times-circle"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        </>
        }
        {!fieldData.completed && <div className="col-12 form_inner p-2">
          <label for="">
          {fieldData.resubmitted ? "Resubmit Comment" :fieldData.approved ? "Approval Comment" : "Reason for Denial"} <span className="text-danger">*</span>
          </label>
          <div className="input_icons">
            <div className="input_field">
              <textarea
                name="approvedComment"
                maxLength={parseInt(process.env.REACT_APP_MAX_COMMENTS_LENGTH, 10) || 750}
                rows="5"
                cols="100"
                onChange={(e) => {
                  if(fieldData.resubmitted){
                    setFieldData({
                      ...fieldData,
                      resubmitComment: e.target.value,
                    })
                  } else if (fieldData.approved) {
                    setFieldData({
                      ...fieldData,
                      approvedComment: e.target.value,
                    })
                  } else {
                    setFieldData({
                      ...fieldData,
                      deniedComment: e.target.value,
                    })
                  }
                }}
              ></textarea>
            </div>
          </div>
          <div className="comments-length-note">{process.env.REACT_APP_MAX_COMMENTS_LENGTH} max characters</div>
        </div>}
        <div className="sign_save_reset_btn">
          <button
            className="btn btn-success"
            {...(fieldData.approved ? {
              disabled:
                fieldData.approved ?
                  false :
                  !(fieldData.deniedComment)
            } : null)}
            onClick={() => {
              handleSubmit()
            }}
          >
            Submit
          </button>
        </div>
      </CustomPopup>
      <div className="main_container">
        {!loading ? (
          Object.keys(data).length ? (
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-5 mb-2">
                  <div className="d-flex flex-column">
                    <div className="mb-3">
                      <div className="header-access-view">General Details</div>
                      <div className="p-2">
                        <div className="main-details">
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Site : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.siteName ? data.siteName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Department : </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.departmentName ? data.departmentName : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">Contractor : </div>
                            <div className="col-6 dd-access">
                              {data.contractorName ? data.contractorName : data.companyName}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Location:{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.locationOfWorks ? data.locationOfWorks : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Submitted By :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {" "}
                              {data.submittedBy ? data.submittedBy : "-"}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Date Of Inspection :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {data.startDate
                                ? commonService.formatDate(
                                  data.startDate,
                                  "Do MMM YYYY"
                                )
                                : "-"}{" "}
                            </div>
                          </div>
                          <div className="mb-1 d-flex dl-access">
                            <div className="col-6 dd-access">
                              Description :{" "}
                            </div>
                            <div className="col-6 dd-access">
                              {data.descriptionOfWorks}
                            </div>
                          </div>
                          {!data.isApproved && <div className="mb-1 d-flex dl-access alert alert-danger">
                            <div className="col-6 dd-access">Reason Denied : </div>
                            <div className="col-6 dd-access">
                              {data.denialComment ? data.denialComment : "-"}{" "}
                            </div>
                          </div>}
                        </div>
                      </div>
                      {/* {siteUsers.filter(user => (user.type == "admin") && (user.userid == userDetails.id)).length && (data.isApproved == 2) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            approved: true
                          })
                          setPopup(false)
                        }}>Approve</button>
                        <button className="btn btn-danger ms-2" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            denied: true
                          })
                          setPopup(false)
                        }}>Deny</button>
                      </>
                        : null}
                      {(data.isApproved == 0) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            resubmitted: true
                          })
                          setPopup(false)
                        }}>Resubmit</button>
                      </>
                        : null}
                      {(data.isApproved == 1) && (data.isCompleted != 1) ? <>
                        <button className="btn btn-success" onClick={() => {
                          setFieldData({
                            ...fieldData,
                            completed: true
                          })
                          setPopup(false)
                        }}>Complete</button>
                      </>
                        : null} */}

                      {id && ( userDetails.role.includes("admin") || userPermissions.includes( 'deleteGA3CantiDeckInspection' ) ) && data.isDeleted != 1 ? <>
                        <button className="btn btn-outline-danger ms-1 mt-2" onClick={() => handleArchive(id)}>
                          Archive
                        </button>
                      </> : ''}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-7 mb-2">
                  <div className="d-flex flex-column">

                    <div className="mb-3" style={{ border: '1px solid #e2e6ea', borderRadius: '8px', overflow: "auto", maxHeight: "670px" }}>
                      <div className="header-access-view position-sticky" style={{ top: 0 }}>Activity</div>
                      {activities.map(activity => (<div className="m-2">
                        <div className="p-2 signature-div-inner">
                          <div className="d-flex justify-content-between mb-2">
                            <span className="rst-title me-2">
                              {activity.action} :{" "}
                            </span>
                            <span className="rst-title me-2 text-secondary">
                              {activity.datetime}
                            </span>
                          </div>
                          {activity.comment ?
                            <p>{activity.comment}</p>
                            : null}
                          {activity.signature ? <img
                            onError={(e) => {
                              e.target.src = DefaultImage;
                            }}
                            className="obj-fit-cover"
                            height="250"
                            width="250"
                            src={
                              activity.signature
                                ? process.env.REACT_APP_API_BASEURL +
                                activity.signature
                                : DefaultImage
                            }
                          ></img> : null}
                        </div>
                      </div>))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="header-access-view"> Uploaded Files</div>
                  <div className="d-flex">
                    {data.files && data.files.split(",").map(imgPreviewUrl => (<div id="profile_pic_1_preview" className="image-fixed m-2">
                      {commonService.outputImageTile(imgPreviewUrl)}
                    </div>))}
                  </div>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4 mt-3">
                  <PDFDownloadLink
                    document={<MyDoc />}
                    className="mt-2 btn btn-primary pdf-download-btn"
                    fileName={"GA3-cantiDeck-inspection-" + id + ".pdf"}
                  >
                    {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download PDF"
                    }
                  </PDFDownloadLink>
                </div>
              </div>

            </div>
          ) : (
            "Unable To Fetch Data"
          )
        ) : (
          "Loading"
        )}
      </div>
    </div>
  );
}
